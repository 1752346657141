import { lazy, Suspense } from "react"
import { createRootRouteWithContext, Outlet } from "@tanstack/react-router"

import { RouterContext } from "../main"
import { DialogQueueProvider } from "@src/context/DialogQueueProvider"
import { Languages } from "@src/context/Languages"

// Only load Tanstack router devtools in development mode
const LazyTanStackRouterDevtools = import.meta.env.PROD
  ? () => null
  : lazy(() =>
      import("@tanstack/router-devtools").then((res) => ({
        default: res.TanStackRouterDevtools,
      })),
    )

export const Route = createRootRouteWithContext<RouterContext>()({
  // Wrapping the root route in the dialog provider because in the main.tsx the dialog provider
  // would wrap the Router one and any router methods could not be used in dialogs
  component: () => (
    <DialogQueueProvider>
      <Languages>
        <Outlet />
        <Suspense>
          <LazyTanStackRouterDevtools initialIsOpen={false} />
        </Suspense>
      </Languages>
    </DialogQueueProvider>
  ),
})
