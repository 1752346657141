import { useState, useEffect, useReducer, useMemo } from "react"
import { useNavigate } from "@tanstack/react-router"
import { t, Trans } from "@lingui/macro"

import {
  MethodologySectionDto,
  MethodologyDto,
  MethodologyContentCircleDto,
} from "@src/api/methodologies"
import { Privacy } from "@src/api/privacy"
import { Tabs } from "@src/components/atoms/Tabs"
import { Input } from "@src/components/atoms/Input"
import { Checkbox } from "@src/components/atoms/Checkbox"
import { Button } from "@src/components/atoms/Button"
import { EditorLazy } from "@src/components/organisms/Editor/EditorLazy"
import { useCommunityId } from "@src/hooks/useCommunityId"
import { Empty } from "@src/components/atoms/Empty"

import { ProgramSections } from "./ProgramSections"
import { LogoSelector } from "./LogoSelector"
import { PrivacySelector } from "./PrivacySelector"
import { useMethodologyApi } from "./useMethodologyApi"

export type ProgramFormProps = {
  methodologySections?: MethodologySectionDto[]
  methodology?: MethodologyDto
}

type FormState = {
  methodologyName: string
  isActive: boolean
  errors?: {
    methodologyName?: string
    isActive?: string
    [key: string]: string | undefined
  }
}

type Action =
  | { type: "SET_METHOD_NAME"; payload: string }
  | { type: "SET_ACTIVE"; payload: boolean }
  | { type: "SET_ERROR"; payload: { field: string; error: string | undefined } }

const reducer = (state: FormState, action: Action): FormState => {
  switch (action.type) {
    case "SET_METHOD_NAME":
      return { ...state, methodologyName: action.payload }
    case "SET_ACTIVE":
      return { ...state, isActive: action.payload }
    case "SET_ERROR":
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: action.payload.error,
        },
      }
    default:
      return state
  }
}

export const ProgramForm = ({
  methodologySections,
  methodology,
}: ProgramFormProps) => {
  const communityId = useCommunityId()
  const { saveMethodology, hasChanges, isLoading } = useMethodologyApi({
    methodologySections,
    methodology,
    communityId,
  })
  const navigate = useNavigate()
  const [state, dispatch] = useReducer(reducer, {
    methodologyName: methodology?.name || "",
    isActive: methodology?.isActive || false,
  })
  const [sortedMethodologySections, setSortedMethodologySections] = useState<
    MethodologySectionDto[] | null
  >(null)
  const [{ sectionIndex, paragraphIndex }, setParagraphPath] = useState({
    sectionIndex: 0,
    paragraphIndex: 0,
  })

  useEffect(() => {
    setSortedMethodologySections(
      methodologySections
        ? methodologySections
            .map((content) => ({
              ...content,
              methodologyParagraphs: content.methodologyParagraphs?.sort(
                (a, b) => (a.order ?? 0) - (b.order ?? 0),
              ),
            }))
            .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
        : [],
    )
  }, [methodologySections])

  const updateParagraphField = (
    field: string,
    value: string | Privacy | MethodologyContentCircleDto[],
  ) => {
    setSortedMethodologySections((prevSections) => {
      if (!prevSections) return prevSections

      const newSections = prevSections.map((section, sIndex) => {
        if (sIndex !== sectionIndex) return section
        return {
          ...section,
          methodologyParagraphs: section.methodologyParagraphs?.map(
            (paragraph, pIndex) => {
              if (pIndex !== paragraphIndex) return paragraph
              return {
                ...paragraph,
                [field]: value,
              }
            },
          ),
        }
      })
      return newSections
    })
  }

  const updateSectionField = (field: string, value: string) => {
    setSortedMethodologySections((prevSections) => {
      if (!prevSections) return prevSections

      const newSections = prevSections.map((section, sIndex) => {
        if (sIndex !== sectionIndex) return section
        return {
          ...section,
          [field]: value as string,
        }
      })

      return newSections
    })
  }

  const currentSection = useMemo(() => {
    return sortedMethodologySections?.[sectionIndex] ?? null
  }, [sortedMethodologySections, sectionIndex])

  const currentParagraph = useMemo(() => {
    return currentSection?.methodologyParagraphs?.[paragraphIndex] ?? null
  }, [currentSection, paragraphIndex])

  const handleFieldBlur = (
    field: string,
    value?: string | null | undefined,
  ) => {
    if (!value?.trim()) {
      dispatch({
        type: "SET_ERROR",
        payload: {
          field,
          error: t({ message: "This field is required", id: "required_field" }),
        },
      })
    }
  }
  const onSubmit = async () => {
    const id = await saveMethodology({
      methodologyName: state.methodologyName,
      isActive: state.isActive,
      newMethodologySections: sortedMethodologySections || [],
    })

    if (!methodology?.id) {
      navigate({
        to: "/community/$communityId/admin-panel/programs/$programId",
        params: {
          communityId: String(communityId),
          programId: String(id),
        },
      })
    }
  }

  return (
    <form
      className="flex h-[calc(100%-62px)] flex-col gap-6"
      onSubmit={(e) => {
        e.preventDefault()
        e.stopPropagation()
        onSubmit()
      }}
    >
      <header className="flex items-center justify-end gap-2">
        <Button
          variant="secondary"
          small
          onClick={() => {
            navigate({
              to: "/community/$communityId/admin-panel/programs",
              params: {
                communityId: String(communityId),
              },
            })
          }}
        >
          <Trans id="delete">Delete</Trans>
        </Button>
        <Button
          small
          type="submit"
          disabled={
            isLoading ||
            !hasChanges({
              methodologyName: state.methodologyName,
              isActive: state.isActive,
              newMethodologySections: sortedMethodologySections ?? [],
            })
          }
        >
          <Trans id="save_changes">Save Changes</Trans>
        </Button>
      </header>

      <section className="flex items-end gap-6 rounded-xl border border-pearl-lighter bg-white p-6">
        <Input
          name="methodologyName"
          placeholder={t({
            message: "Name of the program",
            id: "name_of_the_program",
          })}
          label={t({
            message: "Program name",
            id: "program_name",
          })}
          error={state.errors?.methodologyName}
          variant="primary"
          className="w-full"
          onChange={(value) => {
            dispatch({ type: "SET_METHOD_NAME", payload: value as string })
          }}
          value={state.methodologyName}
          onBlur={() =>
            handleFieldBlur("methodologyName", state.methodologyName)
          }
        />
        <Checkbox
          checked={!!state.isActive}
          onChange={(value) => {
            dispatch({ type: "SET_ACTIVE", payload: value })
          }}
          className="w-full"
          label={t({
            message: "Is program visible?",
            id: "is_program_visible",
          })}
        />
      </section>
      <div className="flex flex-col gap-6 overflow-hidden lg:flex-row">
        <ProgramSections
          methodologyId={methodology?.id ?? 0}
          methodologySections={sortedMethodologySections || []}
          setParagraphPath={setParagraphPath}
          updateSectionsOrder={setSortedMethodologySections}
          selectedSectionIndex={sectionIndex}
          selectedParagraphIndex={paragraphIndex}
        />

        <div className="flex w-full flex-1 flex-col gap-4 overflow-y-auto">
          {currentSection ? (
            <section className="flex flex-col items-center gap-2 rounded-xl border border-pearl-lighter bg-white p-6">
              <div className="flex w-full items-center gap-2">
                <LogoSelector
                  value={currentSection?.logo ?? ""}
                  name={currentSection?.name}
                  onChange={(value) => {
                    // field.handleChange(value as string)
                    updateSectionField("logo", value as string)
                  }}
                />
                <Input
                  name="sectionName"
                  placeholder={t({
                    message: "Section Name",
                    id: "section_name",
                  })}
                  error={state.errors?.currentSectionName}
                  variant="primary"
                  className="w-full"
                  onChange={(value) => {
                    updateSectionField("name", value as string)
                  }}
                  value={currentSection?.name ?? ""}
                  onBlur={() =>
                    handleFieldBlur("currentSectionName", currentSection?.name)
                  }
                />
              </div>
            </section>
          ) : (
            <Empty
              title={t({
                message: "Nothing to see here",
                id: "nothing_to_see_here",
              })}
              description={t({
                message: "Please add your first section",
                id: "please_add_your_first_section",
              })}
            />
          )}
          {currentParagraph && (
            <>
              <section className="rounded-xl border border-pearl-lighter bg-white p-6">
                <Input
                  name="name"
                  placeholder={t({
                    message: "Name",
                    id: "name",
                  })}
                  error={state.errors?.currentParagraphName}
                  variant="big"
                  onChange={(value) => {
                    updateParagraphField("name", value as string)
                  }}
                  value={currentParagraph?.name ?? ""}
                  onBlur={() =>
                    handleFieldBlur(
                      "currentParagraphName",
                      currentParagraph?.name,
                    )
                  }
                />
                <div className="my-4 h-[1px] bg-pearl-lighter"></div>
                <EditorLazy
                  value={currentParagraph?.wikiReferences ?? ""}
                  placeholder={t({
                    message: "One sentence to describe the content",
                    id: "business_model_edit_description_placeholder",
                  })}
                  onChange={(value) => {
                    updateParagraphField("wikiReferences", value as string)
                  }}
                  className="overflow-auto"
                />
              </section>

              <Tabs
                actions={
                  <PrivacySelector
                    privacyLevel={currentParagraph.privacyLevel ?? 6}
                    circleIds={
                      currentParagraph.methodologyContentCircleList ?? []
                    }
                    onChange={({
                      privacyLevel: newPrivacyLevel,
                      circleIds: newCircleIds,
                    }) => {
                      if (newPrivacyLevel) {
                        updateParagraphField("privacyLevel", newPrivacyLevel)
                      }

                      if (newCircleIds) {
                        updateParagraphField(
                          "methodologyContentCircleList",
                          newCircleIds,
                        )
                      }
                    }}
                  />
                }
                tabs={[
                  {
                    label: "Learning content",
                    content: (
                      <EditorLazy
                        value={currentParagraph?.infoText ?? ""}
                        onChange={(value) => {
                          updateParagraphField("infoText", value as string)
                        }}
                        className="overflow-auto rounded-xl border border-pearl-lighter bg-white p-2 focus:border-pearl-light"
                      />
                    ),
                  },
                  ...(!currentParagraph?.isHillary
                    ? [
                        {
                          label: "Activity",
                          content: (
                            <EditorLazy
                              value={currentParagraph?.defaultContent ?? ""}
                              onChange={(value) => {
                                updateParagraphField(
                                  "defaultContent",
                                  value as string,
                                )
                              }}
                              className="overflow-auto rounded-xl border border-pearl-lighter bg-white p-2 focus:border-pearl-light"
                            />
                          ),
                        },
                      ]
                    : []),
                ]}
                tabWidth={208}
              />
            </>
          )}
        </div>
      </div>
    </form>
  )
}
