import { createFileRoute } from '@tanstack/react-router'

import { ApplicationFormPage } from '@src/components/pages/Forms/ApplicationForms/ApplicationFormPage'

export const Route = createFileRoute(
  '/_auth/_community/community/$communityId/_admin-panel/admin-panel/forms/application/$formId',
)({
  component: () => <ApplicationFormPageWrapper />,
  validateSearch: (search: Record<string, unknown>) => {
    return {
      view_mode: search.view_mode === true ? true : false,
      duplicate: search.duplicate === true ? true : false,
    }
  },
})

function ApplicationFormPageWrapper() {
  const { formId } = Route.useParams()
  const { view_mode, duplicate } = Route.useSearch()

  // TODO: handle failing to parse formId
  const parsedFormId = parseInt(formId)

  return (
    <ApplicationFormPage
      formId={parsedFormId}
      viewMode={view_mode}
      duplicate={duplicate}
    />
  )
}
