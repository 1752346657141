import { Input } from "@src/components/atoms/Input"
import { AnswerProps } from "./types"
import { useValidators } from "./validators"

export const NumericAnswer = ({ form, question }: AnswerProps) => {
  const { requiredString } = useValidators()
  const questionOrder = question?.questionOrder ?? 0

  const handleChange = (value: string) => {
    const currentAnswer = form.state.values.answers?.[questionOrder] ?? {}

    form.setFieldValue("answers", {
      ...form.state.values.answers,
      [questionOrder]: {
        ...currentAnswer,
        answer: value,
        formQuestionId: question.id,
      },
    })
  }

  return (
    <form.Field
      name={`answers[${questionOrder}].answer`}
      validators={question.mandatoryAnswer ? requiredString : undefined}
    >
      {(field) => (
        <Input
          name={`answers[${questionOrder}].answer`}
          error={field.state.meta.errors.join(", ")}
          placeholder={question.title ?? ""}
          label={question.title ?? ""}
          type="number"
          description={question.description ?? ""}
          required={question.mandatoryAnswer}
          onChange={(value) => handleChange(value as string)}
          value={field.state.value || ""}
          onBlur={field.handleBlur}
        />
      )}
    </form.Field>
  )
}
