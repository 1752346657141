import { useEffect } from "react"
import { getRouteApi } from "@tanstack/react-router"
import clsx from "clsx"
import { Trans } from "@lingui/macro"
import {
  ApiFilters,
  ApiDynamicFilter,
  QuestionDynamicFilter,
  ApiDynamicFilterType,
} from "@src/api/filters"
import { OptionProps } from "@src/components/atoms/Fieldset/common"
import { Select } from "@src/components/atoms/Select"
import { Button } from "@src/components/atoms/Button"
import { MultiSelect } from "@src/components/atoms/MultiSelect"
import { cleanApiFilters } from "./utils"

// TODO: add display order. Use flex-order to display the filters in the correct
// order
export type ApiFiltersOptionsBasic<T extends string | number> = {
  filterOptions: OptionProps<T>[]
  advancedFilter?: false
  label: string
}

export type ApiFiltersOptionsDynamic<T extends string | number> = {
  filterOptions: OptionProps<T>[]
  advancedFilter: true
  label: string
}

export type QuestionFilterOptionBasic = {
  question: string
  answers: string[]
  advancedFilter?: false
  label: string
}

export type QuestionFilterOptionDynamic = {
  question: string
  answers: string[]
  advancedFilter: true
  label: string
}

export type ApiFiltersOptions<T extends ApiFilters> = {
  [K in keyof T]: T[K] extends ApiDynamicFilter<string | number> | undefined
    ? ApiFiltersOptionsDynamic<string | number>
    : T[K] extends number | undefined | null
      ? ApiFiltersOptionsBasic<string | number>
      : never
} & {
  filters?: Array<
    QuestionFilterOptionBasic | QuestionFilterOptionDynamic
  > | null
}

export type FiltersProps<T extends ApiFilters> = {
  defaultValues: Partial<T>
  /**
   * defaultValues and values cannot actually contain explictly contain the "filters"
   * key unless a type assertion is used. This is because the general type ApiFilters from src/api/filters.ts
   * is an "impossible type". See that file for more details.
   * */
  values: T
  optionValues: Omit<ApiFiltersOptions<T>, "skip" | "take">
  /**
   * Make sure your query function uses the cleanInvalidFilters utility function to clean the filters
   * before calling the API. This is necessary because if a user selects isNoneOf without a value for the filter
   * that's an invalid API request. Ideally we would call this function in the Filters component itself, but that
   * leads to infinite re-renders when called in the useEffect. We cannot call it when we set the search params query
   * because we need the value to be there in order to use it in the MultiSelect component. So for now, the compromise
   * is to call it right before making the API request. e.g.
   */
  onChange: (values: T) => void
  route: RoutesWithFilters
  className?: string
  showLabels?: boolean
  showResetButton?: boolean
}

// Not the best solution, but it works for now to provide typesafety for the filters & search params
// This is basically a list of all the routes that have search params of type ApiFilters (or an extension of it)
// A better solution would be to infer this from the router type. A type that select all the routes from the Router
// which have search params of type ApiFilters. A starting point for this would be this, which is the type of the routes:
// T extends RouteIds<RegisteredRouter['routeTree']>
export type RoutesWithFilters =
  | "/_auth/_community/community/$communityId/_admin-panel/admin-panel/surveys/$surveyId/responses"
  | "/_auth/_community/community/$communityId/_admin-panel/admin-panel/application-definitions/$applicationId/responses"

export const Filters = <T extends ApiFilters>({
  defaultValues,
  optionValues,
  onChange,
  route,
  className,
  showLabels = true,
  showResetButton = false,
}: FiltersProps<T>) => {
  const routeApi = getRouteApi(route)
  const filters: T = routeApi.useSearch()
  const navigate = routeApi.useNavigate()

  const basicFiltersKeys = Object.keys(optionValues).filter(
    (key) => !optionValues[key]?.advancedFilter && key !== "filters",
  )

  const advancedFiltersKeys = Object.keys(optionValues).filter(
    (key) => optionValues[key]?.advancedFilter && key !== "filters",
  )

  const basicQuestionFilters = optionValues.filters?.filter(
    (filter) => !filter.advancedFilter,
  )
  const advancedQuestionFilters = optionValues.filters?.filter(
    (filter) => filter.advancedFilter,
  )

  const setFilters = (newFilters: T) => {
    navigate({
      search: () => {
        return cleanApiFilters({ ...newFilters } as T, defaultValues)
      },
    })
  }

  useEffect(() => {
    onChange(filters as T)
  }, [filters, onChange])

  return (
    <div
      className={clsx("flex flex-wrap gap-2", className)}
      data-testid="filters"
    >
      {basicFiltersKeys.map((key) => {
        const option = optionValues[key]
        return (
          <Select
            className="shrink-0"
            key={key}
            label={showLabels ? option.label : undefined}
            options={option.filterOptions}
            placeholder={option.label}
            optionsDropdownWidth="w-auto"
            value={optionValues[key].filterOptions.find(
              (option) => option.id === filters[key],
            )}
            disabled={optionValues[key].filterOptions.length === 0}
            onSelect={(option) => {
              setFilters({
                ...filters,
                [key]: option.id,
              })
            }}
          />
        )
      })}
      {advancedFiltersKeys.map((key) => {
        // Safe to cast, we already filtered the advanced filters keys
        const option = optionValues[key] as ApiFiltersOptionsDynamic<
          string | number
        >
        const filterValue = filters[key] as ApiDynamicFilter<
          string | number
        > | null
        return (
          <MultiSelect
            className="shrink-0"
            key={key}
            label={showLabels ? option.label : undefined}
            placeholder={option.label}
            options={option.filterOptions}
            disabled={option.filterOptions.length === 0}
            optionsDropdownWidth="w-auto"
            value={optionValues[key].filterOptions.filter((option) => {
              // This could have been just filterValue?.values?.includes(option.id) but the type
              // inference is completely messed up here
              let isSelectedOption = false

              filterValue?.values?.forEach((value) => {
                if (value === option.id) {
                  isSelectedOption = true
                }
              })

              return isSelectedOption
            })}
            onSelect={(options) => {
              const newValues = optionValues[key].filterOptions
                ?.filter((o) => options.some((option) => option.id === o.id))
                .map((o) => o.id)

              setFilters({
                ...filters,
                [key]: {
                  values: newValues,
                  filterType:
                    filterValue?.filterType || ApiDynamicFilterType.IsAnyOf,
                },
              })
            }}
            advancedFilterValue={
              filterValue?.filterType || ApiDynamicFilterType.IsAnyOf
            }
            onFilterChange={(advancedFilterOption) => {
              setFilters({
                ...filters,
                [key]: {
                  values: filterValue?.values,
                  filterType: advancedFilterOption.id,
                },
              })
            }}
          />
        )
      })}
      {basicQuestionFilters?.map((filterOption) => {
        return (
          <MultiSelect
            className="shrink-0"
            key={filterOption.question}
            label={showLabels ? filterOption.label : undefined}
            placeholder={filterOption.label}
            options={filterOption.answers.map((answer) => ({
              id: answer,
              label: answer,
            }))}
            value={(
              (filters?.filters || []).find(
                (f) => f.question === filterOption.question,
              )?.answers || []
            )?.map((answer) => ({
              id: answer,
              label: answer,
            }))}
            optionsDropdownWidth="w-auto"
            onSelect={(options) => {
              const initialQuestionFilterValues = filters?.filters || []

              const existingQuestionFilterValueIndex =
                initialQuestionFilterValues.findIndex(
                  (initialQuestionFilter) =>
                    initialQuestionFilter.question === filterOption.question,
                )

              // There's already a question filter with the same question
              if (existingQuestionFilterValueIndex !== -1) {
                // Creating a new array reference so that React can detect the change
                // when calling setFilters
                const clonedInitialQuestionFilterValues = [
                  ...initialQuestionFilterValues,
                ]

                clonedInitialQuestionFilterValues[
                  existingQuestionFilterValueIndex
                ] = {
                  question: filterOption.question,
                  answers: filterOption.answers.filter((answer) =>
                    options.some((option) => option.id === answer),
                  ),
                }

                setFilters({
                  ...filters,
                  filters: clonedInitialQuestionFilterValues,
                })

                return
              }

              // There's either no question filters yet at all or there's no question filter with
              // the same question
              setFilters({
                ...filters,
                filters: [
                  ...initialQuestionFilterValues,
                  {
                    question: filterOption.question,
                    answers: filterOption.answers.filter((answer) =>
                      options.some((option) => option.id === answer),
                    ),
                  },
                ],
              })
            }}
          />
        )
      })}

      {advancedQuestionFilters?.map((filterOption) => {
        return (
          <MultiSelect
            className="shrink-0"
            key={filterOption.question}
            label={showLabels ? filterOption.label : undefined}
            placeholder={filterOption.label}
            options={filterOption.answers.map((answer) => ({
              id: answer,
              label: answer,
            }))}
            value={(
              (filters?.filters || []).find(
                (f) => f.question === filterOption.question,
              )?.answers || []
            )?.map((answer) => ({
              id: answer,
              label: answer,
            }))}
            optionsDropdownWidth="w-auto"
            onSelect={(options) => {
              const initialQuestionFilterValues = filters?.filters || []

              const existingQuestionFilterValueIndex =
                initialQuestionFilterValues.findIndex(
                  (initialQuestionFilter) =>
                    initialQuestionFilter.question === filterOption.question,
                )

              // There's already a question filter with the same question
              if (existingQuestionFilterValueIndex !== -1) {
                // Creating a new array reference so that React can detect the change
                // when calling setFilters
                const clonedInitialQuestionFilterValues = [
                  ...initialQuestionFilterValues,
                ]

                clonedInitialQuestionFilterValues[
                  existingQuestionFilterValueIndex
                ] = {
                  question: filterOption.question,
                  answers: filterOption.answers.filter((answer) =>
                    options.some((option) => option.id === answer),
                  ),
                  // Safe to cast
                  filterType:
                    (
                      clonedInitialQuestionFilterValues[
                        existingQuestionFilterValueIndex
                      ] as QuestionDynamicFilter
                    )?.filterType || ApiDynamicFilterType.IsAnyOf,
                }

                setFilters({
                  ...filters,
                  filters: clonedInitialQuestionFilterValues,
                })

                return
              }

              // There's either no question filters yet at all or there's no question filter with
              // the same question
              setFilters({
                ...filters,
                filters: [
                  ...initialQuestionFilterValues,
                  {
                    question: filterOption.question,
                    answers: filterOption.answers.filter((answer) =>
                      options.some((option) => option.id === answer),
                    ),
                    filterType: ApiDynamicFilterType.IsAnyOf,
                  },
                ],
              })
            }}
            advancedFilterValue={
              (
                (filters?.filters || []).find(
                  (f) => f.question === filterOption.question,
                ) as QuestionDynamicFilter | undefined
              )?.filterType || ApiDynamicFilterType.IsAnyOf
            }
            onFilterChange={(advancedFilterOption) => {
              const initialQuestionFilterValues = filters?.filters || []

              const existingQuestionFilterValueIndex =
                initialQuestionFilterValues.findIndex(
                  (initialQuestionFilter) =>
                    initialQuestionFilter.question === filterOption.question,
                )

              // There's already a question filter with the same question
              if (existingQuestionFilterValueIndex !== -1) {
                // Creating a new array reference so that React can detect the change
                // when calling setFilters
                const clonedInitialQuestionFilterValues = [
                  ...initialQuestionFilterValues,
                ]

                clonedInitialQuestionFilterValues[
                  existingQuestionFilterValueIndex
                ] = {
                  question: filterOption.question,
                  // Keeping the same answers, updating the filter type
                  answers:
                    clonedInitialQuestionFilterValues[
                      existingQuestionFilterValueIndex
                    ].answers,
                  filterType: advancedFilterOption.id,
                }

                setFilters({
                  ...filters,
                  filters: clonedInitialQuestionFilterValues,
                })

                return
              }

              // There's either no question filters yet at all or there's no question filter with
              // the same question
              setFilters({
                ...filters,
                filters: [
                  ...initialQuestionFilterValues,
                  {
                    question: filterOption.question,
                    answers: [],
                    filterType: advancedFilterOption.id,
                  },
                ],
              })
            }}
          />
        )
      })}

      {showResetButton && (
        <div className="flex shrink-0">
          <Button
            variant="secondary"
            className="h-[38px] self-end"
            onClick={() => {
              setFilters(defaultValues as T)
            }}
          >
            <Trans id="reset_filters">Reset filters</Trans>
          </Button>
        </div>
      )}
    </div>
  )
}
