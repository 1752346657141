import { useSortable } from "@dnd-kit/sortable"
import { clsx } from "clsx"
import { CSS } from "@dnd-kit/utilities"
import { t, Trans } from "@lingui/macro"

import { Button } from "@src/components/atoms/Button"
import { MethodologyParagraphDto } from "@src/api/methodologies"
import { useDialogQueue } from "@src/context/DialogQueueProvider/useDialogQueue"
import { IconButton } from "@src/components/atoms/IconButton"

export type ProgramParagraphItemProps = {
  methodologyParagraph: MethodologyParagraphDto
  paragraphIndex: number
  selectedParagraphIndex: number
  onClick: () => void
  removeParagraph: () => void
}

export const ProgramParagraphItem = ({
  methodologyParagraph,
  paragraphIndex,
  selectedParagraphIndex,
  onClick,
  removeParagraph,
}: ProgramParagraphItemProps) => {
  const { name } = methodologyParagraph
  const { enqueueDialog, closeCurrentDialog } = useDialogQueue()

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: paragraphIndex,
    animateLayoutChanges: () => false,
  })

  return (
    <div
      ref={setNodeRef}
      key={paragraphIndex}
      className={clsx(
        "flex flex-col",
        isDragging && "z-10 rounded-xl bg-blue/10",
      )}
      style={{ transform: CSS.Translate.toString(transform), transition }}
    >
      <div
        className={clsx(
          "group relative flex items-center gap-2 py-2",
          "border-b border-pearl-lighter",
          "cursor-pointer text-paragraph-medium text-black-light hover:text-blue",
          selectedParagraphIndex === paragraphIndex && "text-blue",
        )}
        onClick={onClick}
      >
        <IconButton
          icon="drag_indicator"
          size="large"
          variant="text"
          className="text-pearl-dark"
          {...listeners}
          {...attributes}
        />

        {name}
        <IconButton
          icon="delete"
          size="large"
          variant="text"
          className="ml-auto opacity-0 transition-opacity group-hover:opacity-100"
          onClick={(ev) => {
            ev.stopPropagation()

            enqueueDialog({
              title: t({
                message: `Are you sure you want to delete the ${name} content?`,
                id: "delete_paragraph_dialog_title",
              }),
              actions: (
                <div className="flex justify-end gap-2">
                  <Button
                    small
                    variant="secondary"
                    onClick={closeCurrentDialog}
                  >
                    <Trans id="cancel">Cancel</Trans>
                  </Button>

                  <Button
                    small
                    onClick={() => {
                      removeParagraph()
                      closeCurrentDialog()
                    }}
                  >
                    <Trans id="delete">Delete</Trans>
                  </Button>
                </div>
              ),
            })
          }}
        />
      </div>
    </div>
  )
}
