import { client } from "./client"
import { safeQueryOptions } from "@src/utils/query"
import { components } from "./schema"

export const circleKeys = {
  all: ["circles"],
  circleList: (id?: number) => [...circleKeys.all, "list", "community", id],
}

export const getCommunityCircles = async (id: number) => {
  const data = await client.GET("/api/CommunityCircle/GetByCommunity", {
    params: {
      query: {
        communityId: id,
      },
    },
  })

  return data
}

export type CommunityCircle = components["schemas"]["CommunityCircle"]

export const getCommunityCirclesQuery = (id?: number) =>
  safeQueryOptions({
    queryFn: getCommunityCircles,
    queryKeys: circleKeys.circleList(id),
    unsafeQueryFnArgs: [id],
  })

export const saveCommunityCircle = async (payload: CommunityCircle) => {
  const data = await client.POST("/api/CommunityCircle/Save", {
    body: payload,
  })

  return data
}
