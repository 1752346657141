import { useState } from "react"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { CellContext, ColumnDef } from "@tanstack/react-table"
import { useNavigate } from "@tanstack/react-router"
import { format } from "date-fns"
import { t, Trans } from "@lingui/macro"

import {
  surveyFormKeys,
  getSurveyFormsQuery,
  deleteSurveyForm,
} from "@src/api/surveyForm"

import { Table } from "@src/components/organisms/Table"
import { useCommunityId } from "@src/hooks/useCommunityId"
import { Button } from "@src/components/atoms/Button"
import { SearchInput } from "@src/components/atoms/SearchInput"
import { useDialogQueue } from "@src/context/DialogQueueProvider/useDialogQueue"

export interface FormTableItem {
  id: number | undefined
  name: string | null | undefined
  creationDate: string | null | undefined
}

const initialPageSize = 10

export const SurveyForms = () => {
  const queryClient = useQueryClient()
  const [searchValue, setSearchValue] = useState("")
  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(initialPageSize)
  const communityId = useCommunityId()
  const navigate = useNavigate()
  const { enqueueDialog, closeCurrentDialog } = useDialogQueue()

  const { data: forms, isLoading } = useQuery(getSurveyFormsQuery(communityId))

  const { mutate: deleteFormMutation, isPending: isPendingDeletion } =
    useMutation({
      mutationFn: (formId: number) => {
        return deleteSurveyForm(formId)
      },
      onSuccess: () => {
        closeCurrentDialog()

        queryClient.invalidateQueries({
          queryKey: surveyFormKeys.list(communityId),
        })
      },
    })

  const columns: ColumnDef<FormTableItem>[] = [
    {
      header: t({
        message: "Name",
        id: "name",
      }),
      accessorKey: "name",
      size: 500,
    },
    {
      header: t({
        message: "Creation Date",
        id: "creation_date",
      }),
      accessorKey: "creationDate",
    },
    {
      header: t({
        message: "Actions",
        id: "actions",
      }),
      accessorKey: "actions",
      cell: ({ row }: CellContext<FormTableItem, unknown>) => {
        const id = row.original.id || 0

        return (
          <span className="flex h-4 w-full">
            <Button
              onClick={() => {
                navigate({
                  to: "/community/$communityId/admin-panel/forms/survey/$formId",
                  params: {
                    communityId: String(communityId),
                    formId: String(id),
                  },
                  search: { view_mode: true, duplicate: false },
                })
              }}
              className="pl-0"
              variant="text"
              icon="visibility"
              small
            >
              <Trans id="view">View</Trans>
            </Button>
            <Button
              variant="text"
              icon="edit"
              small
              onClick={() => {
                navigate({
                  to: "/community/$communityId/admin-panel/forms/survey/$formId",
                  params: {
                    communityId: String(communityId),
                    formId: String(id),
                  },
                  search: { view_mode: false, duplicate: false },
                })
              }}
            >
              <Trans id="edit">Edit</Trans>
            </Button>
            <Button
              variant="text"
              icon="file_copy"
              small
              onClick={() => {
                navigate({
                  to: "/community/$communityId/admin-panel/forms/survey/$formId",
                  params: {
                    communityId: String(communityId),
                    formId: String(id),
                  },
                  search: { view_mode: false, duplicate: true },
                })
              }}
            >
              <Trans id="duplicate">Duplicate</Trans>
            </Button>
            <Button
              variant="text"
              icon="delete"
              small
              className="pl-0"
              disabled={isPendingDeletion}
              onClick={() => {
                enqueueDialog({
                  title: t({
                    message: `Are you sure you want to delete the ${row.original.name} form?`,
                    id: "delete_form_title",
                  }),
                  actions: (
                    <div className="flex justify-end gap-2">
                      <Button
                        small
                        variant="secondary"
                        onClick={closeCurrentDialog}
                      >
                        <Trans id="cancel">Cancel</Trans>
                      </Button>

                      <Button
                        small
                        onClick={() => {
                          deleteFormMutation(id)
                        }}
                      >
                        <Trans id="delete">Delete</Trans>
                      </Button>
                    </div>
                  ),
                })
              }}
            >
              <Trans id="delete">Delete</Trans>
            </Button>
          </span>
        )
      },
    },
  ]

  const filteredForms =
    forms?.list
      ?.filter(({ name }) =>
        name?.toLowerCase().includes(searchValue.toLowerCase()),
      )
      .map(({ id, name, creationDate }) => ({
        id,
        name,
        creationDate: format(
          new Date(creationDate || ""),
          "EEE dd-MM-yyyy HH:mm",
        ),
      })) ?? []

  const data = filteredForms.slice(
    pageIndex * pageSize,
    (pageIndex + 1) * pageSize,
  )

  return (
    <div className="flex flex-col gap-6">
      <div className="flex gap-3">
        <Button
          small
          onClick={() => {
            navigate({
              to: "/community/$communityId/admin-panel/forms/survey/create",
              params: {
                communityId: String(communityId),
              },
              search: { view_mode: true },
            })
          }}
        >
          <Trans id="create_new_form">Create new form</Trans>
        </Button>
        <SearchInput
          name="search"
          value={searchValue}
          onChange={(value: string) => {
            setPageIndex(0)
            setSearchValue(value)
          }}
        />
      </div>
      <Table
        columns={columns}
        data={data}
        loading={isLoading}
        itemsCount={filteredForms.length}
        pageIndex={pageIndex}
        pageSize={pageSize}
        onPageChange={setPageIndex}
        onPageSizeChange={setPageSize}
      />
    </div>
  )
}
