import { client } from "./client"
import { safeQueryOptions } from "@src/utils/query"
import { UnpackPromise, UnpackArray } from "@src/utils/types"

export const tagKeys = {
  all: ["tags"],
  userTags: (id?: number) => [...tagKeys.all, "list", "user", id],
  projectTags: (id?: number) => [...tagKeys.all, "list", "project", id],
}

export type CommunityTag = UnpackArray<
  UnpackPromise<ReturnType<typeof getUserTagsByCommunity>>
>

export const getUserTagsByCommunity = async (id: number) => {
  const data = await client.GET("/api/Tag/GetUserTagNameListByCommunity", {
    params: {
      query: {
        communityId: id,
      },
    },
  })

  return data
}

export const getUserTagsByCommunityQuery = (id?: number) =>
  safeQueryOptions({
    queryFn: getUserTagsByCommunity,
    queryKeys: tagKeys.userTags(),
    unsafeQueryFnArgs: [id],
  })

export const getProjectTagsByCommunity = async (id: number) => {
  const data = await client.GET("/api/Tag/GetProjectTagNameListByCommunity", {
    params: {
      query: {
        communityId: id,
      },
    },
  })

  return data
}

export const getProjectTagsByCommunityQuery = (id?: number) =>
  safeQueryOptions({
    queryFn: getProjectTagsByCommunity,
    queryKeys: tagKeys.projectTags(),
    unsafeQueryFnArgs: [id],
  })
