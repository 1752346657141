import { useQuery } from "@tanstack/react-query"

import { getTabInformationQuery } from "@src/api/tab"
import { getCommunityProjectFormQuery } from "@src/api/community"
import { useCommunityId } from "@src/hooks/useCommunityId"

import { Skeleton } from "@src/components/atoms/Skeleton"
import { PageDefinitionForm } from "@src/components/pages/Pages/PageDefinitionPage/PageDefinitionForm"
import { PageProjectForm } from "@src/components/pages/Pages/PageDefinitionPage/PageProjectForm"

export type PageDefinitionPageProps = {
  pageId?: number
  newPage?: boolean
}

export const PageDefinitionPage = ({
  pageId,
  newPage,
}: PageDefinitionPageProps) => {
  const communityId = useCommunityId()

  const { data: tabInformation, isLoading } = useQuery(
    getTabInformationQuery(newPage || pageId === 0 ? undefined : pageId),
  )

  const { data: projectTabInformation, isLoading: isProjectTabLoading } =
    useQuery(
      getCommunityProjectFormQuery(
        newPage || pageId !== 0 ? undefined : communityId,
      ),
    )

  if (isLoading || isProjectTabLoading) {
    return <Skeleton className="h-[100vh]" />
  }

  return pageId === 0 ? (
    <PageProjectForm projectForm={projectTabInformation} />
  ) : (
    <PageDefinitionForm tab={tabInformation} />
  )
}
