import { lazy, Suspense } from "react"
import type { EditorProps } from "@src/components/organisms/Editor"
import { Skeleton } from "@src/components/atoms/Skeleton"

const Editor = lazy(() =>
  import("@src/components/organisms/Editor").then((module) => ({
    default: module.Editor,
  })),
)

export const EditorLazy = ({
  ...props
}: EditorProps) => {
  return (
    <Suspense fallback={<Skeleton className="h-12 w-full rounded-xl" />}>
      <Editor
        {...props}
      />
    </Suspense>
  )
}
