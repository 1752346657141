import { useQuery } from "@tanstack/react-query"

import { getMethodologyContentQuery, getMethodologyQuery } from "@src/api/methodologies"

import { Skeleton } from "@src/components/atoms/Skeleton"
import { ProgramForm } from "./ProgramForm"

export type ProgramPageProps = {
  programId?: number
  newProgram?: boolean
}

export const ProgramPage = ({ programId, newProgram }: ProgramPageProps) => {
  const { data: methodologySections, isLoading: isLoadingSections } = useQuery(
    getMethodologyContentQuery(newProgram ? undefined : programId),
  )

  const { data: methodology, isLoading: isLoadingMethodology } = useQuery(
    getMethodologyQuery(newProgram ? undefined : programId),
  )

  return !isLoadingSections && !isLoadingMethodology ? (
    <ProgramForm methodologySections={methodologySections} methodology={methodology} />
  ) : (
    <Skeleton className="h-[100vh]" />
  )
}
