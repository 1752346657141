import { createFileRoute } from '@tanstack/react-router'
import { PageDefinitionPage } from "@src/components/pages/Pages/PageDefinitionPage"

export const Route = createFileRoute(
  '/_auth/_community/community/$communityId/_admin-panel/admin-panel/pages/$pageId/',
)({
  component: PageDefinitionPageWrapper,
})

function PageDefinitionPageWrapper() {
  const { pageId } = Route.useParams()
  const parsedPageId = parseInt(pageId)

  return <PageDefinitionPage pageId={parsedPageId} />

}
