import { t } from "@lingui/macro"

import { AnswerProps } from "./types"
import { FileInput, UploadedFile } from "@src/components/atoms/FileInput"
import { useValidators } from "./validators"

export const FileAnswer = ({ form, question }: AnswerProps) => {
  const { requiredString } = useValidators()
  const questionOrder = question?.questionOrder ?? 0

  const handleFileChange = (files: UploadedFile[]) => {
    const currentAnswer = form.state.values.answers?.[questionOrder] ?? {}

    form.setFieldValue(`answers`, {
      ...form.state.values.answers,
      [questionOrder]: {
        ...currentAnswer,
        answer: files[0]?.fileName ?? "",
        formQuestionId: question.id,
      },
    })
  }

  return (
    <form.Field
      name={`answers[${questionOrder}].answer`}
      validators={question.mandatoryAnswer ? requiredString : undefined}
    >
      {(field) => {
        const value = field.state.value
          ? [
              {
                fileName: field.state.value || "",
                existing: true,
              },
            ]
          : []

        return (
          <div className="flex items-end gap-4">
            <FileInput
              onChange={(files) => {
                field.handleBlur()
                handleFileChange(files)
              }}
              variant="translucent"
              required={question.mandatoryAnswer}
              error={field.state.meta.errors.join(", ")}
              small
              label={question.title || ""}
              description={question.description || ""}
              text={t({
                message: "Upload",
                id: "upload",
              })}
              name={`answers[${questionOrder}].answer`}
              value={value}
            />
          </div>
        )
      }}
    </form.Field>
  )
}
