import { client } from "./client"
import { safeQueryOptions } from "@src/utils/query"
import { components } from "./schema"

export const applicationFormKeys = {
  all: ["applicationForms"],
  list: (id?: number) => [...applicationFormKeys.all, "list", "community", id],
  single: (id?: number) => [...applicationFormKeys.all, "single", id],
}

export type ApplicationFormResponse =
  components["schemas"]["FormDetailDtoResultListDto"]
export type ApplicationFormDto = components["schemas"]["ApplicationForm"]
export type ApplicationFormQuestionDto =
  components["schemas"]["ApplicationFormQuestion"]
export type ApplicationFormAnswerDto =
  components["schemas"]["ApplicationFormAnswer"]

export const getApplicationForms = async (id: number) => {
  const data = await client.GET("/api/ApplicationForm/GetAll", {
    params: { query: { communityId: id } },
  })

  return data
}

export const getApplicationFormsQuery = (id?: number) =>
  safeQueryOptions({
    queryFn: getApplicationForms,
    queryKeys: applicationFormKeys.list(id),
    unsafeQueryFnArgs: [id],
  })

export const getApplicationForm = async (id: number) => {
  const data = await client.GET("/api/ApplicationForm/{id}", {
    params: {
      path: { id },
    },
  })

  return data
}

export const getApplicationFormQuery = (id?: number) =>
  safeQueryOptions({
    queryFn: getApplicationForm,
    queryKeys: applicationFormKeys.single(id),
    unsafeQueryFnArgs: [id],
  })

export const saveApplicationForm = async (
  payload: ApplicationFormDto,
  id?: number,
) => {
  if (!id) {
    const data = await client.POST("/api/ApplicationForm", {
      body: payload,
    })

    return data
  }

  const data = await client.PUT("/api/ApplicationForm/{id}", {
    params: {
      path: { id },
    },
    body: payload,
  })

  return data
}

export const deleteApplicationForm = async (id: number) => {
  const data = await client.DELETE("/api/ApplicationForm/{id}", {
    params: {
      path: { id },
    },
  })

  return data
}
