import { useState } from "react"
import { t, Trans } from "@lingui/macro"
import { useQuery } from "@tanstack/react-query"
import { useNavigate } from "@tanstack/react-router"

import { getCommunityFormsQuery } from "@src/api/form"
import { useCommunityId } from "@src/hooks/useCommunityId"
import { DatePicker } from "@src/components/atoms/DatePicker"
import { Select } from "@src/components/atoms/Select"
import { Button } from "@src/components/atoms/Button"
import { InfoContainer } from "@src/components/atoms/InfoContainer"

import { SectionProps } from "./types"
import { useValidators } from "../validators"

const languageOptions = [
  { id: "en-EN", label: "English" },
  { id: "de-DE", label: "German" },
  { id: "es-ES", label: "Spanish" },
  { id: "fr-FR", label: "French" },
  { id: "it-IT", label: "Italian" },
  { id: "pt-PT", label: "Portuguese" },
  { id: "hr-HR", label: "Croatian" },
  { id: "ru-RU", label: "Russian" },
  { id: "ro-RO", label: "Romanian" },
  { id: "bg-BG", label: "Bulgarian" },
  { id: "gr-GR", label: "Greek" },
  { id: "sr-SR", label: "Serbian" },
  { id: "tr-TR", label: "Turkish" },
  { id: "ar-AR", label: "Arabic" },
  { id: "zh-ZH", label: "Chinese" },
]

const booleanOptions = [
  {
    id: 0,
    label: "No",
  },
  {
    id: 1,
    label: "Yes",
  },
]

export const BaseInfoSection = ({ form }: SectionProps) => {
  const validators = useValidators()
  const communityId = useCommunityId()
  const navigate = useNavigate()

  const [showInfo, setShowInfo] = useState(true)

  const { data: forms } = useQuery(getCommunityFormsQuery(communityId))
  const formOptions =
    forms?.list?.map(({ id, name }) => ({
      id: id as number,
      label: name as string,
    })) || []

  return (
    <section className="flex w-full flex-col gap-6 rounded-xl border border-pearl-lighter bg-white p-6">
      <div className="flex flex-col items-baseline gap-3 lg:flex-row">
        <form.Field name="startDate" validators={validators.requiredString}>
          {(field) => (
            <DatePicker
              name="startDate"
              error={field.state.meta.errors.join(", ")}
              label={t({
                message: "Start date & time",
                id: "start_date_time",
              })}
              className="w-full"
              required
              hasTimePicker
              onChange={(value) => {
                field.handleChange(value?.toISOString())
              }}
              value={
                field.state.value ? new Date(field.state.value) : new Date()
              }
              onBlur={field.handleBlur}
            />
          )}
        </form.Field>
        <form.Field name="endDate" validators={validators.requiredString}>
          {(field) => (
            <DatePicker
              name="endDate"
              error={field.state.meta.errors.join(", ")}
              label={t({
                message: "End date & time",
                id: "end_date_time",
              })}
              className="w-full"
              required
              hasTimePicker
              onChange={(value) => {
                field.handleChange(value?.toISOString())
              }}
              value={
                field.state.value ? new Date(field.state.value) : new Date()
              }
              onBlur={field.handleBlur}
            />
          )}
        </form.Field>

        <form.Field name="language" validators={validators.requiredString}>
          {(field) => (
            <Select
              error={field.state.meta.errors.join(", ")}
              label={t({
                message: "Application language",
                id: "application_language",
              })}
              placeholder={t({
                message: "Please select a language",
                id: "select_a_language",
              })}
              required
              className="w-full"
              options={languageOptions}
              onSelect={({ id }) => {
                field.handleChange(id)
              }}
              value={
                languageOptions.find(({ id }) => id === field.state.value) ||
                null
              }
              onBlur={field.handleBlur}
            />
          )}
        </form.Field>
      </div>

      <div className="flex items-end gap-3 lg:w-full">
        <form.Field name="formId" validators={validators.requiredNumber}>
          {(field) => (
            <Select
              error={field.state.meta.errors.join(", ")}
              label={t({
                message: "Choose a form",
                id: "choose_form",
              })}
              placeholder={t({
                message: "Please select a form",
                id: "select_a_form",
              })}
              required
              className="w-full"
              options={formOptions}
              onSelect={({ id }) => {
                field.handleChange(id as number)
              }}
              value={
                formOptions.find(({ id }) => id === field.state.value) || null
              }
              onBlur={field.handleBlur}
            />
          )}
        </form.Field>
        <span className="pb-2 text-paragraph-medium text-pearl-dark">Or</span>
        <Button
          variant="ternary"
          small
          icon="description"
          className="w-full"
          onClick={() =>
            navigate({
              to: "/community/$communityId/admin-panel/application-definitions/create",
              params: {
                communityId: String(communityId),
              },
            })
          }
        >
          <Trans id="create_new_form">Create new form</Trans>
        </Button>
      </div>

      <div className="flex flex-col items-center gap-3 lg:flex-row">
        <form.Field name="createProject">
          {(field) => (
            <Select
              error={field.state.meta.errors.join(", ")}
              label={t({
                message: "Create {100} after accepting",
                id: "create_project_after_accepting",
              })}
              info={
                <div className="flex flex-col gap-3 p-2 text-paragraph-medium">
                  <h6>
                    <Trans id="create_project_info_1">
                      If the option is "YES," the applicant can either apply
                      with an existing startup or create a new startup during
                      the application process:
                    </Trans>
                  </h6>
                  <ul className="flex flex-col gap-2">
                    <li>
                      <Trans id="create_project_info_2">
                        <strong>For a new startup:</strong> The startup and its
                        founder will be onboarded into the program once the
                        application is accepted.
                      </Trans>
                    </li>
                    <li>
                      <Trans id="create_project_info_3">
                        <strong>For an existing startup:</strong> The curriculum
                        will be added to the startup's profile upon acceptance
                        of the application.
                      </Trans>
                    </li>
                  </ul>
                </div>
              }
              placeholder={t({
                message: "Please select a value",
                id: "select_a_value",
              })}
              required
              className="w-full lg:w-1/3"
              options={booleanOptions}
              onSelect={({ id }) => {
                field.handleChange(id === 0 ? false : true)
              }}
              value={
                booleanOptions.find(({ id }) =>
                  field.state.value ? id === 1 : id === 0,
                ) || null
              }
              onBlur={field.handleBlur}
            />
          )}
        </form.Field>

        <form.Subscribe
          selector={(state) => ({
            createProject: state.values.createProject,
          })}
          children={({ createProject }) =>
            showInfo &&
            createProject && (
              <InfoContainer
                type="default"
                className="w-full lg:w-2/3"
                text={t({
                  message:
                    "This will add 7 default questions to the application form ({100} name, description, industry, development stage, country, city, SDGs). No need to repeat them in your application form.",
                  id: "application_project_info",
                })}
                onClose={() => setShowInfo(false)}
              />
            )
          }
        />
      </div>
    </section>
  )
}
