import { RadioButtonList } from "@src/components/atoms/RadioButtonList"
import { AnswerProps } from "./types"
import { useValidators } from "./validators"

export const RadioAnswer = ({ form, question }: AnswerProps) => {
  const { requiredNumber } = useValidators()
  const questionOrder = question?.questionOrder ?? 0

  // Ensure options are always defined
  const options =
    question?.questionOptions?.map(
      ({ questionOption, questionOptionOrder }) => ({
        id: questionOptionOrder ?? 0,
        label: questionOption ?? "",
      }),
    ) || []

  const handleChange = (selectedOption: { id: number }) => {
    const currentAnswer = form.state.values.answers?.[questionOrder] ?? {}

    form.setFieldValue("answers", {
      ...form.state.values.answers,
      [questionOrder]: {
        ...currentAnswer,
        optionAnswerOrder: selectedOption.id,
        formQuestionId: question.id,
      },
    })
  }

  return (
    <form.Field
      name={`answers[${questionOrder}].optionAnswerOrder`}
      validators={question.mandatoryAnswer ? requiredNumber : undefined}
    >
      {(field) => (
        <RadioButtonList
          error={field.state.meta.errors.join(", ")}
          label={question.title ?? ""}
          description={question.description ?? ""}
          options={options}
          required={question.mandatoryAnswer}
          onChange={handleChange}
          value={field.state.value !== undefined ? field.state.value : null}
          onBlur={field.handleBlur}
        />
      )}
    </form.Field>
  )
}
