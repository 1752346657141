import { t } from "@lingui/macro"
import { z } from "zod"

export const useValidators = () => ({
  requiredString: {
    onBlur: z
      .string({
        required_error: t({
          message: "This field is required",
          id: "required_field",
        }),
      })
      .refine((value) => value.length >= 0),
  },
  requiredNumber: {
    onBlur: z.number({
      required_error: t({
        message: "This field is required",
        id: "required_field",
      }),
    }),
  },
  requiredDate: {
    onBlur: z.date({
      required_error: t({
        message: "This field is required",
        id: "required_field",
      }),
    }),
  },
})
