import { client } from "./client"
import { safeQueryOptions } from "@src/utils/query"
import { components } from "./schema"

export const surveyFormKeys = {
  all: ["surveyForms"],
  list: (id?: number) => [...surveyFormKeys.all, "list", "community", id],
  single: (id?: number) => [...surveyFormKeys.all, "single", id],
}

export type SurveyFormResponse =
  components["schemas"]["FormDetailDtoResultListDto"]
export type SurveyFormDto = components["schemas"]["SurveyForm"]
export type SurveyFormQuestionDto = components["schemas"]["SurveyFormQuestion"]
export type SurveyFormAnswerDto = components["schemas"]["SurveyFormAnswer"]

export const getSurveyForms = async (id: number) => {
  const data = await client.GET("/api/SurveyForm/GetAll", {
    params: { query: { communityId: id } },
  })

  return data
}

export const getSurveyFormsQuery = (id?: number) =>
  safeQueryOptions({
    queryFn: getSurveyForms,
    queryKeys: surveyFormKeys.list(id),
    unsafeQueryFnArgs: [id],
  })

export const getSurveyForm = async (id: number) => {
  const data = await client.GET("/api/SurveyForm/{id}", {
    params: {
      path: { id },
    },
  })

  return data
}

export const getSurveyFormQuery = (id?: number) =>
  safeQueryOptions({
    queryFn: getSurveyForm,
    queryKeys: surveyFormKeys.single(id),
    unsafeQueryFnArgs: [id],
  })

export const saveSurveyForm = async (payload: SurveyFormDto, id?: number) => {
  if (!id) {
    const data = await client.POST("/api/SurveyForm", {
      body: payload,
    })

    return data
  }

  const data = await client.PUT("/api/SurveyForm/{id}", {
    params: {
      path: { id },
    },
    body: payload,
  })

  return data
}

export const deleteSurveyForm = async (id: number) => {
  const data = await client.DELETE("/api/SurveyForm/{id}", {
    params: {
      path: { id },
    },
  })

  return data
}
