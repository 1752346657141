import { t } from "@lingui/macro"
import {
  ApplicationFilterValues,
  ApplicationsResponses,
  ApplicationsFilters,
  ApplicationStatus,
} from "@src/api/application"
import { OptionProps } from "@src/components/atoms/Fieldset/common"
import { BadgeProps } from "@src/components/atoms/Badge"

export const filterValuesToFilterOptions = (
  values: ApplicationFilterValues,
): Record<string, OptionProps<string | number>[]> => {
  return {
    tagFilter:
      values.applicationTags?.map((tag) => ({
        id: tag,
        label: tag,
      })) || [],
    countryFilter:
      values.applicationCountries
        ?.filter(
          (country) =>
            country.id !== undefined &&
            country.name !== undefined &&
            country.name !== null,
        )
        .map((country) => ({
          id: country.id || 0,
          label: country.name || "",
        })) || [],
    industryFilter:
      values.applicationIndustries
        ?.filter(
          (industry) =>
            industry.id !== undefined &&
            industry.name !== undefined &&
            industry.name !== null,
        )
        .map((industry) => ({
          id: industry.id || 0,
          label: industry.name || "",
        })) || [],
    sdgFilter:
      values.applicationSDGs
        ?.filter(
          (sdg) =>
            sdg.id !== undefined && sdg.name !== undefined && sdg.name !== null,
        )
        .map((sdg) => ({
          id: sdg.id || 0,
          label: sdg.name || "",
        })) || [],
  }
}

export const isEmptyInitialFilter = (filterValues: ApplicationFilterValues) =>
  Object.values(filterValues).every((value) => value === null)

// If no filters are applied to api/ApplicationDefinition/{id}/Applications, then it returns an object
// with all the filters set to null. This function checks if all those filters are null. If they are, we
// have to use the response from /api/ApplicationDefinition/{id}/ApplicationsFilters to set the initial
// possible filter values. But there is another criteria. If the user has already applied a filter, the
// api/ApplicationDefinition/{id}/Applications will return no values for that filter, so that filter would
// essentially disappear from the filter options or be disabled. The user would have to reset all the filters
// to be able to select a different country for example. In this case, we have to keep the filter options
// that the user has already selected and show all the options from /api/ApplicationDefinition/{id}/ApplicationsFilters
export const pickFilterOptionValues = (
  filtersResponse: ApplicationFilterValues,
  applicationsResponse: ApplicationsResponses,
  filterValues: ApplicationsFilters,
) => {
  const definitionFilters = applicationsResponse.applicationDefinitionFilters
  const initialFilterOptions = filterValuesToFilterOptions(filtersResponse)

  if (
    definitionFilters === undefined ||
    definitionFilters === null ||
    isEmptyInitialFilter(definitionFilters)
  ) {
    return filterValuesToFilterOptions(filtersResponse)
  }

  const definitionFilterOptions = filterValuesToFilterOptions(definitionFilters)

  const filterOptions = definitionFilterOptions

  if (filterValues?.tagFilter?.values?.length) {
    filterOptions.tagFilter = initialFilterOptions.tagFilter
  }

  if (filterValues?.countryFilter?.values?.length) {
    filterOptions.countryFilter = initialFilterOptions.countryFilter
  }

  if (filterValues?.industryFilter?.values?.length) {
    filterOptions.industryFilter = initialFilterOptions.industryFilter
  }

  if (filterValues?.sdgFilter?.values?.length) {
    filterOptions.sdgFilter = initialFilterOptions.sdgFilter
  }

  return filterOptions
}

export const applicationStatusToLabel = (status: ApplicationStatus) => {
  const statusMap = {
    [ApplicationStatus.Apply]: t({ message: "Apply", id: "status_apply" }),
    [ApplicationStatus.Draft]: t({ message: "Draft", id: "status_draft" }),
    [ApplicationStatus.Pending]: t({
      message: "Pending",
      id: "status_pending",
    }),
    [ApplicationStatus.Accepted]: t({
      message: "Accepted",
      id: "status_accepted",
    }),
    [ApplicationStatus.Denied]: t({ message: "Denied", id: "status_denied" }),
  }

  return statusMap[status]
}

export const applicationStatusToColor: Record<
  ApplicationStatus,
  BadgeProps["color"]
> = {
  [ApplicationStatus.Apply]: "default",
  [ApplicationStatus.Draft]: "default",
  [ApplicationStatus.Pending]: "yellow",
  [ApplicationStatus.Accepted]: "green",
  [ApplicationStatus.Denied]: "rose",
}
