import { Table, flexRender } from "@tanstack/react-table"
import { clsx } from "clsx"
import { t } from "@lingui/macro"

import { Empty } from "@src/components/atoms/Empty"
import { Icon } from "@src/components/atoms/Icon"

export type DesktopTableProps<T> = {
  table: Table<T>
  stickyColumn?: boolean
}

const styles = {
  table: [
    "w-full bg-white px-8 pb-2",
    "border-separate border-spacing-0 rounded-xl",
  ],
  th: [
    "z-10 bg-white p-4 first:pl-0",
    "border-b border-pearl-lighter",
    "whitespace-nowrap text-left text-paragraph-medium font-semibold leading-none text-black",
  ],
  td: [
    "z-10 p-4 py-3.5 first:pl-0",
    "border-b border-pearl-lighter bg-white",
    "group-last:border-none",
    "whitespace-nowrap text-left text-paragraph-medium leading-none text-black",
  ],
  sticky: [
    "sticky left-8 z-20",
    "after:content-[''] after:w-[1px] after:h-3/4 after:bg-pearl-light",
    "after:absolute after:right-0 after:top-1/2 after:-translate-y-1/2",
    "before:content-[''] before:w-8 before:h-[calc(100%+2px)] before:bg-white",
    "before:absolute before:-left-8 before:top-0",
  ],
}
export const DesktopTable = <T extends object>({
  table,
  stickyColumn,
}: DesktopTableProps<T>) => {
  return (
    <div className="relative overflow-x-auto rounded-xl border border-pearl-lighter bg-white">
      <table className={clsx(styles.table)}>
        <colgroup>
          {table.getAllColumns().map((column, index) => (
            <col
              key={index}
              style={{
                width: column.getSize(),
              }}
            />
          ))}
        </colgroup>

        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => {
                const canSort = header.column.getCanSort()
                const isSorted = header.column.getIsSorted()
                const toggleSorting = header.column.getToggleSortingHandler()

                return (
                  <th
                    key={header.id}
                    className={clsx(
                      styles.th,
                      stickyColumn && index === 0 && styles.sticky,
                      "relative",
                      canSort && "cursor-pointer select-none",
                    )}
                    onClick={canSort ? toggleSorting : undefined}
                  >
                    <div className="flex items-center">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      {canSort && (
                        <span className="inline-flex h-5 w-5 items-center justify-center pl-1">
                          <Icon
                            icon={
                              isSorted === "asc"
                                ? "keyboard_arrow_up"
                                : isSorted === "desc"
                                  ? "keyboard_arrow_down"
                                  : "unfold_more"
                            }
                            size={
                              isSorted === "asc" || isSorted === "desc"
                                ? "small"
                                : "large"
                            }
                            className={clsx(
                              (isSorted === "asc" || isSorted === "desc") &&
                                "font-bold",
                            )}
                          />
                        </span>
                      )}
                    </div>
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.length === 0 && (
            <tr>
              <td
                colSpan={table.getAllColumns().length}
                className="text-center"
              >
                <Empty
                  title={t({
                    message: "No data available",
                    id: "no_data_available",
                  })}
                  description={t({
                    message:
                      "There is no available data to show. Please choose different filters and try again.",
                    id: "no_data_available_description",
                  })}
                />
              </td>
            </tr>
          )}
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className="group">
              {row.getVisibleCells().map((cell, index) => (
                <td
                  key={cell.id}
                  className={clsx(
                    styles.td,
                    stickyColumn && index === 0 && styles.sticky,
                    "relative",
                  )}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
