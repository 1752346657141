import { useEffect, useState } from "react"
import { t, Trans } from "@lingui/macro"

import { uploadFile } from "@src/api/file"
import { Button } from "@src/components/atoms/Button"
import { Image } from "@src/components/atoms/Image"
import {
  ImageUpload,
  ImageDetails,
} from "@src/components/molecules/ImageUpload"
import { Dialog } from "@src/components/atoms/Dialog"

import SectionLogo1 from "@src/static/section-logos/section-logo-1.webp"
import SectionLogo2 from "@src/static/section-logos/section-logo-2.webp"
import SectionLogo3 from "@src/static/section-logos/section-logo-3.webp"
import SectionLogo4 from "@src/static/section-logos/section-logo-4.webp"
import SectionLogo5 from "@src/static/section-logos/section-logo-5.webp"
import SectionLogo6 from "@src/static/section-logos/section-logo-6.webp"
import SectionLogo7 from "@src/static/section-logos/section-logo-7.webp"
import SectionLogo8 from "@src/static/section-logos/section-logo-8.webp"
import SectionLogo9 from "@src/static/section-logos/section-logo-9.webp"
import SectionLogo10 from "@src/static/section-logos/section-logo-10.webp"
import SectionLogo11 from "@src/static/section-logos/section-logo-11.webp"
import SectionLogo12 from "@src/static/section-logos/section-logo-12.webp"
import SectionLogo13 from "@src/static/section-logos/section-logo-13.webp"
import SectionLogo14 from "@src/static/section-logos/section-logo-14.webp"
import SectionLogo15 from "@src/static/section-logos/section-logo-15.webp"
import SectionLogo16 from "@src/static/section-logos/section-logo-16.webp"
import SectionLogo17 from "@src/static/section-logos/section-logo-17.webp"
import SectionLogo18 from "@src/static/section-logos/section-logo-18.webp"
import SectionLogo19 from "@src/static/section-logos/section-logo-19.webp"
import SectionLogo20 from "@src/static/section-logos/section-logo-20.webp"
import SectionLogo21 from "@src/static/section-logos/section-logo-21.webp"
import SectionLogo22 from "@src/static/section-logos/section-logo-22.webp"
import SectionLogo23 from "@src/static/section-logos/section-logo-23.webp"
import SectionLogo24 from "@src/static/section-logos/section-logo-24.webp"
import SectionLogo25 from "@src/static/section-logos/section-logo-25.webp"
import SectionLogo26 from "@src/static/section-logos/section-logo-26.webp"
import SectionLogo27 from "@src/static/section-logos/section-logo-27.webp"
import SectionLogo28 from "@src/static/section-logos/section-logo-28.webp"
import SectionLogo29 from "@src/static/section-logos/section-logo-29.webp"

const logos = [
  SectionLogo1,
  SectionLogo2,
  SectionLogo3,
  SectionLogo4,
  SectionLogo5,
  SectionLogo6,
  SectionLogo7,
  SectionLogo8,
  SectionLogo9,
  SectionLogo10,
  SectionLogo11,
  SectionLogo12,
  SectionLogo13,
  SectionLogo14,
  SectionLogo15,
  SectionLogo16,
  SectionLogo17,
  SectionLogo18,
  SectionLogo19,
  SectionLogo20,
  SectionLogo21,
  SectionLogo22,
  SectionLogo23,
  SectionLogo24,
  SectionLogo25,
  SectionLogo26,
  SectionLogo27,
  SectionLogo28,
  SectionLogo29,
]

export type LogoSelectorProps = {
  value: string | undefined
  name: string | null | undefined
  onChange: (value: string) => void
}

export const LogoSelector = ({ value, name, onChange }: LogoSelectorProps) => {
  const [open, setOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState<ImageDetails | null>({
    blob: null,
    url: value,
  })

  useEffect(() => {
    setSelectedImage({
      url: value,
    })
  }, [value])

  return (
    <>
      <button className="size-8" onClick={() => setOpen(true)}>
        <Image
          src={value}
          className="size-8 !object-contain"
          alt={name || "Program section logo"}
        />
      </button>
      <Dialog
        title={t({
          message: "Select icon",
          id: "select_icon",
        })}
        open={open}
        onClose={() => setOpen(false)}
        actions={
          <div className="flex justify-end gap-2">
            <Button small variant="secondary" onClick={() => setOpen(false)}>
              <Trans id="cancel">Cancel</Trans>
            </Button>

            <Button
              small
              onClick={async () => {
                if (selectedImage?.blob) {
                  const url = await uploadFile({
                    file: selectedImage?.blob,
                    folder: "custom_logo",
                  })

                  onChange(url)
                } else {
                  onChange(selectedImage?.url || "")
                }

                setOpen(false)
              }}
            >
              <Trans id="save">Save</Trans>
            </Button>
          </div>
        }
      >
        <div className="flex flex-col gap-4">
          <ImageUpload
            name="logo"
            value={{
              url: selectedImage?.url,
              blob: null,
            }}
            imageContainerClassname="aspect-square size-20"
            className="items-center"
            onChange={(imageDetails) => {
              setSelectedImage({
                blob: imageDetails?.blob,
                url: null,
              })
            }}
          />
          <div className="flex space-x-2 overflow-x-auto rounded-lg bg-blue-light p-2">
            {logos.map((logo, index) => (
              <div
                key={index}
                className="size-12 flex-shrink-0 cursor-pointer"
                onClick={() => setSelectedImage({ url: logo, blob: null })}
              >
                <Image
                  src={logo}
                  className="size-12 !object-contain"
                  alt={`Section logo ${index + 1}`}
                />
              </div>
            ))}
          </div>
        </div>
      </Dialog>
    </>
  )
}
