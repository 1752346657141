import { useState } from "react"
import { FormApi, ReactFormApi } from "@tanstack/react-form"
import { z } from "zod"
import { t } from "@lingui/macro"
import { useSortable } from "@dnd-kit/sortable"
import { clsx } from "clsx"
import { CSS } from "@dnd-kit/utilities"

import { FormQuestionAnswerType, EditorFormDto } from "@src/api/form"
import { Input } from "@src/components/atoms/Input"
import { Select } from "@src/components/atoms/Select"
import { Checkbox } from "@src/components/atoms/Checkbox"
import { IconButton } from "@src/components/atoms/IconButton"
import { EditorLazy } from "@src/components/organisms/Editor/EditorLazy"
import { getAnswerTypes } from "./utils"
import { GenericFormQuestionOptions } from "./QuestionOptions"
import { GenericFormQuestionScale } from "./QuestionScale"
import { GenericFormConditionalLogic } from "./ConditionalLogic"
import { QuestionPrivacy } from "./QuestionPrivacy"
import { ZodValidator } from "../"

type GenericFormQuestionProps = {
  index: number
  form: FormApi<EditorFormDto, ZodValidator> &
    ReactFormApi<EditorFormDto, ZodValidator>
  privacySelector?: boolean
}

export const GenericFormQuestion = ({
  index,
  form,
  privacySelector,
}: GenericFormQuestionProps) => {
  const conditionalLogicsList =
    form.getFieldValue(`questions[${index}].formQuestionConditionalLogicList`)
      ?.length || 0

  const [withConditionalLogic, setWithConditionalLogic] = useState(
    conditionalLogicsList > 0,
  )
  const answerTypes = getAnswerTypes()

  const validators = {
    title: {
      onBlur: z
        .string()
        .min(
          2,
          t({
            message: "The question name must be at least 2 characters",
            id: "question_name_min_2",
          }),
        )
        .max(
          80,
          t({
            message: "The question name must be under 80 characters",
            id: "question_name_under_80",
          }),
        ),
    },
    answerType: {
      onChange: z
        .number()
        .min(0, t({ message: "This field is required", id: "required_field" })),
    },
  }
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: index,
    animateLayoutChanges: () => false,
  })

  return (
    <div
      key={index}
      ref={setNodeRef}
      className={clsx(
        "relative flex flex-col gap-3 rounded-xl bg-alice-blue px-12 py-7",
        isDragging && "z-20 shadow-lg",
      )}
      style={{ transform: CSS.Translate.toString(transform), transition }}
    >
      <form.Subscribe
        selector={(state) => [state.values.questions]}
        children={() => (
          <>
            <div className="flex flex-col gap-2 md:flex-row">
              <form.Field
                name={`questions[${index}].title`}
                validators={validators.title}
                children={(field) => (
                  <Input
                    name={`questions[${index}].title`}
                    className="md:w-2/3"
                    error={field.state.meta.errors.join(", ")}
                    placeholder={t({
                      message: "Question Name",
                      id: "question_name",
                    })}
                    variant="secondary"
                    onChange={(value) => {
                      field.handleChange(value as string)
                    }}
                    value={field.state.value}
                    onBlur={field.handleBlur}
                  />
                )}
              />
              <form.Field
                name={`questions[${index}].answerType`}
                validators={validators.answerType}
                children={(field) => (
                  <Select
                    className="md:w-1/3"
                    placeholder={t({
                      message: "Question Type",
                      id: "question_type",
                    })}
                    error={field.state.meta.errors.join(", ")}
                    variant="secondary"
                    onSelect={({ id }: { id: FormQuestionAnswerType }) => {
                      field.handleChange(id)
                    }}
                    value={answerTypes.find(
                      ({ id }) => id === field.state.value,
                    )}
                    options={answerTypes}
                  />
                )}
              />
            </div>

            <form.Field
              name={`questions[${index}].description`}
              children={(field) => (
                <EditorLazy
                  className="rounded-xl bg-white placeholder:text-pearl-dark"
                  value={field.state.value}
                  placeholder={t({
                    message: "Description",
                    id: "description",
                  })}
                  onChange={(e) => {
                    field.handleChange(e)
                  }}
                />
              )}
            />

            <GenericFormQuestionOptions form={form} index={index} />

            <GenericFormQuestionScale form={form} index={index} />

            <div className="flex flex-col gap-2 md:flex-row md:gap-8">
              <form.Field
                name={`questions[${index}].mandatoryAnswer`}
                children={(field) => (
                  <Checkbox
                    checked={!!field.state.value}
                    onChange={(e) => {
                      field.handleChange(e)
                    }}
                    label={t({
                      message: "Mandatory question",
                      id: "mandatory_question",
                    })}
                  />
                )}
              />
              <Checkbox
                checked={withConditionalLogic}
                onChange={(e) => {
                  setWithConditionalLogic(e)
                }}
                label={t({
                  message: "Conditional Logic",
                  id: "conditional_logic",
                })}
              />
              <form.Field
                name={`questions[${index}].isFilterable`}
                children={(field) => (
                  <Checkbox
                    checked={!!field.state.value}
                    onChange={(e) => {
                      field.handleChange(e)
                    }}
                    label={t({
                      message: "Filterable",
                      id: "filterable",
                    })}
                  />
                )}
              />

              <form.Subscribe
                selector={(state) => ({
                  answerType: state.values.questions?.[index]?.answerType,
                })}
                children={({ answerType }) =>
                  answerType === FormQuestionAnswerType.NumericAnswer && (
                    <form.Field
                      name={`questions[${index}].kpiConvert`}
                      children={(field) => (
                        <Checkbox
                          checked={!!field.state.value}
                          onChange={(e) => {
                            field.handleChange(e)
                          }}
                          label={t({
                            message: "Convert to KPI",
                            id: "convert_to_kpi",
                          })}
                        />
                      )}
                    />
                  )
                }
              />
            </div>

            {withConditionalLogic && (
              <GenericFormConditionalLogic form={form} index={index} />
            )}

            {privacySelector && (
              <form.Subscribe
                selector={(state) => ({
                  privacyLevel: state.values.questions?.[index]?.privacyLevel,
                  privacyCirclesIds:
                    state.values.questions?.[index]?.privacyCirclesIds,
                })}
                children={({ privacyCirclesIds, privacyLevel }) => (
                  <QuestionPrivacy
                    privacyLevel={privacyLevel || 1}
                    circleIds={privacyCirclesIds || []}
                    onChange={({
                      privacyLevel: newPrivacyLevel,
                      circleIds: newCircleIds,
                    }) => {
                      if (newPrivacyLevel) {
                        form.setFieldValue(
                          `questions[${index}].privacyLevel`,
                          newPrivacyLevel,
                        )
                      }

                      if (newCircleIds) {
                        form.setFieldValue(
                          `questions[${index}].privacyCirclesIds`,
                          newCircleIds,
                        )
                      }
                    }}
                  />
                )}
              />
            )}

            <IconButton
              icon="delete"
              size="largex"
              variant="text"
              className="absolute right-1 top-16 text-pearl-dark"
              onClick={() => form.removeFieldValue("questions", index)}
            />
            <IconButton
              icon="drag_indicator"
              size="largex"
              variant="text"
              className="absolute left-3 top-16 text-pearl-dark"
              {...listeners}
              {...attributes}
            />
          </>
        )}
      />
    </div>
  )
}
