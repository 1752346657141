import { useQuery } from "@tanstack/react-query"
import {
  getApplicationResponseQuery,
  getCommunityApplicationDefinitionQuery,
} from "@src/api/application"
import { Skeleton } from "@src/components/atoms/Skeleton"
import { Answers } from "@src/components/organisms/Answers"
import { PageContainer } from "@src/components/atoms/PageContainer"
import { InfoContainer } from "@src/components/atoms/InfoContainer"
import { t } from "@lingui/macro"

export type EvaluateResponseProps = {
  applicationDefinitionId: number
  responseId: number
}

export const EvaluateResponse = ({
  applicationDefinitionId,
  responseId,
}: EvaluateResponseProps) => {
  const { data: applicationDefinition, isLoading: definitionLoading } =
    useQuery(getCommunityApplicationDefinitionQuery(applicationDefinitionId))

  const { data: response, isLoading: responseLoading } = useQuery(
    getApplicationResponseQuery(responseId),
  )

  if (definitionLoading || responseLoading) {
    return (
      <>
        <Skeleton className="h-[200px]" />
        <Skeleton className="h-[100vh]" />
      </>
    )
  }

  if (!response?.answers?.list || !applicationDefinition?.form) {
    return (
      <PageContainer>
        <InfoContainer
          type="error"
          text={t({
            message: "Failed to load information. Please refresh the page!",
            id: "failed_to_load",
          })}
        />
      </PageContainer>
    )
  }

  return (
    <div>
      <PageContainer>
        <Answers
          answers={response?.answers?.list}
          form={applicationDefinition?.form}
        />
      </PageContainer>
    </div>
  )
}
