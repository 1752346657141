import { client } from "./client"
import { safeQueryOptions } from "@src/utils/query"
import { UnpackArray, UnpackPromise } from "@src/utils/types"
import { components } from "@src/api/schema"
import { EditorFormQuestion } from "@src/api/form"

export const tabSettingsKeys = {
  all: ["pagesTabs"],
  tabsList: (communityId?: number) => [
    ...tabSettingsKeys.all,
    "list",
    "tabs",
    communityId,
  ],
  tab: (tabId?: number) => [...tabSettingsKeys.all, "tab", tabId],
}

export type TabForm = UnpackPromise<ReturnType<typeof getTabInformation>>
export type PagesTabSettingsByCommunity = UnpackArray<
  UnpackPromise<ReturnType<typeof getTabSettingsByCommunity>>
>
export type TabDto = components["schemas"]["TabDto"]
export type TabFormDto = Omit<TabDto["form"], "questions"> & {
  questions?: EditorFormQuestion[] | null
}
export type TabEditorDto = Omit<TabDto, "form"> & TabFormDto

export const getTabSettingsByCommunity = async (communityId: number) => {
  const data = await client.GET("/api/Community/{id}/Tabs", {
    params: {
      path: {
        id: communityId,
      },
    },
  })

  return data
}
export const getTabSettingsByCommunityQuery = (communityId: number) =>
  safeQueryOptions({
    queryFn: getTabSettingsByCommunity,
    queryKeys: tabSettingsKeys.tabsList(communityId),
    unsafeQueryFnArgs: [communityId],
  })

export const saveTab = async (payload: TabDto, id?: number) => {
  if (!id) {
    const data = await client.POST("/api/Tab", {
      body: payload,
    })

    return data
  }

  const data = await client.PUT("/api/Tab/{id}", {
    params: {
      path: {
        id: id,
      },
    },
    body: payload,
  })

  return data
}

export const deleteTab = async (tabId: number) => {
  const data = await client.DELETE("/api/Tab/{id}", {
    params: {
      path: {
        id: tabId,
      },
    },
  })

  return data
}

export const getTabInformation = async (tabId: number) => {
  const data = await client.GET("/api/Tab/{id}", {
    params: {
      path: {
        id: tabId,
      },
    },
  })

  return data
}

export const getTabInformationQuery = (tabId?: number) =>
  safeQueryOptions({
    queryFn: getTabInformation,
    queryKeys: tabSettingsKeys.tab(tabId),
    unsafeQueryFnArgs: [tabId],
  })
