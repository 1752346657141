import { client } from "./client"
import { safeQueryOptions } from "@src/utils/query"

import { components } from "./schema"

export const methodologiesKeys = {
  all: ["methodologies"],
  content: (id?: number) => [...methodologiesKeys.all, "list", "content", id],
  data: (id?: number) => [...methodologiesKeys.all, "list", "data", id],
}

export type MethodologyDto = components["schemas"]["Methodology"]
export type MethodologySectionDto = components["schemas"]["MethodologySection"]
export type MethodologyParagraphDto =
  components["schemas"]["MethodologyParagraphDto"]
export type MethodologyContentCircleDto =
  components["schemas"]["MethodologyContentCircleDto"]

export const getMethodologyContent = async (id: number) => {
  const data = await client.GET("/api/MethodologyContent/GetMethodology", {
    params: {
      query: { id },
    },
  })

  return data
}

export const getMethodologyContentQuery = (id?: number) =>
  safeQueryOptions({
    queryFn: getMethodologyContent,
    queryKeys: methodologiesKeys.content(id),
    unsafeQueryFnArgs: [id],
  })

export const getMethodology = async (id: number) => {
  const data = await client.GET("/api/Methodology/GetMethodology", {
    params: {
      query: { id },
    },
  })

  return data
}

export const getMethodologyQuery = (id?: number) =>
  safeQueryOptions({
    queryFn: getMethodology,
    queryKeys: methodologiesKeys.data(id),
    unsafeQueryFnArgs: [id],
  })

export const deleteMethodology = async (communityId: number, id: number) => {
  const data = await client.DELETE("/api/Methodology/{communityId}/{id}", {
    params: {
      path: { id, communityId },
    },
  })

  return data
}

export const toggleMethodologyVisibility = async (
  id: number,
  enable: boolean,
) => {
  if (enable) {
    return await client.GET("/api/Methodology/Enable", {
      params: {
        query: { id },
      },
    })
  } else {
    return await client.GET("/api/Methodology/Disable", {
      params: {
        query: { id },
      },
    })
  }
}

export const createMethodology = async (
  name: string,
  isActive: boolean,
  communityId: number,
) => {
  const data = await client.GET("/api/Methodology/Create", {
    params: {
      query: { name, isActive },
    },
  })

  if (!data) {
    throw new Error("Failed to create methodology")
  }

  const methodology = data as { id: number }

  await client.POST("/api/Methodology/UpdateCommunity", {
    body: { methodologyId: methodology?.id, communityId },
  })

  return data as { id: number }
}

export const renameMethodology = async (id: number, name: string) => {
  const data = await client.GET("/api/Methodology/Rename", {
    params: {
      query: { id, name },
    },
  })

  return data
}

export const saveMethodologySection = async (
  methodologySection: MethodologySectionDto,
) => {
  const data = await client.POST("/api/MethodologyContent/SaveSection", {
    body: methodologySection,
  })

  return data
}

export const saveMethodologyParagraph = async (
  methodologyParagraph: MethodologyParagraphDto,
) => {
  const data = await client.POST("/api/MethodologyContent/SaveParagraph", {
    body: methodologyParagraph,
  })

  return data
}

export const updateMethodologySectionOrder = async (
  methodologyId: number,
  methodologySections: { id: number; order: number }[],
) => {
  const data = await client.POST(
    "/api/MethodologyContent/UpdateSectionsOrder/methodology/{methodologyId}",
    {
      params: {
        path: { methodologyId },
      },
      body: methodologySections,
    },
  )

  return data
}

export const updateMethodologyParagraphOrder = async (
  methodologyId: number,
  methodologyParagraphs: { id: number; order: number }[],
) => {
  const data = await client.POST(
    "/api/MethodologyContent/UpdateParagraphsOrder/Methodology/{methodologyId}",
    {
      params: {
        path: { methodologyId },
      },
      body: methodologyParagraphs,
    },
  )

  return data
}

export const removeSection = async (sectionId: number) => {
  const data = await client.GET("/api/MethodologyContent/RemoveSection", {
    params: {
      query: { sectionId },
    },
  })

  return data
}

export const removeParagraph = async (paragraphId: number) => {
  const data = await client.GET("/api/MethodologyContent/RemoveParagraph", {
    params: {
      query: { paragraphId },
    },
  })

  return data
}
