import { FormAnswerDto, FormQuestionDto } from "@src/api/form"
import { Trans } from "@lingui/macro"

export type ScaleAnswerProps = {
  answer: FormAnswerDto
  question: FormQuestionDto
}

export const ScaleAnswer = ({ answer, question }: ScaleAnswerProps) => {
  if (!question?.questionOptions?.length) {
    return <div>-</div>
  }

  const orderedOptions = question.questionOptions.sort((optionA, optionB) => {
    return (
      (optionA.questionOptionOrder ?? 0) - (optionB.questionOptionOrder ?? 0)
    )
  })

  return (
    <div className="flex items-center gap-4 text-paragraph-medium">
      <span className="font-bold">
        <Trans id="from">From</Trans>
      </span>
      {orderedOptions.map((option) => {
        if (option.questionOptionOrder === answer.optionAnswerOrder) {
          return (
            <span
              className="inline-block rounded-md bg-blue px-2 py-1 text-white"
              key={option.questionOptionOrder}
            >
              {option.questionOptionOrder}
            </span>
          )
        }

        return (
          <span key={option.questionOptionOrder}>
            {option.questionOptionOrder}
          </span>
        )
      })}
      <span className="font-bold">
        <Trans id="to">To</Trans>
      </span>
    </div>
  )
}
