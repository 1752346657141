import { Trans } from "@lingui/macro"
import { clsx } from "clsx"

import { MenuItem } from "@src/components/organisms/Menu/Common/MenuItem"
import { useMenus, MenuItemType } from "@src/hooks/useMenus"

type CommunityMenuProps = {
  isAdminPanel: boolean
}

export const CommunityMenu = ({ isAdminPanel }: CommunityMenuProps) => {
  const { communityMenu, adminMenu } = useMenus()

  return (
    <aside className="hidden lg:flex">
      <section className="border-r border-pearl-lighter p-2">
        <nav>
          {communityMenu.map(({ name, link, icon }: MenuItemType) => (
            <MenuItem
              key={`menu-item-${link}`}
              link={link}
              name={!isAdminPanel ? name : ""}
              icon={icon}
              className={clsx([
                "my-2 rounded-xl",
                isAdminPanel && "p-2",
                !isAdminPanel && "px-8 py-2",
              ])}
            />
          ))}
        </nav>
      </section>
      {isAdminPanel && (
        <section className="border-r border-pearl-lighter p-2 ">
          <header
            className={clsx([
              "flex items-center justify-between",
              "w-56 pb-2 pl-5 pt-4",
              "text-paragraph-medium uppercase text-pearl-light",
            ])}
          >
            <Trans id="community_menu_admin_panel">Admin Panel</Trans>
          </header>
          <nav>
            {adminMenu.map(({ name, link, icon, children }: MenuItemType) => (
              <MenuItem
                key={`menu-item-${link}`}
                name={name}
                link={link}
                icon={icon}
                className="my-2 gap-6 px-8 py-2"
                children={children}
              />
            ))}
          </nav>
        </section>
      )}
    </aside>
  )
}
