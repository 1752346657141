import { createFileRoute } from '@tanstack/react-router'

import { ApplicationFormPage } from '@src/components/pages/Forms/ApplicationForms/ApplicationFormPage'

export const Route = createFileRoute(
  '/_auth/_community/community/$communityId/_admin-panel/admin-panel/forms/application/create',
)({
  component: () => <ApplicationFormPageWrapper />,
  validateSearch: (search: Record<string, unknown>) => {
    return {
      view_mode: search.view_mode === true ? true : false,
    }
  },
})

function ApplicationFormPageWrapper() {
  const { view_mode } = Route.useSearch()

  return (
    <ApplicationFormPage
      newForm
      viewMode={view_mode}
    />
  )
}
