import { useEffect, useState } from "react"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useForm } from "@tanstack/react-form"
import { zodValidator } from "@tanstack/zod-form-adapter"
import { t, Trans } from "@lingui/macro"
import { z } from "zod"
import { useNavigate } from "@tanstack/react-router"

import { EditorLazy } from "@src/components/organisms/Editor/EditorLazy"
import { useCommunityId } from "@src/hooks/useCommunityId"

import {
  saveSurveyDefinition,
  SurveyDefinition,
  SurveyDefinitionUpdate,
  IterationType,
  SurveyType,
} from "@src/api/survey"
import { getSurveyFormsQuery } from "@src/api/surveyForm"
import { getCommunityOverviewQuery } from "@src/api/community"
import { getProjectTagsByCommunityQuery } from "@src/api/tags"
import { Privacy } from "@src/api/privacy"

import { ZodValidator } from "@src/components/organisms/GenericForm"
import { Input } from "@src/components/atoms/Input"
import { Button } from "@src/components/atoms/Button"
import { InfoContainer } from "@src/components/atoms/InfoContainer"
import { Select } from "@src/components/atoms/Select"
import { MultiSelect } from "@src/components/atoms/MultiSelect"
import { DatePicker } from "@src/components/atoms/DatePicker"
import { PrivacyUserSelector } from "@src/components/organisms/PrivacyUserSelector"

export type SurveyDefinitionPageProps = {
  survey?: SurveyDefinition
  duplicate?: boolean
}

export const SurveyDefinitionForm = ({
  survey,
  duplicate = false,
}: SurveyDefinitionPageProps) => {
  const navigate = useNavigate()
  const communityId = useCommunityId()
  const [showInfo, setShowInfo] = useState(true)

  const validators = {
    name: {
      onBlur: z
        .string()
        .min(
          3,
          t({
            message: "The form name must be at least 3 characters",
            id: "form_name_min_3",
          }),
        )
        .max(
          80,
          t({
            message: "The form name must be under 80 characters",
            id: "form_name_max_80",
          }),
        ),
    },
    requiredString: {
      onBlur: z
        .string({
          required_error: t({
            message: "This field is required",
            id: "required_field",
          }),
        })
        .refine((value) => value.length >= 0),
    },
    requiredNumber: {
      onBlur: z.number({
        required_error: t({
          message: "This field is required",
          id: "required_field",
        }),
      }),
    },
    requiredDate: {
      onBlur: z.date({
        required_error: t({
          message: "This field is required",
          id: "required_field",
        }),
      }),
    },
  }

  const { data: forms } = useQuery(getSurveyFormsQuery(communityId))
  const formOptions =
    forms?.list?.map(({ id, name }) => ({
      id: id as number,
      label: name as string,
    })) || []

  const { data: communityOverview } = useQuery(
    getCommunityOverviewQuery(communityId),
  )

  const methodologyOptions =
    communityOverview?.methodologies?.map(({ id, name }) => ({
      id: id || 0,
      label:
        name ||
        t({
          message: "Unnamed Methodology",
          id: "unnamed_methodology",
        }),
    })) || []

  const { data: projectTags = [] } = useQuery(
    getProjectTagsByCommunityQuery(communityId),
  )

  const tagsOptions =
    projectTags?.map((tag) => ({
      id: tag,
      label:
        tag ||
        t({
          message: "Unnamed Tag",
          id: "unnamed_tag",
        }),
    })) || []

  const iterationOptions = [
    {
      id: IterationType.Weekly,
      label: t({
        message: "Weekly",
        id: "weekly",
      }),
    },
    {
      id: IterationType.Monthly,
      label: t({
        message: "Monthly",
        id: "monthly",
      }),
    },
    {
      id: IterationType.Quarterly,
      label: t({
        message: "Quarterly",
        id: "quarterly",
      }),
    },
    {
      id: IterationType.Semestral,
      label: t({
        message: "Semestral",
        id: "semestral",
      }),
    },
    {
      id: IterationType.Yearly,
      label: t({
        message: "Yearly",
        id: "yearly",
      }),
    },
    {
      id: IterationType.Custom,
      label: t({
        message: "Custom",
        id: "custom",
      }),
    },
  ]

  const surveyOptions = [
    {
      id: SurveyType.Startup,
      label: t({
        message: "Startup survey",
        id: "startup_survey",
      }),
    },
    {
      id: SurveyType.Individual,
      label: t({
        message: "User survey",
        id: "user_survey",
      }),
    },
  ]

  const { mutate: saveSurveyDefinitionMutation } = useMutation({
    mutationFn: (payload: SurveyDefinitionUpdate) => {
      if (duplicate) {
        return saveSurveyDefinition({
          ...payload,
        })
      } else {
        return saveSurveyDefinition(payload, survey?.id)
      }
    },
  })

  const formInstance = useForm<SurveyDefinitionUpdate, ZodValidator>({
    defaultValues: survey,
    validatorAdapter: zodValidator(),
    onSubmit: async ({ value }) => {
      return saveSurveyDefinitionMutation(value, {
        onSuccess: (response) => {
          if (!survey?.id || duplicate) {
            navigate({
              to: "/community/$communityId/admin-panel/surveys/$surveyId",
              params: {
                communityId: String(communityId),
                surveyId: String(response?.id),
              },
              search: { duplicate: false },
            })
          }
        },
      })
    },
  })

  useEffect(() => {
    formInstance.reset({ ...survey, communityId })
  }, [formInstance, survey, communityId])

  return (
    <form
      className="m-auto"
      onSubmit={(e) => {
        e.preventDefault()
        e.stopPropagation()
        formInstance.handleSubmit()
      }}
    >
      <div className="flex flex-col gap-6">
        <header className="flex items-center justify-end gap-2">
          <Button
            variant="secondary"
            small
            onClick={() => {
              navigate({
                to: "/community/$communityId/admin-panel/surveys",
                params: {
                  communityId: String(communityId),
                },
              })
            }}
          >
            <Trans id="delete">Delete</Trans>
          </Button>
          <formInstance.Subscribe
            selector={(state) => [state.canSubmit, state.isSubmitting]}
            children={([canSubmit, isSubmitting]) => (
              <Button small type="submit" disabled={!canSubmit || isSubmitting}>
                <Trans id="save_changes">Save Changes</Trans>
              </Button>
            )}
          />
        </header>
        <section className="w-full rounded-xl border border-pearl-lighter bg-white p-6">
          <formInstance.Field
            name="title"
            validators={validators.name}
            children={(field) => (
              <Input
                name="name"
                placeholder={t({
                  message: "Name",
                  id: "name",
                })}
                error={field.state.meta.errors.join(", ")}
                variant="big"
                onChange={(value) => field.handleChange(value as string)}
                value={field.state.value}
                onBlur={field.handleBlur}
              />
            )}
          />
          <div className="my-4 h-[1px] w-full bg-pearl-lighter"></div>
          <formInstance.Field
            name="description"
            children={(field) => (
              <EditorLazy
                className="rounded-xl bg-ghost-white"
                placeholder={t({
                  message:
                    "Write a compelling description to explain to users how they should fill the form",
                  id: "write_compelling_description",
                })}
                value={field.state.value}
                onChange={(value) => {
                  field.handleChange(value)
                }}
              />
            )}
          />
        </section>

        {showInfo && (
          <InfoContainer
            type="default"
            text={t({
              message:
                "In case the survey targets startups, is it enough that only 1 team member fills the survey. Any team member can continue and contribute to filling the survey.",
              id: "survey_info_details",
            })}
            onClose={() => setShowInfo(false)}
          />
        )}

        <formInstance.Field
          name="surveyType"
          validators={validators.requiredNumber}
        >
          {(field) => (
            <Select
              error={field.state.meta.errors.join(", ")}
              insideLabel={t({
                message: "Survey type",
                id: "survey_type",
              })}
              placeholder={t({
                message: "Please select a type",
                id: "select_type",
              })}
              required
              className="lg:w-1/2"
              options={surveyOptions}
              onSelect={({ id }: { id: SurveyType }) => {
                field.handleChange(id)
              }}
              value={
                surveyOptions.find(({ id }) => id === field.state.value) || null
              }
              onBlur={field.handleBlur}
            />
          )}
        </formInstance.Field>

        <formInstance.Subscribe
          selector={(state) => ({
            surveyPrivacyCircles: state.values.surveyPrivacyCircles,
            surveyCommunityUserTags: state.values.surveyCommunityUserTags,
            surveyType: state.values.surveyType,
          })}
          children={({
            surveyPrivacyCircles,
            surveyCommunityUserTags,
            surveyType,
          }) =>
            surveyType === SurveyType.Individual ? (
              <PrivacyUserSelector
                onChange={({ circleIds, tags }) => {
                  formInstance.setFieldValue("surveyPrivacyCircles", circleIds)
                  formInstance.setFieldValue("surveyCommunityUserTags", tags)
                }}
                circleIds={surveyPrivacyCircles || []}
                tags={surveyCommunityUserTags || []}
                defaultPrivacyType={
                  surveyPrivacyCircles?.length ||
                  surveyCommunityUserTags?.length
                    ? Privacy.Custom
                    : Privacy.Public
                }
              />
            ) : (
              <div className="grid grid-cols-1 gap-6 rounded-xl border border-pearl-lighter bg-white px-4 py-6 md:grid-cols-2">
                <formInstance.Field name="surveyMethodologies">
                  {(field) => (
                    <MultiSelect
                      error={field.state.meta.errors.join(", ")}
                      label={t({
                        message: "{201}",
                        id: "programs",
                      })}
                      placeholder={t({
                        message: "Select {201}",
                        id: "select_programs",
                      })}
                      className="w-full"
                      options={methodologyOptions}
                      onSelect={(values) => {
                        field.handleChange(values.map((v) => v.id as number))
                      }}
                      value={methodologyOptions.filter(({ id }) =>
                        field.state.value?.includes(id),
                      )}
                      onBlur={field.handleBlur}
                    />
                  )}
                </formInstance.Field>
                <formInstance.Field name="surveyProjectTags">
                  {(field) => (
                    <MultiSelect
                      error={field.state.meta.errors.join(", ")}
                      label={t({
                        message: "Tags",
                        id: "tags",
                      })}
                      placeholder={t({
                        message: "Select tags",
                        id: "select_tags",
                      })}
                      className="w-full"
                      options={tagsOptions}
                      onSelect={(values) => {
                        field.handleChange(values.map((v) => v.id as string))
                      }}
                      value={tagsOptions.filter(({ id }) =>
                        field.state.value?.includes(id),
                      )}
                      onBlur={field.handleBlur}
                    />
                  )}
                </formInstance.Field>
              </div>
            )
          }
        />

        <section className="flex w-full flex-col gap-6 rounded-xl border border-pearl-lighter bg-white p-6">
          <div className="flex items-end gap-3 lg:w-2/3">
            <formInstance.Field
              name="formId"
              validators={validators.requiredNumber}
            >
              {(field) => (
                <Select
                  error={field.state.meta.errors.join(", ")}
                  label={t({
                    message: "Choose a form",
                    id: "choose_form",
                  })}
                  placeholder={t({
                    message: "Please select a form",
                    id: "select_form",
                  })}
                  required
                  className="w-full"
                  options={formOptions}
                  onSelect={({ id }) => {
                    field.handleChange(id as number)
                  }}
                  value={
                    formOptions.find(({ id }) => id === field.state.value) ||
                    null
                  }
                  onBlur={field.handleBlur}
                />
              )}
            </formInstance.Field>
            <span className="pb-2 text-paragraph-medium text-pearl-dark">
              Or
            </span>
            <Button
              variant="ternary"
              small
              icon="description"
              className="w-full"
              onClick={() => {
                navigate({
                  to: "/community/$communityId/admin-panel/surveys/create",
                  params: {
                    communityId: String(communityId),
                  },
                })
              }}
            >
              <Trans id="create_new_form">Create new form</Trans>
            </Button>
          </div>
          <div className="flex gap-3">
            <formInstance.Field
              name="iterationType"
              validators={validators.requiredNumber}
            >
              {(field) => (
                <Select
                  error={field.state.meta.errors.join(", ")}
                  label={t({
                    message: "Iteration type",
                    id: "iteration_type",
                  })}
                  placeholder={t({
                    message: "Please select an iteration type",
                    id: "select_iteration_type",
                  })}
                  required
                  className="lg:w-2/3"
                  options={iterationOptions}
                  onSelect={({ id }) => {
                    field.handleChange(id)
                  }}
                  value={
                    iterationOptions.find(
                      ({ id }) => id === field.state.value,
                    ) || null
                  }
                  onBlur={field.handleBlur}
                />
              )}
            </formInstance.Field>
            <formInstance.Subscribe
              selector={(state) => ({
                iterationType: state.values.iterationType,
              })}
              children={({ iterationType }) =>
                iterationType === IterationType.Custom && (
                  <formInstance.Field
                    name="iterationDuration"
                    validators={validators.requiredString}
                  >
                    {(field) => (
                      <Input
                        name="iterationDuration"
                        label={t({
                          message: "Iteration (in days)",
                          id: "iteration_days",
                        })}
                        placeholder={t({
                          message: "Please enter the iteration duration",
                          id: "enter_iteration_days",
                        })}
                        error={field.state.meta.errors.join(", ")}
                        className="lg:w-1/3"
                        type="number"
                        onChange={(value) =>
                          field.handleChange(value as number)
                        }
                        value={field.state.value}
                        onBlur={field.handleBlur}
                      />
                    )}
                  </formInstance.Field>
                )
              }
            />
          </div>

          <div className="flex items-baseline gap-3 lg:w-2/3">
            <formInstance.Field
              name="startDate"
              validators={validators.requiredString}
            >
              {(field) => (
                <DatePicker
                  name="startDate"
                  error={field.state.meta.errors.join(", ")}
                  label={t({
                    message: "Start date & time",
                    id: "start_date_time",
                  })}
                  placeholder={t({
                    message: "Please select a start date & time",
                    id: "select_start_date_time",
                  })}
                  className="w-full"
                  required
                  hasTimePicker
                  onChange={(value) => {
                    field.handleChange(value?.toISOString())
                  }}
                  value={
                    field.state.value ? new Date(field.state.value) : undefined
                  }
                  onBlur={field.handleBlur}
                />
              )}
            </formInstance.Field>
            <formInstance.Field
              name="endDate"
              validators={validators.requiredString}
            >
              {(field) => (
                <DatePicker
                  name="endDate"
                  error={field.state.meta.errors.join(", ")}
                  label={t({
                    message: "End date & time",
                    id: "end_date_time",
                  })}
                  placeholder={t({
                    message: "Please select a end date & time",
                    id: "select_end_date_time",
                  })}
                  className="w-full"
                  required
                  hasTimePicker
                  onChange={(value) => {
                    field.handleChange(value?.toISOString())
                  }}
                  value={
                    field.state.value ? new Date(field.state.value) : undefined
                  }
                  onBlur={field.handleBlur}
                />
              )}
            </formInstance.Field>
          </div>
        </section>
      </div>
    </form>
  )
}
