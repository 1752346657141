import { t } from "@lingui/macro"
import { AxiosError } from "axios"

export const getErrorMessage = (
  apiError?: AxiosError | string | Error,
  fallbackMessage?: string,
): string => {
  const errorMessagesMap: Record<string, string> = {
    "Babele.Domain.Aggregates.ApplicationAgg.Exceptions.CanNotAcceptOrRejectAnApplicationThatIsInNotInPendingStatus":
      t({
        message:
          "Can't accept or reject an application that is not in submitted status",
        id: "CanNotAcceptOrRejectAnApplicationThatIsInNotInPendingStatus",
      }),
    "Babele.Services.Exceptions.UserListOrApplicationIdAndApplicationFilterMustSetException":
      t({
        message: "User list or application and application filters must be set",
        id: "UserListOrApplicationIdAndApplicationFilterMustSetException",
      }),
  }

  let key: string

  if ((apiError as AxiosError).name === "AxiosError") {
    key = String((apiError as AxiosError).response?.data)
  } else if (typeof apiError === "string") {
    key = apiError
  } else {
    key = apiError?.message || ""
  }

  return (
    errorMessagesMap[key] ||
    fallbackMessage ||
    t({
      message: "An error occurred. Please try again.",
      id: "AnErrorOccurred",
    })
  )
}
