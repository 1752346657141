import { Messages } from "@lingui/core"

const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export const formatLanguages = (
  initial: Messages,
  replacements: { [key: string]: string },
): Messages => {
  return Object.keys(initial).reduce((formatted, key) => {
    let value = initial[key]

    if (Array.isArray(value)) {
      let hasReplacement = false
      value = value.map((message, index) => {
        if (Array.isArray(message)) {
          const replacementMessage = replacements[message[0]]
          if (replacementMessage) {
            hasReplacement = true
            return index === 0
              ? capitalize(replacementMessage)
              : replacementMessage
          }
          return message
        }

        return message
      })
      if (hasReplacement) {
        value = value.join("")
      }
    }

    formatted[key] = value

    return formatted
  }, {} as Messages)
}
