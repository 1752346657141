import { UseMutationResult } from "@tanstack/react-query"
import { Trans, t } from "@lingui/macro"
import { ApplicationResponse, ApplicationStatus } from "@src/api/application"
import { Button } from "@src/components/atoms/Button"
import { InfoContainer } from "@src/components/atoms/InfoContainer"

export type RejectApplicationDialogProps = {
  selectedApplications: Array<ApplicationResponse & { id: number }>
  applicationDefinitionId: number
  onClose: () => void
  mutation: UseMutationResult<void, Error, void, unknown>
}

export const RejectApplicationsDialog = ({
  selectedApplications,
  mutation,
  onClose,
}: RejectApplicationDialogProps) => {
  const applicationsNotSubmitted = selectedApplications.filter(
    (application) => application?.status !== ApplicationStatus.Pending,
  )

  return (
    <div>
      {applicationsNotSubmitted.length > 0 && (
        <InfoContainer
          type="warning"
          text={t({
            message:
              "Only submitted applications can be rejected. Please review the selected applications and try again.",
            id: "application_prevent_reject_non_pending",
          })}
          className="mb-4"
        />
      )}
      <p className="mb-4 text-paragraph-medium text-pearl-darker">
        <Trans id="reject_application_description">
          If you confirm, these applications will not join your program. Beware,
          this action is irreversible!
        </Trans>
      </p>
      <div className="flex items-center justify-end gap-2">
        <Button variant="secondary" small onClick={() => onClose()}>
          <Trans id="cancel">Cancel</Trans>
        </Button>
        <Button
          type="submit"
          small
          onClick={() => mutation.mutate()}
          disabled={applicationsNotSubmitted.length > 0 || mutation.isPending}
        >
          <Trans id="reject_applications">Reject applications</Trans>
        </Button>
      </div>
    </div>
  )
}
