import { useForm, Validator } from "@tanstack/react-form"
import { zodValidator } from "@tanstack/zod-form-adapter"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { z } from "zod"
import { t, Trans } from "@lingui/macro"
import {
  saveApplicationTags,
  ApplicationResponse,
  applicationDefinitionKeys,
} from "@src/api/application"
import {
  MultiComboboxEdit,
  Option,
} from "@src/components/atoms/MultiCombobox/MultiComboboxEdit"
import { useState } from "react"
import { Button } from "@src/components/atoms/Button"

export type TagsDialogProps = {
  applicationResponse: NonNullable<ApplicationResponse & { id: number }>
  applicationTags?: string[] | null
  applicationDefinitionId: number
  onClose: () => void
}

type TagsFormValues = {
  tags: string[]
}

type ZodValidator = Validator<
  TagsFormValues,
  z.ZodType<unknown, z.ZodTypeDef, unknown>
>

export const TagsDialog = ({
  applicationResponse,
  applicationTags,
  applicationDefinitionId,
  onClose,
}: TagsDialogProps) => {
  const queryClient = useQueryClient()

  const saveApplicationTagsMutation = useMutation({
    mutationFn: (tags: string[]) => {
      return saveApplicationTags(applicationResponse.id, tags)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: applicationDefinitionKeys.applicationDefinition(
          applicationDefinitionId,
        ),
      })
    },
  })

  const [tagsOptions, setTagsOptions] = useState<Option<string>[]>(
    applicationTags
      ?.map((tag) => ({ id: tag, label: tag }))
      .sort((tagA, tagB) => tagA.label.localeCompare(tagB.label)) || [],
  )

  const validators = {
    tags: {
      onBlur: z.array(z.string()),
    },
  }

  const form = useForm<TagsFormValues, ZodValidator>({
    defaultValues: {
      tags: applicationResponse?.tags || [],
    },
    onSubmit: (values) => {
      saveApplicationTagsMutation.mutate(values.value.tags)
      onClose()
    },
    validatorAdapter: zodValidator(),
  })

  const handleCancel = () => {
    form.reset()
    onClose()
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        e.stopPropagation()
        form.handleSubmit()
      }}
    >
      <form.Field
        name="tags"
        validators={validators.tags}
        children={(field) => (
          <MultiComboboxEdit
            options={tagsOptions}
            label={t({
              message: "Edit tags",
              id: "edit_tags",
            })}
            value={field.state.value.map((tag) => ({ id: tag, label: tag }))}
            onSelect={(values: Option<string>[]) => {
              field.handleChange(values.map((tag) => tag.id))
            }}
            className="mb-4"
            onAddOption={(optionValue: string) => {
              const option = { id: optionValue, label: optionValue }

              field.handleChange([...field.state.value, option.id])
              setTagsOptions(
                [...tagsOptions, option].sort((tagA, tagB) =>
                  tagA.label.localeCompare(tagB.label),
                ),
              )
            }}
            onRemoveOption={(option: Option<string>) =>
              field.handleChange(
                field.state.value.filter((tag) => tag !== option.id),
              )
            }
          />
        )}
      />
      <div className="flex items-center justify-end gap-2">
        <Button variant="secondary" small onClick={handleCancel}>
          <Trans id="cancel">Cancel</Trans>
        </Button>
        <Button type="submit" small>
          <Trans id="save">Save</Trans>
        </Button>
      </div>
    </form>
  )
}
