import { useQuery } from "@tanstack/react-query"

import { getApplicationFormQuery } from "@src/api/applicationForm"

import { Skeleton } from "@src/components/atoms/Skeleton"
import { ApplicationForm } from "./ApplicationForm"

export type ApplicationFormPageProps = {
  formId?: number
  viewMode?: boolean
  duplicate?: boolean
  newForm?: boolean
}

export const ApplicationFormPage = ({
  formId,
  viewMode,
  duplicate,
  newForm,
}: ApplicationFormPageProps) => {
  const { data: form, isLoading } = useQuery(
    getApplicationFormQuery(newForm ? undefined : formId),
  )

  return !isLoading ? (
    <ApplicationForm form={form} viewMode={viewMode} duplicate={duplicate} />
  ) : (
    <Skeleton className="h-[100vh]" />
  )
}
