import { useEffect } from "react"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useForm } from "@tanstack/react-form"
import { zodValidator } from "@tanstack/zod-form-adapter"
import { t, Trans } from "@lingui/macro"
import { useNavigate } from "@tanstack/react-router"

import { useCommunityId } from "@src/hooks/useCommunityId"
import {
  saveCommunityApplicationDefinition,
  CommunityApplicationDetail,
} from "@src/api/application"
import { getCommunityCirclesQuery } from "@src/api/circle"
import { getCommunityOverviewQuery } from "@src/api/community"

import { ZodValidator } from "@src/components/organisms/GenericForm"
import { Input } from "@src/components/atoms/Input"
import { Button } from "@src/components/atoms/Button"
import { Select } from "@src/components/atoms/Select"
import { EditorLazy } from "@src/components/organisms/Editor/EditorLazy"
import { useValidators } from "./validators"

import { BaseInfoSection } from "./ApplicationDefinitionFormSections/BaseInfoSection"
import { EvaluationSection } from "./ApplicationDefinitionFormSections/EvaluationSection"
import { TrackingSection } from "./ApplicationDefinitionFormSections/TrackingSection"
import { EmailsSection } from "./ApplicationDefinitionFormSections/EmailsSection"

export type ApplicationDefinitionPageProps = {
  application?: CommunityApplicationDetail
  duplicate?: boolean
}

export const ApplicationDefinitionForm = ({
  application,
  duplicate = false,
}: ApplicationDefinitionPageProps) => {
  const navigate = useNavigate()
  const communityId = useCommunityId()

  const validators = useValidators()

  const { data: communityCircles = [] } = useQuery(
    getCommunityCirclesQuery(communityId),
  )
  const circleOptions = communityCircles.map(({ id, name }) => ({
    id: id || 0,
    label:
      name ||
      t({
        message: "Unnamed Circle",
        id: "unnamed_circle",
      }),
  }))

  const { data: communityOverview } = useQuery(
    getCommunityOverviewQuery(communityId),
  )
  const methodologyOptions =
    communityOverview?.methodologies?.map(({ id, name }) => ({
      id: id || 0,
      label:
        name ||
        t({
          message: "Unnamed Methodology",
          id: "unnamed_methodology",
        }),
    })) || []

  const { mutate: saveCommunityApplicationDefinitionMutation } = useMutation({
    mutationFn: (payload: CommunityApplicationDetail) => {
      if (duplicate) {
        return saveCommunityApplicationDefinition({
          ...payload,
          id: 0,
        })
      } else {
        return saveCommunityApplicationDefinition(payload, application?.id)
      }
    },
  })

  const formInstance = useForm<CommunityApplicationDetail, ZodValidator>({
    defaultValues: application,
    validatorAdapter: zodValidator(),
    onSubmit: async ({ value }) => {
      return saveCommunityApplicationDefinitionMutation(value, {
        onSuccess: (response) => {
          if (!application?.id || duplicate) {
            navigate({
              to: "/community/$communityId/admin-panel/application-definitions/$applicationId",
              params: {
                communityId: String(communityId),
                applicationId: String(response.id),
              },
              search: { duplicate: false },
            })
          }
        },
      })
    },
  })

  useEffect(() => {
    formInstance.reset(application)
  }, [formInstance, application])

  return (
    <form
      className="m-auto"
      onSubmit={(e) => {
        e.preventDefault()
        e.stopPropagation()
        formInstance.handleSubmit()
      }}
    >
      <div className="flex flex-col gap-6">
        <header className="flex items-center justify-end gap-2">
          <Button
            variant="secondary"
            small
            onClick={() => {
              navigate({
                to: "/community/$communityId/admin-panel/application-definitions",
                params: {
                  communityId: String(communityId),
                },
              })
            }}
          >
            <Trans id="cancel">Cancel</Trans>
          </Button>
          <formInstance.Subscribe
            selector={(state) => [state.canSubmit, state.isSubmitting]}
            children={([canSubmit, isSubmitting]) => (
              <Button small type="submit" disabled={!canSubmit || isSubmitting}>
                <Trans id="save_changes">Save Changes</Trans>
              </Button>
            )}
          />
        </header>

        <section className="w-full rounded-xl border border-pearl-lighter bg-white p-6">
          <formInstance.Field
            name="name"
            validators={validators.name}
            children={(field) => (
              <Input
                name="name"
                placeholder={t({
                  message: "Name",
                  id: "name",
                })}
                error={field.state.meta.errors.join(", ")}
                variant="big"
                onChange={(value) => field.handleChange(value as string)}
                value={field.state.value}
                onBlur={field.handleBlur}
              />
            )}
          />
          <div className="my-4 h-[1px] w-full bg-pearl-lighter"></div>
          <formInstance.Field
            name="description"
            children={(field) => (
              <EditorLazy
                className="mb-6 rounded-xl bg-ghost-white"
                placeholder={t({
                  message:
                    "Write a compelling description to explain to users how they should fill the form",
                  id: "write_compelling_description",
                })}
                value={field.state.value}
                onChange={(value) => {
                  field.handleChange(value)
                }}
              />
            )}
          />

          <div className="flex flex-col items-baseline gap-3 lg:flex-row">
            <formInstance.Field
              name="acceptedCircleId"
              validators={validators.requiredNumber}
            >
              {(field) => (
                <Select
                  error={field.state.meta.errors.join(", ")}
                  label={t({
                    message: "Accepted users role",
                    id: "accepted_users_role",
                  })}
                  placeholder={t({
                    message: "Please select a role",
                    id: "select_a_role",
                  })}
                  required
                  className="w-full"
                  options={circleOptions}
                  onSelect={({ id }) => {
                    field.handleChange(id as number)
                  }}
                  value={
                    circleOptions.find(({ id }) => id === field.state.value) ||
                    null
                  }
                  onBlur={field.handleBlur}
                />
              )}
            </formInstance.Field>

            <formInstance.Field
              name="acceptedMethodologyId"
              validators={validators.requiredNumber}
            >
              {(field) => (
                <Select
                  error={field.state.meta.errors.join(", ")}
                  label={t({
                    message: "Accepted {101} program",
                    id: "accepted_program",
                  })}
                  placeholder={t({
                    message: "Please select a program",
                    id: "select_a_program",
                  })}
                  required
                  className="w-full"
                  options={methodologyOptions}
                  onSelect={({ id }) => {
                    field.handleChange(id as number)
                  }}
                  value={
                    methodologyOptions.find(
                      ({ id }) => id === field.state.value,
                    ) || null
                  }
                  onBlur={field.handleBlur}
                />
              )}
            </formInstance.Field>
          </div>
        </section>

        <BaseInfoSection form={formInstance} />
        <EvaluationSection form={formInstance} />
        <EmailsSection form={formInstance} />
        <TrackingSection form={formInstance} />
      </div>
    </form>
  )
}
