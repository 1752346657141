import { useState } from "react"

import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/react"
import clsx from "clsx"

import { Field, Label, ErrorMessage } from "@src/components/atoms/Fieldset"
import { Icon } from "@src/components/atoms/Icon"
import { Checkbox } from "@src/components/atoms/Checkbox"
import { Trans } from "@lingui/macro"
import { Button } from "@src/components/atoms/Button"
import { IconButton } from "../IconButton"

const styles = {
  base: [
    "relative flex items-center justify-between w-full rounded-xl border bg-white", // Base styles
    "text-paragraph-medium", // Typography
    "focus-within:border-pearl-light", // Focus if input or button
    "focus:outline-none appearance-none", // Hide default focus styles
    "placeholder:text-gray",
  ],
  primary: [
    "border-pearl-lighter focus:border-pearl-light bg-transparent text-black",
  ],
  secondary: [
    "bg-pearl-lighter/10 focus:border-pearl-lighter border-pearl-lighter/10 text-black-light",
  ],
  size: (small: boolean) =>
    small ? ["py-1 pl-3 pr-1 gap-2"] : ["py-2 pl-5 pr-2 gap-4"],
}

export type Option<T extends number | string> = {
  id: T
  label: string
  disabled?: boolean
}

export type MultiComboboxEditProps<T extends number | string> = {
  variant?: "primary" | "secondary"
  options: Option<T>[]
  label?: string
  placeholder?: string
  value?: Option<T>[]
  error?: string
  className?: string
  small?: boolean
  onSelect: (selectedOptions: Option<T>[]) => void
  disabled?: boolean
  onAddOption: (optionValue: string) => void
  onRemoveOption: (option: Option<T>) => void
}

export const MultiComboboxEdit = <T extends number | string>({
  variant = "primary",
  label,
  placeholder,
  error,
  value = [],
  small = false,
  options,
  className,
  onSelect,
  disabled,
  onAddOption,
  onRemoveOption,
}: MultiComboboxEditProps<T>) => {
  const [query, setQuery] = useState("")
  const filteredOptions =
    query === ""
      ? options
      : options.filter((option) =>
          option.label.toLowerCase().includes(query.toLowerCase()),
        )

  const exactMatch = options.find((option) => option.label === query)

  const isSelected = (option: Option<T>) =>
    value.some((selected) => selected.id === option.id)

  const toggleSelection = (option: Option<T>) => {
    const newSelection = isSelected(option)
      ? value.filter((selected) => selected.id !== option.id)
      : [...value, option]

    onSelect(newSelection)
  }

  return (
    <Field className={clsx("flex-col", className)}>
      <Label htmlFor={label}>{label}</Label>

      <Combobox
        value={value}
        multiple
        disabled={disabled}
        onClose={() => setQuery("")}
      >
        <div className="relative">
          <ComboboxInput
            className={clsx(styles.base, styles[variant], styles.size(small))}
            placeholder={placeholder}
            onChange={(event) => setQuery(event.target.value)}
          />
          <ComboboxButton className="group absolute inset-y-0 right-0 flex items-center px-2.5">
            <Icon
              className="group pointer-events-none text-black"
              icon="keyboard_arrow_down"
              size="large"
            />
          </ComboboxButton>
        </div>
        <ComboboxOptions
          anchor="bottom"
          transition
          className={clsx(
            "z-20 grid !max-h-60 flex-1 gap-0.5 overflow-y-auto py-2",
            "my-2 w-[var(--input-width)]",
            "border border-pearl-lighter bg-white",
            "font-semibold text-black",
          )}
        >
          {query.length > 0 && !exactMatch && (
            <ComboboxOption
              value={{ id: null, name: query }}
              className="data-[focus]:bg-blue-100"
            >
              <Button variant="text" small onClick={() => onAddOption(query)}>
                <Trans id="create">Create</Trans>{" "}
                <span className="font-bold">"{query}"</span>
              </Button>
            </ComboboxOption>
          )}
          {filteredOptions.map((option) => (
            <ComboboxOption
              key={option.id}
              value={option}
              onClick={() => toggleSelection(option)}
              disabled={option.disabled}
              className={clsx(
                "group flex items-center gap-2 overflow-auto",
                "mx-2 cursor-pointer select-none rounded-md p-2 hover:bg-blue-light/50",
                "break-all text-paragraph-medium font-light text-black",
              )}
            >
              <Checkbox
                checked={isSelected(option)}
                onChange={() => toggleSelection(option)}
              />
              {option.label}
            </ComboboxOption>
          ))}
        </ComboboxOptions>
        <div className="mt-1 flex flex-wrap items-center gap-2">
          {value.map((option) => (
            <span
              key={option.id}
              className="flex items-center gap-1 rounded-xl border border-pearl-lighter px-2 py-1 text-gray"
            >
              <span className="text-paragraph-medium">{option.label}</span>

              <IconButton
                icon="close"
                size="small"
                onClick={() => onRemoveOption(option)}
                variant="text"
              />
            </span>
          ))}
        </div>
      </Combobox>
      <ErrorMessage>{error}</ErrorMessage>
    </Field>
  )
}
