import { FormApi, ReactFormApi, Validator } from "@tanstack/react-form"
import { z } from "zod"

import { Icon } from "@src/components/atoms/Icon"
import { EditorFormDto } from "@src/api/form"
import { GenericFormViewer } from "@src/components/organisms/GenericFormViewer"
import { GenericFormQuestion } from "./Question"
import { DEFAULT_FORM_QUESTION } from "./Question/defaultData"
import { EmptyState } from "./EmptyState"

import {
  SortableList,
  SortableElement,
} from "@src/components/atoms/SortableList"

export type ZodValidator = Validator<
  unknown,
  z.ZodType<unknown, z.ZodTypeDef, unknown>
>

export type GenericFormProps = {
  form: FormApi<EditorFormDto, ZodValidator> &
  ReactFormApi<EditorFormDto, ZodValidator>
  viewMode?: boolean
  privacySelector?: boolean
}

export const GenericForm = ({
  form,
  viewMode = false,
  privacySelector = false,
}: GenericFormProps) => {
  const currentFormDefinition = form.useStore((state) => state.values)

  if (viewMode) {
    return <GenericFormViewer formDefinition={currentFormDefinition} />
  }

  return (
    <div className="flex flex-col gap-6">
      <form.Field
        name="questions"
        mode="array"
        children={(questionsField) => {
          const questions = questionsField.state.value || []

          const items =
            questions?.map((q, i) => ({
              id: i,
              element: (
                <GenericFormQuestion
                  key={i}
                  index={q.questionOrder || 0}
                  form={form}
                  privacySelector={privacySelector}
                />
              ),
            })) ?? []

          const onDragEnd = (orderedItems: SortableElement[]) => {
            const newQuestionsOrder: EditorFormDto["questions"] = []

            for (let i = 0; i < orderedItems.length; i++) {
              const question = Object.assign({}, questions[orderedItems[i].id])

              if (question) {
                question.questionOrder = i
                newQuestionsOrder[i] = question
              }
            }

            form.setFieldValue("questions", newQuestionsOrder)
          }

          return questions.length ? (
            <SortableList
              items={items}
              onDragEnd={onDragEnd}
              className="flex flex-col gap-6"
            />
          ) : (
            <EmptyState />
          )
        }}
      />

      <button
        className="flex w-full items-center justify-center rounded-xl bg-alice-blue py-2"
        onClick={(e) => {
          e.preventDefault()
          const questions = form.getFieldValue("questions")
          const newQuestion = Object.assign({}, DEFAULT_FORM_QUESTION(), {
            questionOrder: questions?.length || 0,
          })

          if (Array.isArray(questions)) {
            form.setFieldValue("questions", [...questions, newQuestion])
          } else {
            form.setFieldValue("questions", [newQuestion])
          }
        }}
      >
        <Icon icon="add_row_below" size="large2x" className="text-blue" />
      </button>
    </div>
  )
}
