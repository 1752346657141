import clsx from "clsx"

type EmptyProps = {
  title: string
  description: string
  className?: string
}

export const Empty = ({ title, description, className }: EmptyProps) => {
  return (
    <div
      className={clsx(
        "flex w-full flex-col items-center gap-3 p-4 lg:p-16",
        className,
      )}
    >
      <h4 className="text-center text-black">
        {title}
      </h4>
      <p className="max-w-96 text-center text-xl text-black-light">
        {description}
      </p>
    </div>
  )
}
