import { ReactNode, useState, useEffect } from "react"
import { useQuery } from "@tanstack/react-query"
import { I18nProvider } from "@lingui/react"
import { i18n } from "@lingui/core"

import { getCommunityLiteralsQuery } from "@src/api/literals"
import { getCurrentUserQuery } from "@src/api/user"
import { useCommunityId } from "@src/hooks/useCommunityId"
import { formatLanguages } from "./utils"

export const Languages = ({ children }: { children: ReactNode }) => {
  const [languageActivated, setLanguageActivated] = useState(false)
  const communityId = useCommunityId()

  const { data: communityLiterals } = useQuery(
    getCommunityLiteralsQuery(communityId),
  )

  const { data: currentUser } = useQuery(getCurrentUserQuery())

  useEffect(() => {
    const loadAndSetMessages = async () => {
      const language = currentUser?.language?.slice(0, 2) ?? "en"
      const replacements = {
        "100": communityLiterals?.primitive_startup ?? "Startup",
        "101": communityLiterals?.primitive_startups ?? "Startups",
        "200": communityLiterals?.primitive_program ?? "Program",
        "201": communityLiterals?.primitive_programs ?? "Programs",
      }

      const { messages } = await import(`@src/locales/${language}/messages.ts`)

      const mergedMessages = {
        ...messages,
        ...communityLiterals,
      }

      const processedMessages = formatLanguages(mergedMessages, replacements)
      i18n.load(language, processedMessages)
      i18n.activate(language)
      setLanguageActivated(true)
    }

    if (!languageActivated && currentUser && communityLiterals) {
      loadAndSetMessages()
    }
  }, [currentUser, communityLiterals, languageActivated])

  if (!languageActivated) {
    return null
  }

  return <I18nProvider i18n={i18n}>{children}</I18nProvider>
}
