import { useQuery } from "@tanstack/react-query"
import { Trans } from "@lingui/macro"
import {
  getSurveyResponseListQuery,
  getSurveyDefinitionQuery,
} from "@src/api/survey"
import { getSurveyFormQuery } from '@src/api/surveyForm'
import { PageContainer } from "@src/components/atoms/PageContainer"
import { ReportingAnswers } from "@src/components/organisms/Answers/Reporting"
import { Skeleton } from "@src/components/atoms/Skeleton"

export type ReportingPageProps = {
  surveyId: number
}

export const ReportingPage = ({ surveyId }: ReportingPageProps) => {
  const { data: surveyDefinition, isLoading: definitionLoading } = useQuery(
    getSurveyDefinitionQuery(surveyId),
  )
  const { data: form = {} } = useQuery(getSurveyFormQuery(surveyDefinition?.formId))

  const { data: surveyResponses, isLoading: responsesListLoading } =
    useQuery(getSurveyResponseListQuery(surveyId))

  const allAnswersList = surveyResponses?.list?.flatMap(
    ({ answers, projectName, userName }) => {
      return answers?.map((answer) => {
        return { ...answer, projectName, userName }
      }) ?? []
    },
  )

  return (
    <>
      <h1 className="mb-6 text-heading5 font-bold text-black md:text-heading4">
        <Trans id="answers">Answers</Trans>
      </h1>
      {definitionLoading ||
        (responsesListLoading && <Skeleton className="h-[100vh]" />)}
      {form && allAnswersList && (
        <PageContainer>
          <ReportingAnswers
            form={form}
            answers={allAnswersList || []}
            surveyType={surveyDefinition?.surveyType ?? 0}
            reportType="respondersAggregate"
          />
        </PageContainer>
      )}
    </>
  )
}
