import { createFileRoute } from '@tanstack/react-router'

import { SurveyFormPage } from '@src/components/pages/Forms/SurveyForms/SurveyFormPage'

export const Route = createFileRoute(
  '/_auth/_community/community/$communityId/_admin-panel/admin-panel/forms/survey/create',
)({
  component: () => <FormPageWrapper />,
  validateSearch: (search: Record<string, unknown>) => {
    return {
      view_mode: search.view_mode === true ? true : false,
    }
  },
})


function FormPageWrapper() {
  const { view_mode } = Route.useSearch()

  // TODO: handle failing to parse formId
  return (
    <SurveyFormPage
      newForm
      viewMode={view_mode}
    />
  )
}
