import { useState } from "react"
import { t } from "@lingui/macro"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Dropdown } from "@src/components/atoms/Dropdown"
import { useDialogQueue } from "@src/context/DialogQueueProvider/useDialogQueue"
import {
  ApplicationResponse,
  ApplicationDefinition,
  downloadApplicationDefinitionEvaluationsCsv,
  downloadApplicationDefinitionResponsesCsv,
  applicationDefinitionKeys,
  deleteApplication,
  rejectApplication,
  acceptApplication,
} from "@src/api/application"
import { octetStreamToCsvDownload } from "@src/utils/file"
import { RejectApplicationsDialog } from "./RejectApplicationsDialog"
import { AcceptApplicationsDialog } from "./AcceptApplicationsDialog"
import { DeleteApplicationsDialog } from "./DeleteApplicationsDialog"
import { SendEmailsDialog } from "./SendEmailsDialog"
import { hasDefinedId } from "@src/utils/types"
import { getErrorMessage } from "@src/api/errors"
import { InfoContainer } from "@src/components/atoms/InfoContainer"
import { sendGroupMessage, SendGroupMessage } from "@src/api/message"
import { Button } from "@src/components/atoms/Button"
import { Icon } from "@src/components/atoms/Icon"
import { SearchInput } from "@src/components/atoms/SearchInput"

export type ActionProps = {
  applicationDefinition?: ApplicationDefinition
  selectedApplications: Array<ApplicationResponse & { id: number }>
  onShowFilters: () => void
  onSearchChange: (value: string) => void
  searchValue?: string | null
}

export const Actions = ({
  applicationDefinition,
  selectedApplications,
  onShowFilters,
  onSearchChange,
  searchValue,
}: ActionProps) => {
  const queryClient = useQueryClient()
  const { enqueueDialog, closeCurrentDialog } = useDialogQueue()
  const [showDeleteInfo, setShowDeleteInfo] = useState(false)
  const [showAcceptInfo, setShowAcceptInfo] = useState(false)
  const [showRejectInfo, setShowRejectInfo] = useState(false)
  const [showSendEmailInfo, setShowSendEmailInfo] = useState(false)
  const [message, setMessage] = useState<SendGroupMessage>({
    applicationDefinitionId: null,
    message: "",
    recaptchaResponse: "",
  })

  const downloadApplicationResponsesMutation = useMutation({
    mutationFn: async () => {
      const data = await downloadApplicationDefinitionResponsesCsv(
        applicationDefinition?.id || 0,
      )

      octetStreamToCsvDownload(data, "application-responses.csv")
    },
  })

  const downloadApplicationEvaluations = useMutation({
    mutationFn: async () => {
      const data = await downloadApplicationDefinitionEvaluationsCsv(
        applicationDefinition?.id || 0,
      )

      octetStreamToCsvDownload(data, "application-evaluations.csv")
    },
  })

  const deleteApplicationsMutation = useMutation({
    mutationFn: async () => {
      await Promise.all(
        selectedApplications.map((application) =>
          deleteApplication(application.id),
        ),
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: applicationDefinitionKeys.applicationDefinition(
          applicationDefinition?.id,
        ),
      })
    },
    onSettled: () => {
      closeCurrentDialog()
      setShowDeleteInfo(true)
    },
  })

  const acceptApplicationsMutation = useMutation({
    mutationFn: async () => {
      await Promise.all(
        selectedApplications.map((application) =>
          acceptApplication(application.id),
        ),
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: applicationDefinitionKeys.applicationDefinition(
          applicationDefinition?.id,
        ),
      })
    },
    onSettled: () => {
      closeCurrentDialog()
      setShowAcceptInfo(true)
    },
  })

  const rejectApplicationsMutation = useMutation({
    mutationFn: async () => {
      await Promise.all(
        selectedApplications.map((application) =>
          rejectApplication(application.id),
        ),
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: applicationDefinitionKeys.applicationDefinition(
          applicationDefinition?.id,
        ),
      })
    },
    onSettled: () => {
      closeCurrentDialog()
      setShowRejectInfo(true)
    },
  })

  const sendEmailMutation = useMutation({
    mutationFn: async () => {
      await sendGroupMessage({
        ...message,
        userList: selectedApplications
          .map((application) => application.applicantId)
          .filter((id) => id !== null && id !== undefined) as number[],
      })
    },
    onSettled: () => {
      closeCurrentDialog()
      setShowSendEmailInfo(true)
    },
  })

  if (
    applicationDefinition === undefined ||
    !hasDefinedId(applicationDefinition)
  ) {
    return null
  }

  return (
    <div>
      <div className="mb-4 flex items-center gap-2">
        <Dropdown
          variant="primary"
          dropdownClassname="w-80"
          items={[
            {
              text: t({
                message: "Accept selected applications",
                id: "application_accept_selected",
              }),
              onClick: () => {
                enqueueDialog({
                  title: t({
                    message: "Do you want to accept the selected applications?",
                    id: "accept_application_title",
                  }),
                  children: (
                    <AcceptApplicationsDialog
                      selectedApplications={selectedApplications}
                      applicationDefinition={applicationDefinition}
                      onClose={closeCurrentDialog}
                      mutation={acceptApplicationsMutation}
                    />
                  ),
                })
              },
              icon: "check",
              iconColor: "green",
              disabled: !selectedApplications.length,
            },
            {
              text: t({
                message: "Reject selected applications",
                id: "application_reject_selected",
              }),
              onClick: () => {
                enqueueDialog({
                  title: t({
                    message: "Do you want to reject the selected applications?",
                    id: "reject_application_title",
                  }),
                  children: (
                    <RejectApplicationsDialog
                      selectedApplications={selectedApplications}
                      onClose={closeCurrentDialog}
                      applicationDefinitionId={applicationDefinition?.id}
                      mutation={rejectApplicationsMutation}
                    />
                  ),
                })
              },
              icon: "close",
              iconColor: "red",
              disabled: !selectedApplications.length,
            },
            {
              text: t({
                message: "Delete selected applications",
                id: "application_delete_selected",
              }),
              onClick: () => {
                enqueueDialog({
                  title: t({
                    message: `Do you want to delete the ${selectedApplications.length} selected applications?`,
                    id: "delete_application_title",
                  }),
                  children: (
                    <DeleteApplicationsDialog
                      selectedApplications={selectedApplications}
                      onClose={closeCurrentDialog}
                      mutation={deleteApplicationsMutation}
                    />
                  ),
                })
              },
              icon: "delete",
              iconColor: "red",
              disabled: !selectedApplications.length,
            },
            {
              text: t({
                message: "Send message to selected applications",
                id: "application_send_message_selected",
              }),
              onClick: () => {
                enqueueDialog({
                  title: t({
                    message: "Send message to selected applications",
                    id: "application_send_message_selected",
                  }),
                  children: (
                    <SendEmailsDialog
                      selectedApplications={selectedApplications}
                      onClose={closeCurrentDialog}
                      mutation={sendEmailMutation}
                      onSubmit={(messageDetails: Partial<SendGroupMessage>) => {
                        setMessage({ ...message, ...messageDetails })
                        sendEmailMutation.mutate()
                      }}
                    />
                  ),
                })
              },
              icon: "message",
              disabled: !selectedApplications.length,
            },
            {
              text: t({
                message: "Export all responses as CSV",
                id: "application_export_responses",
              }),
              onClick: () => downloadApplicationResponsesMutation.mutate(),
              icon: "file_export",
            },
            {
              text: t({
                message: "Export all evaluations as CSV",
                id: "application_export_evaluations",
              }),
              onClick: () => downloadApplicationEvaluations.mutate(),
              icon: "file_export",
            },
          ]}
          children={"Actions"}
        />
        <Button variant="secondary" small onClick={onShowFilters}>
          <Icon icon="filter_list" size="large" />
        </Button>
        <SearchInput
          onChange={(value: string) => onSearchChange(value)}
          value={searchValue}
        />
      </div>

      {rejectApplicationsMutation.isError && showRejectInfo && (
        <InfoContainer
          type="error"
          text={getErrorMessage(
            rejectApplicationsMutation.error,
            t({
              message:
                "An error occurred while rejecting the applications. Please try again.",
              id: "application_reject_error",
            }),
          )}
          className="mb-4"
          onClose={() => setShowRejectInfo(false)}
        />
      )}
      {rejectApplicationsMutation.isSuccess && showRejectInfo && (
        <InfoContainer
          type="success"
          text={t({
            message: "Applications rejected successfully",
            id: "application_reject_success",
          })}
          icon="check"
          className="mb-4"
          onClose={() => setShowRejectInfo(false)}
        />
      )}
      {acceptApplicationsMutation.isError && showAcceptInfo && (
        <InfoContainer
          type="error"
          text={getErrorMessage(
            acceptApplicationsMutation.error,
            t({
              message:
                "An error occurred while accepting the applications. Please try again.",
              id: "application_accept_error",
            }),
          )}
          className="mb-4"
          onClose={() => setShowAcceptInfo(false)}
        />
      )}
      {acceptApplicationsMutation.isSuccess && showAcceptInfo && (
        <InfoContainer
          type="success"
          text={t({
            message: "Applications accepted successfully",
            id: "application_accepted_success",
          })}
          icon="check"
          className="mb-4"
          onClose={() => setShowAcceptInfo(false)}
        />
      )}
      {deleteApplicationsMutation.isError && showDeleteInfo && (
        <InfoContainer
          type="error"
          text={getErrorMessage(
            deleteApplicationsMutation.error,
            t({
              message:
                "An error occurred while deleting the applications. Please try again.",
              id: "application_delete_error",
            }),
          )}
          className="mb-4"
          onClose={() => setShowDeleteInfo(false)}
        />
      )}
      {deleteApplicationsMutation.isSuccess && showDeleteInfo && (
        <InfoContainer
          type="success"
          text={t({
            message: `${selectedApplications.length} applications deleted!`,
            id: "application_deleted_success",
          })}
          icon="check"
          className="mb-4"
          onClose={() => setShowDeleteInfo(false)}
        />
      )}
      {sendEmailMutation.isError && showSendEmailInfo && (
        <InfoContainer
          type="error"
          text={getErrorMessage(
            sendEmailMutation.error,
            t({
              message:
                "An error occurred while sending the message. Please try again.",
              id: "application_send_message_error",
            }),
          )}
          className="mb-4"
          onClose={() => setShowSendEmailInfo(false)}
        />
      )}
      {sendEmailMutation.isSuccess && showSendEmailInfo && (
        <InfoContainer
          type="success"
          text={t({
            message: "Message sent successfully!",
            id: "application_send_message_success",
          })}
          icon="check"
          className="mb-4"
          onClose={() => setShowSendEmailInfo(false)}
        />
      )}
    </div>
  )
}
