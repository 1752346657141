import { useForm, Validator } from "@tanstack/react-form"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { t, Trans } from "@lingui/macro"

import { z } from "zod"
import { zodValidator } from "@tanstack/zod-form-adapter"

import { Input } from "@src/components/atoms/Input"
import { Button } from "@src/components/atoms/Button"
import { Field, Label } from "@src/components/atoms/Fieldset"
import { ImageUpload } from "@src/components/molecules/ImageUpload"
import { PageContainer } from "@src/components/atoms/PageContainer"
import { EditorLazy } from "@src/components/organisms/Editor/EditorLazy"
import { communityKeys, CommunityOverview } from "@src/api/community"
import { updateCommunityMutationFn } from "./mutations"

export type GeneralSettingsFormValues = {
  name: string | null | undefined
  shortDescription: string | null | undefined
  mainImage: {
    url: string | null | undefined
    blob: File | null | undefined
  }
  logoImage: {
    url: string | null | undefined
    blob: File | null | undefined
  }
  description: string | null | undefined
}

type ZodValidator = Validator<
  unknown,
  z.ZodType<unknown, z.ZodTypeDef, unknown>
>

export type GeneralSettingsFormProps = {
  initialData: CommunityOverview
}

export const GeneralSettingsForm = ({
  initialData,
}: GeneralSettingsFormProps) => {
  const queryClient = useQueryClient()

  const saveCommunityMutation = useMutation({
    mutationFn: updateCommunityMutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: communityKeys.overview(initialData.id),
      })
    },
  })

  const validators = {
    name: {
      onBlur: z
        .string()
        .min(
          3,
          t({
            message: "The community name must be at least 3 characters",
            id: "community_name_min_3",
          }),
        )
        .max(
          80,
          t({
            message: "The community name must be under 80 characters",
            id: "community_name_max_80",
          }),
        ),
    },
  }

  const form = useForm<GeneralSettingsFormValues, ZodValidator>({
    defaultValues: {
      name: initialData?.name,
      shortDescription: initialData?.shortDescription,
      mainImage: {
        url: initialData?.mainImage,
        blob: null as File | null | undefined,
      },
      logoImage: {
        url: initialData?.logoImage,
        blob: null as File | null | undefined,
      },
      description: initialData?.description,
    },
    validatorAdapter: zodValidator(),
    onSubmit: async (values) => {
      if (initialData) {
        saveCommunityMutation.mutate({
          data: initialData,
          formValues: values.value,
        })
      }
    },
  })

  const handleCancel = () => {
    form.reset()
  }

  return (
    <div className="flex flex-col gap-4 lg:gap-6">
      <header className="flex items-center justify-end gap-2">
        <Button variant="secondary" small onClick={handleCancel}>
          <Trans id="cancel">Cancel</Trans>
        </Button>
        <form.Subscribe
          selector={(state) => [state.canSubmit, state.isSubmitting]}
          children={([canSubmit]) => (
            <Button
              small
              type="submit"
              disabled={!canSubmit || saveCommunityMutation.isPending}
              onClick={form.handleSubmit}
            >
              <Trans id="save_changes">Save Changes</Trans>
            </Button>
          )}
        />
      </header>
      <section>
        <PageContainer className="m-auto max-w-[800px]">
          <form
            className="m-auto"
            onSubmit={(e) => {
              e.preventDefault()
              e.stopPropagation()
              form.handleSubmit()
            }}
          >
            <form.Field
              name="mainImage"
              children={(field) => (
                <ImageUpload
                  label={t({
                    message: "Cover image",
                    id: "cover_image",
                  })}
                  description={t({
                    message:
                      "Use a landscape image of max 1 MB and at least 400px.",
                    id: "cover_landscape_image_description",
                  })}
                  name="mainImage"
                  value={{
                    url: field.state.value.url,
                  }}
                  buttonText="Upload"
                  imageContainerClassname="aspect-3/1"
                  onChange={(imageDetails) => {
                    field.handleChange({
                      blob: imageDetails?.blob,
                      url: null,
                    })
                  }}
                />
              )}
            />

            <form.Field
              name="logoImage"
              children={(field) => (
                <ImageUpload
                  label={t({
                    message: "Logo image",
                    id: "logo_image",
                  })}
                  description={t({
                    message:
                      "Use a square image of max 1 MB and at least 400px.",
                    id: "cover_square_image_description",
                  })}
                  name="logoImage"
                  className="mt-6"
                  imageContainerClassname="w-48 aspect-square"
                  value={{
                    url: field.state.value.url,
                  }}
                  buttonText="Upload"
                  onChange={(imageDetails) => {
                    field.handleChange({
                      blob: imageDetails?.blob,
                      url: null,
                    })
                  }}
                />
              )}
            />

            <form.Field
              name="name"
              validators={validators.name}
              children={(field) => (
                <Field className="mt-6 flex-col">
                  <Label
                    htmlFor="name"
                    description={t({
                      message:
                        "Try to keep it under 50 characters. 80 is the maximum allowed.",
                      id: "community_name_description",
                    })}
                    required
                  >
                    <Trans id="community_name">Community Name</Trans>
                  </Label>
                  <Input
                    name="name"
                    id="name"
                    onChange={(value) => field.handleChange(value as string)}
                    error={field.state.meta.errors.join(", ")}
                    value={field.state.value}
                    onBlur={field.handleBlur}
                  />
                </Field>
              )}
            />

            <form.Field
              name="shortDescription"
              children={(field) => (
                <Field className="mt-6 flex-col">
                  <Label
                    htmlFor="short-description"
                    description={t({
                      message:
                        "Appears next to the logo and the name. State the purpose of the community.",
                      id: "community_short_description_description",
                    })}
                  >
                    <Trans id="short_description">Short description</Trans>
                  </Label>
                  <Input
                    name="shortDescription"
                    id="short-description"
                    onChange={(value) => field.handleChange(value as string)}
                    value={field.state.value}
                  />
                </Field>
              )}
            />

            {/* Maybe move the suspense in the editor component? And lazy import the ckedito module there */}
            <form.Field
              name="description"
              children={(field) => (
                <Field className="mt-6 flex-col">
                  <Label
                    htmlFor="description"
                    description={t({
                      message: "An about page for your community",
                      id: "community_about_page_description",
                    })}
                  >
                    <Trans id="description">Description</Trans>
                  </Label>
                  <EditorLazy
                    value={field.state.value}
                    onChange={(value) => {
                      field.handleChange(value)
                    }}
                    className="max-h-[400px] overflow-auto rounded-xl border border-pearl-lighter px-5 py-2 focus:border-pearl-light"
                  />
                </Field>
              )}
            />
          </form>
        </PageContainer>
      </section>
    </div>
  )
}
