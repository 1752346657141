import { FormApi, ReactFormApi } from "@tanstack/react-form"
import { t } from "@lingui/macro"

import { EditorFormDto, FormQuestionAnswerType } from "@src/api/form"
import { Input } from "@src/components/atoms/Input"
import { Select } from "@src/components/atoms/Select"
import { ZodValidator } from "../"

type GenericFormQuestionProps = {
  index: number
  form: FormApi<EditorFormDto, ZodValidator> &
    ReactFormApi<EditorFormDto, ZodValidator>
}

const toOptions = Array.from({ length: 2 }, (_, i) => ({
  id: i,
  label: i.toString(),
}))

const fromOptions = Array.from({ length: 8 }, (_, i) => ({
  id: i + 2,
  label: (i + 2).toString(),
}))

export const GenericFormQuestionScale = ({
  index,
  form,
}: GenericFormQuestionProps) => {
  const answerType = form.useStore(
    (state) => state.values?.questions?.[index].answerType,
  )
  if (answerType !== FormQuestionAnswerType.ScaleAnswer) {
    return null
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-end gap-2">
        <form.Field
          name={`questions[${index}].questionOptions[0].questionOptionOrder`}
          children={(field) => (
            <Select
              className="w-full"
              error={field.state.meta.errors.join(", ")}
              label={t({
                message: "From",
                id: "from",
              })}
              placeholder={t({
                message: "From",
                id: "from",
              })}
              variant="secondary"
              onSelect={({ id }: { id: number }) => {
                field.handleChange(id)
              }}
              value={fromOptions.find(({ id }) => id === field.state.value)}
              options={fromOptions}
            />
          )}
        />

        <form.Field
          name={`questions[${index}].questionOptions[0].questionOption`}
          children={(field) => (
            <Input
              className="w-full"
              error={field.state.meta.errors.join(", ")}
              name="value"
              placeholder={t({
                message: "Label (optional)",
                id: "label_optional",
              })}
              variant="ternary"
              onChange={(value) => {
                field.handleChange(value as string)
              }}
              value={field.state.value}
              onBlur={field.handleBlur}
            />
          )}
        />
      </div>
      <div className="flex items-end gap-2">
        <form.Field
          name={`questions[${index}].questionOptions[1].questionOptionOrder`}
          children={(field) => (
            <Select
              className="w-full"
              error={field.state.meta.errors.join(", ")}
              label={t({
                message: "To",
                id: "to",
              })}
              placeholder={t({
                message: "To",
                id: "to",
              })}
              variant="secondary"
              onSelect={({ id }: { id: number }) => {
                field.handleChange(id)
              }}
              value={toOptions.find(({ id }) => id === field.state.value)}
              options={fromOptions}
            />
          )}
        />

        <form.Field
          name={`questions[${index}].questionOptions[1].questionOption`}
          children={(field) => (
            <Input
              className="w-full"
              error={field.state.meta.errors.join(", ")}
              name="value"
              placeholder={t({
                message: "Label (optional)",
                id: "label_optional",
              })}
              variant="ternary"
              onChange={(value) => {
                field.handleChange(value as string)
              }}
              value={field.state.value}
              onBlur={field.handleBlur}
            />
          )}
        />
      </div>
    </div>
  )
}
