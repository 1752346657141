import { useMemo } from "react"
import { t, Trans } from "@lingui/macro"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { ColumnDef, createColumnHelper } from "@tanstack/react-table"
import { useNavigate } from "@tanstack/react-router"

import { Table } from "@src/components/organisms/Table"
import { Button } from "@src/components/atoms/Button"
import {
  getTabSettingsByCommunityQuery,
  deleteTab,
  tabSettingsKeys,
} from "@src/api/tab"
import { useDialogQueue } from "@src/context/DialogQueueProvider/useDialogQueue"

export type TabSettingsProps = {
  communityId: number
}

type TabItem = {
  id?: number | undefined
  name?: string | null | undefined
  icon?: string | null | undefined
  canFollow?: boolean | undefined
}

export const Pages = ({ communityId }: TabSettingsProps) => {
  const queryClient = useQueryClient()
  const { enqueueDialog, closeCurrentDialog } = useDialogQueue()
  const navigate = useNavigate()

  const { data: tabs, isLoading: tabsLoading } = useQuery(
    getTabSettingsByCommunityQuery(communityId),
  )

  const { mutate: deleteCommunityTabMutation, isPending: isPendingDeletion } =
    useMutation({
      mutationFn: (tabId: number) => {
        return deleteTab(tabId)
      },
      onSuccess: () => {
        closeCurrentDialog()

        queryClient.invalidateQueries({
          queryKey: tabSettingsKeys.tabsList(communityId),
        })
      },
    })

  const combinedTabs = useMemo(() => {
    const startupTab: TabItem = {
      id: 0,
      name: "Startups",
    }

    if (!tabs || tabs.length === 0) {
      return [startupTab]
    }

    return [startupTab, ...tabs]
  }, [tabs])

  const columnHelper = createColumnHelper<TabItem>()
  const columns = [
    columnHelper.accessor("name", {
      header: t({
        message: "Name",
        id: "name",
      }),
      size: 500,
    }),
    columnHelper.accessor("id", {
      header: t({
        message: "Actions",
        id: "actions",
      }),
      cell: ({ row }) => {
        const { id, name } = row.original

        return (
          <span className="flex h-4">
            <Button
              variant="text"
              icon="edit"
              className="pl-0"
              small
              onClick={() => {
                navigate({
                  to: "/community/$communityId/admin-panel/pages/$pageId",
                  params: {
                    communityId: String(communityId),
                    pageId: String(id),
                  },
                })
              }}
            >
              <Trans id="edit">Edit</Trans>
            </Button>
            {id != null && id !== 0 && (
              <Button
                variant="text"
                icon="delete"
                className="pl-0"
                disabled={isPendingDeletion}
                small
                onClick={() => {
                  enqueueDialog({
                    title: t({
                      message: `Are you sure you want to delete this the ${name} tab?`,
                      id: "delete_tab_title",
                    }),
                    actions: (
                      <div className={"flex justify-end gap-2"}>
                        <Button
                          small
                          variant="secondary"
                          onClick={closeCurrentDialog}
                        >
                          <Trans id="delete">Delete</Trans>
                        </Button>
                        <Button
                          small
                          onClick={() => {
                            deleteCommunityTabMutation(id)
                          }}
                        >
                          <Trans id="delete">Delete</Trans>
                        </Button>
                      </div>
                    ),
                  })
                }}
              >
                <Trans id="delete">Delete</Trans>
              </Button>
            )}
          </span>
        )
      },
    }),
  ] as ColumnDef<TabItem>[]

  return (
    <div className="flex flex-col gap-4 lg:gap-6">
      <div className="flex items-center gap-2">
        <Button
          variant="primary"
          small
          onClick={() => {
            navigate({
              to: "/community/$communityId/admin-panel/pages/create",
              params: {
                communityId: String(communityId),
              },
            })
          }}
        >
          <Trans id="create_new_page">Create new page</Trans>
        </Button>
      </div>
      <div>
        <Table columns={columns} data={combinedTabs} loading={tabsLoading} />
      </div>
    </div>
  )
}
