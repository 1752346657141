import { useState } from "react"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { CellContext, ColumnDef } from "@tanstack/react-table"
import { useNavigate } from "@tanstack/react-router"
import { t, Trans } from "@lingui/macro"
import clsx from "clsx"

import { getCommunityOverviewQuery, communityKeys } from "@src/api/community"
import {
  deleteMethodology,
  toggleMethodologyVisibility,
} from "@src/api/methodologies"

import { Table } from "@src/components/organisms/Table"
import { useCommunityId } from "@src/hooks/useCommunityId"
import { Button } from "@src/components/atoms/Button"
import { SearchInput } from "@src/components/atoms/SearchInput"
import { useDialogQueue } from "@src/context/DialogQueueProvider/useDialogQueue"

export interface ProgramTableItem {
  id: number | undefined
  name: string | null | undefined
  isActive: boolean | undefined
}

const initialPageSize = 10
export const Programs = () => {
  const queryClient = useQueryClient()
  const [searchValue, setSearchValue] = useState("")
  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(initialPageSize)
  const communityId = useCommunityId()
  const navigate = useNavigate()

  const { enqueueDialog, closeCurrentDialog } = useDialogQueue()

  const { data: communityOverview, isLoading } = useQuery(
    getCommunityOverviewQuery(communityId),
  )

  const { mutate: deleteMethodologyMutation, isPending: isPendingDeletion } =
    useMutation({
      mutationFn: (methodologyId: number) => {
        return deleteMethodology(communityId || 0, methodologyId)
      },
      onSuccess: () => {
        closeCurrentDialog()

        queryClient.invalidateQueries({
          queryKey: communityKeys.overview(communityId),
        })
      },
    })

  const {
    mutate: toggleMethodologyVisibilityMutation,
    isPending: isPendingVisibility,
  } = useMutation({
    mutationFn: ({
      methodologyId,
      enable,
    }: {
      methodologyId: number
      enable: boolean
    }) => {
      return toggleMethodologyVisibility(methodologyId, enable)
    },
    onSuccess: () => {
      closeCurrentDialog()

      queryClient.invalidateQueries({
        queryKey: communityKeys.overview(communityId),
      })
    },
  })

  const columns: ColumnDef<ProgramTableItem>[] = [
    {
      header: t({
        message: "Name",
        id: "name",
      }),
      accessorKey: "name",
      size: 500,
      cell: ({ row }: CellContext<ProgramTableItem, unknown>) => {
        const name = row.original.name || 0
        const isActive = row.original.isActive || false

        return <span className={clsx(!isActive && "opacity-50")}>{name}</span>
      },
    },
    {
      header: t({
        message: "Actions",
        id: "actions",
      }),
      accessorKey: "actions",
      cell: ({ row }: CellContext<ProgramTableItem, unknown>) => {
        const id = row.original.id || 0
        const isActive = row.original.isActive || false

        return (
          <span className="flex h-4">
            <Button
              variant="text"
              icon="edit"
              className="pl-0"
              small
              onClick={() => {
                navigate({
                  to: "/community/$communityId/admin-panel/programs/$programId",
                  params: {
                    communityId: String(communityId),
                    programId: String(id),
                  },
                  search: { duplicate: false },
                })
              }}
            >
              <Trans id="edit">Edit</Trans>
            </Button>
            <Button
              onClick={() => {
                navigate({
                  to: "/community/$communityId/admin-panel/programs/$programId/kpis",
                  params: {
                    communityId: String(communityId),
                    programId: String(id),
                  },
                })
              }}
              className="pl-0"
              variant="text"
              icon="timeline"
              small
            >
              <Trans id="kpis">KPIs</Trans>
            </Button>
            <Button
              onClick={() => {
                navigate({
                  to: "/community/$communityId/admin-panel/programs/$programId/planner",
                  params: {
                    communityId: String(communityId),
                    programId: String(id),
                  },
                })
              }}
              className="pl-0"
              variant="text"
              icon="schedule"
              small
            >
              <Trans id="plan">Plan</Trans>
            </Button>
            <Button
              variant="text"
              icon="file_copy"
              small
              className="pl-0"
              onClick={() => {
                navigate({
                  to: "/community/$communityId/admin-panel/programs/$programId",
                  params: {
                    communityId: String(communityId),
                    programId: String(id),
                  },
                  search: { duplicate: true },
                })
              }}
            >
              <Trans id="duplicate">Duplicate</Trans>
            </Button>
            <Button
              variant="text"
              icon={isActive ? "visibility_off" : "visibility"}
              small
              className="pl-0"
              disabled={isPendingVisibility}
              onClick={() => {
                enqueueDialog({
                  title: isActive
                    ? t({
                        message: `Are you sure you want to hide the ${row.original.name} program?`,
                        id: "hide_program_title",
                      })
                    : t({
                        message: `Are you sure you want to show the ${row.original.name} program?`,
                        id: "show_program_title",
                      }),
                  actions: (
                    <div className="flex justify-end gap-2">
                      <Button
                        small
                        variant="secondary"
                        onClick={closeCurrentDialog}
                      >
                        <Trans id="delete">Delete</Trans>
                      </Button>

                      <Button
                        small
                        onClick={() => {
                          toggleMethodologyVisibilityMutation({
                            methodologyId: id,
                            enable: !isActive,
                          })
                        }}
                      >
                        <Trans id="confirm">Confirm</Trans>
                      </Button>
                    </div>
                  ),
                })
              }}
            >
              {isActive ? (
                <Trans id="hide">Hide</Trans>
              ) : (
                <Trans id="show">Show</Trans>
              )}
            </Button>
            <Button
              variant="text"
              icon="delete"
              small
              className="pl-0"
              disabled={isPendingDeletion}
              onClick={() => {
                enqueueDialog({
                  title: t({
                    message: `Are you sure you want to delete the ${row.original.name} program?`,
                    id: "delete_program_title",
                  }),
                  actions: (
                    <div className="flex justify-end gap-2">
                      <Button
                        small
                        variant="secondary"
                        onClick={closeCurrentDialog}
                      >
                        <Trans id="delete">Delete</Trans>
                      </Button>

                      <Button
                        small
                        onClick={() => {
                          deleteMethodologyMutation(id)
                        }}
                      >
                        <Trans id="delete">Delete</Trans>
                      </Button>
                    </div>
                  ),
                })
              }}
            >
              <Trans id="delete">Delete</Trans>
            </Button>
          </span>
        )
      },
    },
  ]

  const filteredApplications =
    communityOverview?.methodologies
      ?.filter(({ name }) =>
        name?.toLowerCase().includes(searchValue.toLowerCase()),
      )
      .map(({ id, name, isActive }) => ({
        id,
        name,
        isActive,
      })) ?? []

  const data = filteredApplications.slice(
    pageIndex * pageSize,
    (pageIndex + 1) * pageSize,
  )

  return (
    <div className="flex flex-col gap-6">
      <div className="flex gap-3">
        <Button
          small
          onClick={() => {
            navigate({
              to: "/community/$communityId/admin-panel/programs/create",
              params: {
                communityId: String(communityId),
              },
            })
          }}
        >
          <Trans id="create_new_program">Create new {200}</Trans>
        </Button>
        <SearchInput
          name="search"
          value={searchValue}
          onChange={(value: string) => {
            setPageIndex(0)
            setSearchValue(value)
          }}
        />
      </div>
      <Table
        columns={columns}
        data={data}
        loading={isLoading}
        itemsCount={filteredApplications.length}
        pageIndex={pageIndex}
        pageSize={pageSize}
        onPageChange={setPageIndex}
        onPageSizeChange={setPageSize}
      />
    </div>
  )
}
