import { t } from "@lingui/macro"

export const API_BASE_URL = import.meta.env.VITE_API_BASE_URL.endsWith("/")
  ? import.meta.env.VITE_API_BASE_URL.slice(0, -1)
  : import.meta.env.VITE_API_BASE_URL

export const STAGING_URL = "app-new-stag.babele.co"

export const PROD_URL = "app-new.babele.co"

export const V1_APP_URL =
  import.meta.env.MODE === "production"
    ? "https://app.babele.co"
    : "https://app-stag.babele.co"

export const UPLOAD_FOLDERS = {
  resourceFiles: "resource_files",
  tabImages: "tab_image",
  businessPlanLogos: "businessplan_logo",
  discussionImages: "discussion_image",
  communityImages: "community_image",
  profilePictures: "profile_picture",
  customLogos: "custom_logo",
} as const

export type FileUploadFolder =
  (typeof UPLOAD_FOLDERS)[keyof typeof UPLOAD_FOLDERS]

export const UPLOAD_FOLDERS2 = [
  "resource_files",
  "tab_image",
  "businessplan_logo",
  "discussion_image",
  "community_image",
  "profile_picture",
  "custom_logo",
] as const

export const SKILLS_IMAGES = [
  "BusinessStrategy",
  "MarketingPR",
  "EntreIntrapreneurship",
  "InformationTechnology",
  "Legal",
  "NaturalSocialSciences",
  "ProductDesign",
  "Engineering",
  "Others",
  "FinanceAccounting",
  "Management",
  "HumanResources",
  "FundingCapital",
  "Sales",
  "Languages",
  "BusinessOperations",
  "Buildingandconstruction",
  "CleanEnergy",
  "Lifescience",
  "FintechandInsurtech",
] as const

export const getSkillImage = (skill: (typeof SKILLS_IMAGES)[number]) => {
  return `${V1_APP_URL}/static/skill-categories/${skill}`
}

// These are hardcoded in the frontend only, the backend doesn't have a concept of SDGs
// beyond the numerical ids
export const SDGList = () => {
  const sdgList = [
    {
      name: t({
        message: "No Poverty",
        id: "no_poverty",
      }),
      iconPath:
        "/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-01.png",
      id: 1,
    },
    {
      name: t({
        message: "Zero Hunger",
        id: "zero_hunger",
      }),
      iconPath:
        "/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-02.png",
      id: 2,
    },
    {
      name: t({
        message: "Good Health And Well-Being",
        id: "good_health_and_well_being",
      }),
      iconPath:
        "/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-03.png",
      id: 3,
    },
    {
      name: t({
        message: "Quality Education",
        id: "quality_education",
      }),
      iconPath:
        "/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-04.png",
      id: 4,
    },
    {
      name: t({
        message: "Gender Equality",
        id: "gender_equality",
      }),
      iconPath:
        "/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-05.png",
      id: 5,
    },
    {
      name: t({
        message: "Clean Water And Sanitation",
        id: "clean_water_and_sanitation",
      }),
      iconPath:
        "/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-06.png",
      id: 6,
    },
    {
      name: t({
        message: "Affordable And Clean Energy",
        id: "affordable_and_clean_energy",
      }),
      iconPath:
        "/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-07.png",
      id: 7,
    },
    {
      name: t({
        message: "Decent Work and Economic Growth",
        id: "decent_work_and_economic_growth",
      }),
      iconPath:
        "/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-08.png",
      id: 8,
    },
    {
      name: t({
        message: "Industry, Innovation And Infrastructure",
        id: "industry_innovation_and_infrastructure",
      }),
      iconPath:
        "/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-09.png",
      id: 9,
    },
    {
      name: t({
        message: "Reduced Inequalities",
        id: "reduced_inequalities",
      }),
      iconPath:
        "/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-10.png",
      id: 10,
    },
    {
      name: t({
        message: "Sustainable Cities And Communities",
        id: "sustainable_cities_and_communities",
      }),
      iconPath:
        "/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-11.png",
      id: 11,
    },
    {
      name: t({
        message: "Responsible Consumption And Production",
        id: "responsible_consumption_and_production",
      }),
      iconPath:
        "/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-12.png",
      id: 12,
    },
    {
      name: t({
        message: "Climate Action",
        id: "climate_action",
      }),
      iconPath:
        "/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-13.png",
      id: 13,
    },
    {
      name: t({
        message: "Life Below Water",
        id: "life_below_water",
      }),
      iconPath:
        "/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-14.png",
      id: 14,
    },
    {
      name: t({
        message: "Life On Land",
        id: "life_on_land",
      }),
      iconPath:
        "/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-15.png",
      id: 15,
    },
    {
      name: t({
        message: "Peace, Justice And Strong Institutions",
        id: "peace_justice_and_strong_institutions",
      }),
      iconPath:
        "/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-16.png",
      id: 16,
    },
    {
      name: t({
        message: "Partnerships For The Goals",
        id: "partnerships_for_the_goals",
      }),
      iconPath:
        "/Images/branding.babele/SDGs/Icons_Square/goals_icons-individual-rgb-17.png",
      id: 17,
    },
  ]

  return sdgList.map((sdg) => {
    return {
      ...sdg,
      iconPath: `${V1_APP_URL}${sdg.iconPath}`,
    }
  })
}
