import { useContext } from "react"

import { DialogQueueContext, DialogQueueContextValue } from "./"

export const useDialogQueue = (): DialogQueueContextValue => {
  const context = useContext(DialogQueueContext)
  if (!context) {
    throw new Error("useDialogQueue must be used within a DialogQueueProvider")
  }

  return context
}
