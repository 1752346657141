import { UseMutationResult, useQuery } from "@tanstack/react-query"
import { Trans, t } from "@lingui/macro"
import {
  ApplicationResponse,
  ApplicationStatus,
  ApplicationDefinition,
} from "@src/api/application"
import { getCommunityOverviewQuery } from "@src/api/community"
import { Button } from "@src/components/atoms/Button"
import { InfoContainer } from "@src/components/atoms/InfoContainer"
import { Skeleton } from "@src/components/atoms/Skeleton"

export type AcceptApplicationDialogProps = {
  selectedApplications: Array<ApplicationResponse & { id: number }>
  applicationDefinition: ApplicationDefinition & { id: number }
  mutation: UseMutationResult<void, Error, void, unknown>
  onClose: () => void
}

export const AcceptApplicationsDialog = ({
  selectedApplications,
  applicationDefinition,
  onClose,
  mutation,
}: AcceptApplicationDialogProps) => {
  const {
    data: communityOverview,
    isLoading: communityLoading,
    isError: communityError,
  } = useQuery(getCommunityOverviewQuery(applicationDefinition.communityId))

  const applicationsNotSubmitted = selectedApplications.filter(
    (application) => application?.status !== ApplicationStatus.Pending,
  )

  if (communityLoading) {
    return <Skeleton className="h-12 w-full" />
  }

  if (communityError) {
    return (
      <InfoContainer
        type="error"
        text={t({
          message:
            "An error occurred while loading the community. Please try again.",
          id: "community_loading_error",
        })}
      />
    )
  }

  return (
    <div>
      {applicationsNotSubmitted.length > 0 && (
        <InfoContainer
          type="warning"
          text={t({
            message:
              "Only submitted applications can be accepted. Please review the selected applications and try again.",
            id: "application_prevent_accept_non_pending",
          })}
          className="mb-4"
        />
      )}
      <p className="mb-4 text-paragraph-medium text-pearl-darker">
        {!applicationDefinition?.createProject && (
          <Trans id="accept_application_without_create_description">
            If you confirm, the applicant will be invited to join{" "}
            {communityOverview?.name}. Beware, this action is irreversible.
          </Trans>
        )}
        {applicationDefinition?.createProject && (
          <Trans id="accept_application_description">
            If you confirm, the application will be converted into a project in
            the community {communityOverview?.name}. Beware, this action is
            irreversible.
          </Trans>
        )}
      </p>
      <div className="flex items-center justify-end gap-2">
        <Button variant="secondary" small onClick={() => onClose()}>
          <Trans id="cancel">Cancel</Trans>
        </Button>
        <Button
          type="submit"
          disabled={mutation.isPending || applicationsNotSubmitted.length > 0}
          small
          onClick={() => mutation.mutate()}
        >
          <Trans id="accept_application">Accept applications</Trans>
        </Button>
      </div>
    </div>
  )
}
