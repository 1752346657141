import { createFileRoute } from "@tanstack/react-router"

import { ProgramPage } from "@src/components/pages/Programs/ProgramPage"

export const Route = createFileRoute(
  "/_auth/_community/community/$communityId/_admin-panel/admin-panel/programs/$programId/",
)({
  component: () => <ProgramPageWrapper />,
})

function ProgramPageWrapper() {
  const { programId } = Route.useParams()

  const parsedProgramId = parseInt(programId)

  return <ProgramPage programId={parsedProgramId} />
}
