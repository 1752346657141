import { useParams } from "@tanstack/react-router"

export const useCommunityId = () => {
  try {
    const params = useParams({ strict: false })

    return params.communityId ? parseInt(params.communityId, 10) : undefined
  } catch {
    const match = window.location.pathname.match(/\/community\/(\d+)/)
    return match ? parseInt(match[1], 10) : undefined
  }
}
