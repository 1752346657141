import { t } from "@lingui/macro"

import { useCommunityId } from "@src/hooks/useCommunityId"
import { IconType } from "@src/config/icons"

export type MenuItemType = {
  name: string
  link: string
  icon: IconType
  children?: Omit<MenuItemType, "children" | "icon">[]
}

export const useMenus = () => {
  const communityId = useCommunityId()

  const communityMenu: MenuItemType[] = [
    {
      name: t({
        message: "Home",
        id: "home",
      }),
      link: `/community/${communityId}`,
      icon: "home",
    },
    {
      name: t({
        message: "Forum",
        id: "forum",
      }),
      link: `/community/${communityId}/forum`,
      icon: "tooltip_2",
    },
    {
      name: t({
        message: "Members",
        id: "members",
      }),
      link: `/community/${communityId}/members`,
      icon: "group",
    },
    {
      name: t({
        message: "Resources",
        id: "resources",
      }),
      link: `/community/${communityId}/resources`,
      icon: "folder_copy",
    },
    {
      name: t({
        message: "Events",
        id: "events",
      }),
      link: `/community/${communityId}/events`,
      icon: "event",
    },
    {
      name: t({
        message: "Requests",
        id: "requests",
      }),
      link: `/community/${communityId}/request`,
      icon: "live_help",
    },
    {
      name: t({
        message: "Applications",
        id: "applications",
      }),
      link: `/community/${communityId}/applications`,
      icon: "page_info",
    },
    {
      name: t({
        message: "Settings",
        id: "settings",
      }),
      link: `/community/${communityId}/admin-panel/community-settings`,
      icon: "tune",
    },
  ]
  const adminMenu: MenuItemType[] = [
    {
      name: t({
        message: "General",
        id: "general",
      }),
      link: `/community/${communityId}/admin-panel/community-settings`,
      icon: "tune",
    },
    {
      name: t({
        message: "Forms",
        id: "forms",
      }),
      link: `/community/${communityId}/admin-panel/forms`,
      icon: "description",
      children: [
        {
          name: t({
            message: "Application",
            id: "application",
          }),
          link: `/community/${communityId}/admin-panel/forms/application`,
        },
        {
          name: t({
            message: "Survey",
            id: "survey",
          }),
          link: `/community/${communityId}/admin-panel/forms/survey`,
        },
      ],
    },
    {
      name: t({
        message: "{201}",
        id: "programs",
      }),
      link: `/community/${communityId}/admin-panel/programs`,
      icon: "lightbulb",
    },
    {
      name: t({
        message: "Applications",
        id: "applications",
      }),
      link: `/community/${communityId}/admin-panel/application-definitions`,
      icon: "bookmarks",
    },
    {
      name: t({
        message: "Surveys",
        id: "surveys",
      }),
      link: `/community/${communityId}/admin-panel/surveys`,
      icon: "assignment",
    },
    {
      name: t({
        message: "{101}",
        id: "projects",
      }),
      link: `/community/${communityId}/admin-panel/overview-projects`,
      icon: "rocket_launch",
    },
    {
      name: t({
        message: "Reports",
        id: "reports",
      }),
      link: `/community/${communityId}/admin-panel/requests-status`,
      icon: "data_exploration",
      children: [
        {
          name: t({
            message: "Coaching",
            id: "coaching",
          }),
          link: `/community/${communityId}/admin-panel/requests-status`,
        },
      ],
    },
    {
      name: t({
        message: "Permissions",
        id: "permissions",
      }),
      link: `/community/${communityId}/admin-panel/circle-management`,
      icon: "encrypted",
    },
    {
      name: t({
        message: "Pages",
        id: "pages",
      }),
      link: `/community/${communityId}/admin-panel/pages`,
      icon: "home",
    },
    {
      name: t({
        message: "Statistics",
        id: "statistics",
      }),
      link: `/community/${communityId}/admin-panel/statistics`,
      icon: "monitoring",
    },
  ]

  return { adminMenu, communityMenu }
}
