import { lazy, Suspense, useState } from "react"
import { useForm } from "@tanstack/react-form"
import { UseMutationResult } from "@tanstack/react-query"
import { z } from "zod"
import { zodValidator } from "@tanstack/zod-form-adapter"
import ReCAPTCHA from "react-google-recaptcha"
import { Trans, t } from "@lingui/macro"
import { ApplicationResponse } from "@src/api/application"
import { SendGroupMessage } from "@src/api/message"
import { Skeleton } from "@src/components/atoms/Skeleton"

import { Button } from "@src/components/atoms/Button"
import { Field, Label } from "@src/components/atoms/Fieldset"

const Editor = lazy(() =>
  import("@src/components/organisms/Editor").then((module) => ({
    default: module.Editor,
  })),
)

export type SendEmailsDialogProps = {
  selectedApplications: Array<ApplicationResponse & { id: number }>
  mutation: UseMutationResult<void, Error, void, unknown>
  onClose: () => void
  onSubmit: (message: Partial<SendGroupMessage>) => void
}

export const SendEmailsDialog = ({
  selectedApplications,
  mutation,
  onClose,
  onSubmit,
}: SendEmailsDialogProps) => {
  const [message, setMessage] = useState("")
  const [recaptchaResponse, setRecaptchaResponse] = useState("")

  const validators = {
    message: {
      onBlur: z
        .string()
        .min(
          1,
          t({
            message: "Message is required",
            id: "message_required",
          }),
        )
        .max(
          5000,
          t({
            message: "Message is too long",
            id: "message_too_long",
          }),
        ),
    },
    recaptchaResponse: {
      onBlur: z.string().min(
        1,
        t({
          message: "Recaptcha is required",
          id: "recaptcha_required",
        }),
      ),
    },
  }

  const form = useForm({
    defaultValues: {
      message: "",
      recaptchaResponse: "",
    },
    validatorAdapter: zodValidator(),
    onSubmit: (formDetails) => {
      onSubmit(formDetails.value)
    },
  })

  return (
    <div>
      <Suspense fallback={<Skeleton className="h-[100px]" />}>
        <form>
          <form.Field
            name="message"
            validators={validators.message}
            children={(field) => (
              <Field className="flex-col">
                <Label htmlFor="message" description="Message" />
                <Editor
                  value={field.state.value}
                  onChange={(value) => {
                    field.handleChange(value)
                    setMessage(value)
                  }}
                  className="max-h-[400px] overflow-auto rounded-xl border border-pearl-lighter px-5 py-2 focus:border-pearl-light"
                />
              </Field>
            )}
          />
          <form.Field
            name="recaptchaResponse"
            validators={validators.recaptchaResponse}
            children={(field) => (
              <div className="my-4 flex items-center justify-center">
                <ReCAPTCHA
                  sitekey={import.meta.env.VITE_CAPTCHA_SITEKEY}
                  onChange={(value) => {
                    field.handleChange(value || "")
                    setRecaptchaResponse(value || "")
                  }}
                />
              </div>
            )}
          />
          <div className="flex items-center justify-end gap-2">
            <Button variant="secondary" small onClick={() => onClose()}>
              <Trans id="cancel">Cancel</Trans>
            </Button>
            <Button
              type="submit"
              small
              onClick={(e) => {
                e.preventDefault()
                form.handleSubmit()
              }}
              disabled={
                mutation.isPending ||
                !selectedApplications.length ||
                !message ||
                !recaptchaResponse
              }
            >
              <Trans id="send_message">Send message</Trans>
            </Button>
          </div>
        </form>
      </Suspense>
    </div>
  )
}
