import { saveTab, TabForm } from "@src/api/tab"
import { saveCommunityProjectForm } from "@src/api/community"
import { FormDetailDto } from "@src/api/form"
import { uploadFile } from "@src/api/file"
import { EditorFormDto } from "@src/api/form"
import {
  prepareFormSaveStep1,
  prepareFormSaveStep2,
} from "@src/components/organisms/GenericForm/parser"

export const updateTabMutationFn = async ({
  formValues,
  data,
}: {
  formValues: EditorFormDto & {
    canFollow?: boolean
    includeInLandingPage?: boolean
    icon?: {
      url: string | null | undefined
      blob: File | null | undefined
    }
    createdAt?: string
  }
  data: TabForm
  communityId?: number
}) => {
  const newTabInfo: TabForm = {
    canFollow: formValues.canFollow ?? false,
    communityId: formValues.communityId,
    createdAt: formValues.createdAt,
    creatorUserId: data.creatorUserId,
    editedAt: data.editedAt,
    form: {
      ...data.form,
      questions: formValues.questions,
    },
    formId: data.formId,
    icon: data.icon,
    id: data.id,
    includeInLandingPage:
      formValues.includeInLandingPage ?? data.includeInLandingPage ?? false,
    name: formValues.name,
    requestForm: data.requestForm,
    requestFormId: data.requestFormId,
    requestIntro: data.requestIntro,
  }

  if (formValues.icon?.blob) {
    const url = await uploadFile({
      file: formValues.icon.blob,
      folder: "tab_image",
    })

    newTabInfo.icon = `/${url}`
  }

  const step1Form = prepareFormSaveStep1(formValues)

  if (data.id) {
    const response = await saveTab(
      {
        ...newTabInfo,
        form: step1Form,
      },
      data.id,
    )

    const step2Form = prepareFormSaveStep2(formValues, response.form || {})
    return await saveTab(
      {
        ...newTabInfo,
        form: step2Form,
      },
      data.id,
    )
  } else {
    const response = await saveTab({
      ...newTabInfo,
      form: step1Form,
    })

    const step2Form = prepareFormSaveStep2(formValues, response.form || {})
    await saveTab(
      {
        ...newTabInfo,
        form: step2Form,
      },
      response.id,
    )

    return response
  }
}

export const updateProjectFormMutationFn = async ({
  formValues,
  communityId,
}: {
  formValues: FormDetailDto
  communityId: number
}) => {
  const step1Form = prepareFormSaveStep1(formValues)
  const response = await saveCommunityProjectForm(step1Form, communityId)

  const step2Form = prepareFormSaveStep2(
    {
      ...formValues,
      questions: formValues.questions,
    },
    response || {},
  )

  return await saveCommunityProjectForm(
    {
      ...step1Form,
      questions: step2Form.questions,
    },
    communityId,
  )
}
