import { createFileRoute } from "@tanstack/react-router"
import { Pages } from "@src/components/pages/Pages"

export const Route = createFileRoute(
  '/_auth/_community/community/$communityId/_admin-panel/admin-panel/pages/',
)({
  component: TabsPageWrapper,
})

function TabsPageWrapper() {
  const { communityId } = Route.useParams()
  const parsedCommunityId = parseInt(communityId)

  return <Pages communityId={parsedCommunityId} />
}
