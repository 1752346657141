import { createFileRoute } from '@tanstack/react-router'

import { SurveyFormPage } from '@src/components/pages/Forms/SurveyForms/SurveyFormPage'

export const Route = createFileRoute(
  '/_auth/_community/community/$communityId/_admin-panel/admin-panel/forms/survey/$formId',
)({
  component: () => <FormPageWrapper />,
  validateSearch: (search: Record<string, unknown>) => {
    return {
      view_mode: search.view_mode === true ? true : false,
      duplicate: search.duplicate === true ? true : false,
    }
  },
})

function FormPageWrapper() {
  const { formId } = Route.useParams()
  const { view_mode, duplicate } = Route.useSearch()

  // TODO: handle failing to parse formId
  const parsedFormId = parseInt(formId)

  return (
    <SurveyFormPage
      formId={parsedFormId}
      viewMode={view_mode}
      duplicate={duplicate}
    />
  )
}
