import { client } from "./client"
import { safeQueryOptions } from "@src/utils/query"
import { components } from "./schema"
import { ApiDynamicFilter } from "./filters"

export const surveyKeys = {
  all: ["surveys"],
  surveyDefinitions: (communityId?: number, take?: number, skip?: number) => [
    ...surveyKeys.all,
    "list",
    communityId,
    take,
    skip,
  ],
  surveyDefinition: (id?: number) => [...surveyKeys.all, "definition", id],
  surveyResponse: (id?: number) => [...surveyKeys.all, "response", id],
  surveyResponses: (id?: number) => [...surveyKeys.all, "responses", id],
  latestSurveyResponse: (id?: number) => [...surveyKeys.all, "latest", id],
}

export enum SurveyType {
  Individual = 0,
  Startup = 1,
}

export enum IterationType {
  Weekly = 0,
  Monthly = 1,
  Quarterly = 2,
  Semestral = 3,
  Yearly = 4,
  Custom = 5,
}

export type SurveyDefinition = components["schemas"]["SurveyDefinitionDto"]
export type SurveyDefinitionUpdate =
  components["schemas"]["SurveyDefinitionUpdateDto"]
export type SurveyResponseCreation =
  components["schemas"]["SurveyResponseCreationDto"]
export type SurveyResponseCreationUpdateBase =
  components["schemas"]["SurveyResponseCreationUpdateBaseDto"]
export type SurveyResponse = components["schemas"]["SurveyResponseDto"]

export enum SurveyStatus {
  Draft = 0,
  Submitted = 1,
}

enum SurveyOrderBy {
  Status,
  FillRate,
}

export type SurveyResponsesFilters = {
  iterationFilter?: ApiDynamicFilter<number>
  statusFilter?: ApiDynamicFilter<SurveyStatus>
  userFilter?: ApiDynamicFilter<number>
  projectFilter?: ApiDynamicFilter<number>
  orderBy?: SurveyOrderBy
  skip?: number
  take?: number
}

export const getSurveyDefinition = async (id: number) => {
  const data = await client.GET("/api/Survey/survey-definition/{id}", {
    params: {
      path: { id },
    },
  })
  return data
}

export const getSurveyDefinitionQuery = (id?: number) =>
  safeQueryOptions({
    queryFn: getSurveyDefinition,
    queryKeys: surveyKeys.surveyDefinition(id),
    unsafeQueryFnArgs: [id],
  })

export const saveSurveyDefinition = async (
  payload: SurveyDefinitionUpdate,
  surveyId?: number,
) => {
  if (!surveyId) {
    const data = await client.POST("/api/Survey/survey-definition", {
      body: payload,
    })

    return data
  }

  const data = await client.PUT("/api/Survey/survey-definitions/{surveyId}", {
    params: {
      path: { surveyId },
    },
    body: payload,
  })

  return data
}

export const getSurveyDefinitionList = async (
  communityId: number,
  skip: number,
  take: number,
) => {
  const data = await client.POST("/api/Survey/survey-definitions", {
    body: {
      communityId,
      skip,
      take,
    },
  })

  return data
}

export const getSurveyDefinitionListQuery = ({
  communityId,
  take,
  skip,
}: {
  communityId?: number
  take: number
  skip: number
}) =>
  safeQueryOptions({
    queryFn: getSurveyDefinitionList,
    queryKeys: surveyKeys.surveyDefinitions(communityId, take, skip),
    unsafeQueryFnArgs: [communityId, skip, take],
  })

export const getSurveyResponseList = async (id: number) => {
  const data = await client.POST(
    "/api/Survey/survey-definition/{id}/survey-responses",
    {
      params: {
        path: { id },
      },
      body: {
        submissionDateFilters: {},
        tagFilters: {},
        statusFilters: {},
        iterationFilters: {},
        take: 0,
        skip: 0,
      },
    },
  )

  return data
}

export const getSurveyResponseListQuery = (id?: number) =>
  safeQueryOptions({
    queryFn: getSurveyResponseList,
    queryKeys: surveyKeys.surveyResponses(id),
    unsafeQueryFnArgs: [id],
  })

export const getSurveyResponse = async (id: number) => {
  const data = await client.GET("/api/Survey/survey-response/{id}", {
    params: {
      path: { id },
    },
  })
  return data
}

export const getSurveyResponseQuery = (id?: number) =>
  safeQueryOptions({
    queryFn: getSurveyResponse,
    queryKeys: surveyKeys.surveyResponse(id),
    unsafeQueryFnArgs: [id],
  })

export const getLatestUserSurveyResponse = async (id: number) => {
  const data = await client.GET(
    "/api/Survey/{surveyId}/latest-user-survey-response",
    {
      params: {
        path: { surveyId: id },
      },
    },
  )

  return data
}

export const getLatestUserSurveyResponseQuery = (id?: number) =>
  safeQueryOptions({
    queryFn: getLatestUserSurveyResponse,
    queryKeys: surveyKeys.latestSurveyResponse(id),
    unsafeQueryFnArgs: [id],
  })

export const deleteSurveyDefinition = async (surveyId: number) => {
  const data = await client.DELETE("/api/Survey/survey-definition/{surveyId}", {
    params: {
      path: { surveyId },
    },
  })

  return data
}

export const saveSurvey = async (
  payload: SurveyResponseCreation | SurveyResponseCreationUpdateBase,
  id?: number,
) => {
  if (id) {
    const data = await client.PATCH(
      `/api/Survey/survey-response/{surveyResponseId}`,
      {
        params: {
          path: { surveyResponseId: id },
        },
        body: payload,
      },
    )

    return data
  } else {
    const data = await client.POST("/api/Survey/survey-response", {
      body: payload,
    })

    return data
  }
}
