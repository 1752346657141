import clsx from "clsx"

import { Icon } from "@src/components/atoms/Icon"
import { IconButton } from "@src/components/atoms/IconButton"
import { IconType } from "@src/config/icons"

export type InfoContainerProps = {
  type: "default" | "error" | "success" | "warning"
  text: string
  className?: string
  onClose?: () => void
  icon?: IconType
}

type ColourClasses = {
  textColor: string
  bgColor: string
}

type ColourMap = Record<InfoContainerProps["type"], ColourClasses>

const colourMap: ColourMap = {
  default: {
    textColor: "text-blue",
    bgColor: "bg-blue/10",
  },
  error: {
    textColor: "text-red",
    bgColor: "bg-red/10",
  },
  success: {
    textColor: "text-green",
    bgColor: "bg-green/10",
  },
  warning: {
    textColor: "text-orange",
    bgColor: "bg-orange/10",
  },
}

export const InfoContainer = ({
  type = "default",
  text,
  className,
  onClose,
  icon = "warning",
}: InfoContainerProps) => {
  const { textColor, bgColor } = colourMap[type]

  return (
    <div
      className={clsx(
        "flex items-center justify-between rounded-xl px-4 py-3",
        textColor,
        bgColor,
        className,
      )}
    >
      <span className="flex items-center gap-3">
        <Icon icon={icon} size="large" />

        <span className="text-paragraph-medium">{text}</span>
      </span>

      {
        onClose && (
          <IconButton
            icon="close"
            className={textColor}
            variant="textUnstyled"
            onClick={onClose}
          ></IconButton>
        )
      }
    </div>
  )
}
