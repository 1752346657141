import { clsx } from "clsx"
import { getInitials } from "@src/utils/image"

export type PlaceholderImageProps = {
  name: string
  className?: string
}

export const PlaceholderImage = ({
  name,
  className,
}: PlaceholderImageProps) => {
  const initials = getInitials(name)

  return (
    <div
      role="img"
      className={clsx([
        "flex items-center justify-center bg-blue-light font-bold text-white",
        className,
      ])}
    >
      {initials || ""}
    </div>
  )
}
