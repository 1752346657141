import { useRef, useState } from "react"
import clsx from "clsx"
import { useParams } from "@tanstack/react-router"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { Trans } from "@lingui/macro"

import { Button } from "@src/components/atoms/Button"
import { Image } from "@src/components/atoms/Image"
import { Countdown } from "@src/components/atoms/Countdown"
import { GenericFormViewer } from "@src/components/organisms/GenericFormViewer"

import { useCommunityId } from "@src/hooks/useCommunityId"
import {
  surveyKeys,
  SurveyStatus,
  SurveyResponseCreation,
  SurveyResponseCreationUpdateBase,
  getSurveyDefinitionQuery,
  getLatestUserSurveyResponseQuery,
  saveSurvey,
} from "@src/api/survey"
import { getSurveyFormQuery } from "@src/api/surveyForm"
import { getCommunityOverviewQuery } from "@src/api/community"

export const SurveysCompletePage = () => {
  const [surveyStatus, setSurveyStatus] = useState(SurveyStatus.Draft)
  const formRef = useRef<{ handleSubmit: () => void }>(null)
  const communityId = useCommunityId()
  const queryClient = useQueryClient()

  const params = useParams({ strict: false })
  const surveyId = params.surveyId ? parseInt(params.surveyId, 10) : undefined

  const { data: survey } = useQuery(getSurveyDefinitionQuery(surveyId))
  const { data: surveyLatestResponse } = useQuery(
    getLatestUserSurveyResponseQuery(surveyId),
  )
  const { data: form = {} } = useQuery(getSurveyFormQuery(survey?.formId))
  const { data: community } = useQuery(getCommunityOverviewQuery(communityId))
  const { mutate: saveSurveyMutation } = useMutation({
    mutationFn: (
      payload: SurveyResponseCreation | SurveyResponseCreationUpdateBase,
    ) => {
      return saveSurvey(payload, surveyLatestResponse?.id)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: surveyKeys.latestSurveyResponse(surveyId),
      })
    },
  })
  return (
    <div>
      <div className="relative mb-32 max-h-96 w-full md:mb-20">
        <Image
          src={community?.mainImage || ""}
          alt={community?.name || "Community main image"}
          className="max-h-96 w-full"
        />
        <h2
          className={clsx(
            "absolute left-1/2 top-1/4 -translate-x-1/2 ",
            "w-full",
            "text-center text-2xl font-semibold text-white lg:text-heading2",
          )}
        >
          {survey?.title}
        </h2>

        <div
          className={clsx(
            "flex flex-col items-center justify-between gap-3 md:flex-row",
            "absolute left-1/2 -translate-x-1/2 -translate-y-1/4 md:-translate-y-1/2",
            "mx-auto w-auto p-4 md:w-2/3",
            "border border-pearl-lighter bg-white",
            "rounded-xl",
          )}
        >
          <Countdown
            deadline={
              survey?.deadline ? new Date(survey?.deadline) : new Date()
            }
          />
          <div className="flex flex-col items-center gap-1">
            <div className="flex justify-center gap-2">
              {surveyLatestResponse?.status !== SurveyStatus.Submitted && (
                <Button
                  variant="ternary"
                  small
                  onClick={() => {
                    setSurveyStatus(SurveyStatus.Draft)
                    formRef.current?.handleSubmit()
                  }}
                >
                  <Trans id="save">Save</Trans>
                </Button>
              )}
              <Button
                small
                onClick={() => {
                  setSurveyStatus(SurveyStatus.Submitted)

                  formRef.current?.handleSubmit()
                }}
              >
                <Trans id="submit">Submit</Trans>
              </Button>
            </div>
            {surveyLatestResponse?.status === SurveyStatus.Draft && (
              <span className="text-center text-paragraph-medium font-normal text-blue">
                <Trans id="status_dots_draft">Status: Draft</Trans>
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col-reverse items-start justify-between gap-6 lg:flex-row">
        <div className="w-full rounded-xl border border-pearl-lighter bg-white p-6">
          <div className="flex flex-col gap-10 border-b border-pearl-lighter pb-7">
            <p
              className="font-light text-black"
              dangerouslySetInnerHTML={{ __html: survey?.description ?? "" }}
            ></p>
          </div>
          <div className="py-6">
            <GenericFormViewer
              ref={formRef}
              formDefinition={form}
              defaultAnswers={{
                answers: surveyLatestResponse?.answers ?? [],
              }}
              onSave={({ answers }) => {
                saveSurveyMutation({
                  status: surveyStatus,
                  surveyId,
                  answers,
                })
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
