import { createFileRoute } from "@tanstack/react-router"
import { ApplicationResponses } from "@src/components/pages/Applications/Responses"
import { ApplicationsFilters } from "@src/api/application"

const path =
  "/_auth/_community/community/$communityId/_admin-panel/admin-panel/application-definitions/$applicationId/responses" as const

export const Route = createFileRoute(path)({
  component: ApplicationResponsesPageWrapper,
  validateSearch: () => ({}) as ApplicationsFilters,
})

function ApplicationResponsesPageWrapper() {
  const { applicationId } = Route.useParams()

  const parsedApplicationId = parseInt(applicationId)

  return (
    <ApplicationResponses applicationId={parsedApplicationId} route={path} />
  )
}
