import { Component, ErrorInfo, ReactNode } from "react"
import { Trans } from "@lingui/macro"

type Props = {
  children: ReactNode
}

type State = {
  hasError: boolean
}

export class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
  }

  static getDerivedStateFromError(): State {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return (
        <h4>
          <Trans id="something_went_wrong">
            Something went wrong! Please try refreshing the page. If the error
            persists, please contact us!
          </Trans>
        </h4>
      )
    }

    return this.props.children
  }
}
