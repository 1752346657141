import { useState } from "react"
import { t } from "@lingui/macro"
import { FormAnswerDto, FormQuestionDto } from "@src/api/form"
import { SurveyType } from "@src/api/survey"
import { AnswersListContainer } from "../AnswersListContainer"
import { Switch } from "@src/components/atoms/Switch"
import { SimpleBarChart } from "@src/components/molecules/Charts/SimpleBarChart"
import { numericReportToTableAnswer } from "../utils"
import { TableAnswer } from "@src/components/molecules/Question/TableAnswer"

export type NumericAnswersReportProps = {
  question: FormQuestionDto
  answers: Array<
    FormAnswerDto & {
      projectName?: string | null
      username?: string | null
      iterationName?: string | null
    }
  >
  surveyType: SurveyType
  reportType: "respondersAggregate" | "iterationAggregateForIndividual"
}

export const NumericAnswersReport = ({
  question,
  answers,
  surveyType,
  reportType,
}: NumericAnswersReportProps) => {
  const [showResponses, setShowResponses] = useState(false)

  const answersByQuestion = answers.filter(
    (answer) => answer?.formQuestionId === question.id,
  )

  const chartsWidth = 100 + 5 * answersByQuestion.length

  const data = answersByQuestion
    .map((answer) => ({
      name:
        reportType === "iterationAggregateForIndividual"
          ? answer?.iterationName
          : surveyType === 1
            ? answer?.projectName
            : answer?.username,
      value: Number(answer.answer),
    })) // The filter typing isn't working here, so we need to cast it
    .filter(({ name }) => name !== undefined && name !== null) as {
    name: string
    value: number
  }[]

  const tableData = numericReportToTableAnswer(
    question,
    answersByQuestion,
    surveyType,
    reportType,
  )

  return (
    <AnswersListContainer className="pl-4">
      <div>
        <div className="mb-8 flex justify-end">
          <Switch
            checked={showResponses}
            label={t({
              message: "Individual responses",
              id: "individual_responses",
            })}
            onChange={(checked) => {
              setShowResponses(checked)
            }}
          />
        </div>
        {!showResponses && <SimpleBarChart data={data} width={chartsWidth} />}
        {showResponses && <TableAnswer columns={tableData} />}
      </div>
    </AnswersListContainer>
  )
}
