import { client } from "./client"
import { components } from "./schema"

export type SendGroupMessage = components["schemas"]["SendGroupUserMessageDto"]

export const sendGroupMessage = async (sendGroupMessage: SendGroupMessage) => {
  await client.POST("/api/Message/SendGroupMessageToUser", {
    body: sendGroupMessage,
  })
}
