import { useState } from "react"
import { t } from "@lingui/macro"
import {
  FormAnswerDto,
  FormQuestionAnswerType,
  FormQuestionDto,
} from "@src/api/form"
import { SurveyType } from "@src/api/survey"
import { AnswersListContainer } from "../AnswersListContainer"
import { AnswerWrapper } from "@src/components/molecules/Question/AnswerWrapper"
import { TextAnswer } from "@src/components/molecules/Question/TextAnswer"
import { BasicPieChart } from "@src/components/molecules/Charts/BasicPieChart"
import { createOptionAnswersPieChartReport, groupByNameAnswers } from "../utils"
import { Switch } from "@src/components/atoms/Switch"

export type OptionsChoiceAnswersReportProps = {
  question: FormQuestionDto
  answers: Array<
    FormAnswerDto & {
      projectName?: string | null
      username?: string | null
      iterationName?: string | null
    }
  >
  surveyType: SurveyType
  reportType: "respondersAggregate" | "iterationAggregateForIndividual"
}

/* This component covers the reporting of answers for checkbox, radio, dropdown, multiple choice
and scale questions */
export const OptionsChoiceAnswersReport = ({
  question,
  answers,
  surveyType,
  reportType,
}: OptionsChoiceAnswersReportProps) => {
  const [showResponses, setShowResponses] = useState(false)

  const answersByQuestion = answers.filter(
    (answer) => answer?.formQuestionId === question.id,
  )

  const data = createOptionAnswersPieChartReport(question, answersByQuestion)

  const groupAnswersBy =
    reportType === "iterationAggregateForIndividual"
      ? "iterationName"
      : surveyType === 1
        ? "projectName"
        : "username"

  const answersWithOptionText = answersByQuestion.map((answer) => {
    const option = question?.questionOptions?.find(
      (option) => option.questionOptionOrder === answer.optionAnswerOrder,
    )

    // Scale question options don't have text, so we use the order number
    const answerText =
      question.answerType === FormQuestionAnswerType.ScaleAnswer
        ? `${answer.optionAnswerOrder}`
        : option?.questionOption || ""

    return {
      ...answer,
      answer: answerText,
    }
  })

  const groupedByNameAnswers = groupByNameAnswers(
    groupAnswersBy,
    answersWithOptionText,
  )

  const icon =
    reportType === "iterationAggregateForIndividual"
      ? "event_repeat"
      : surveyType === 1
        ? "groups"
        : "person"

  return (
    <div>
      <AnswersListContainer className="pb-4">
        <div className="mb-4 flex justify-end">
          <Switch
            checked={showResponses}
            label={t({
              message: "Individual responses",
              id: "individual_responses",
            })}
            onChange={(checked) => {
              setShowResponses(checked)
            }}
          />
        </div>
        {!showResponses && <BasicPieChart data={data} />}
        {showResponses && (
          <div>
            {Object.keys(groupedByNameAnswers).map((name) => {
              return (
                <AnswerWrapper
                  key={name}
                  title={name}
                  className="mb-4"
                  icon={icon}
                >
                  {groupedByNameAnswers[name].map((answer) => (
                    <TextAnswer key={answer.id} answer={answer.answer} />
                  ))}
                </AnswerWrapper>
              )
            })}
          </div>
        )}
      </AnswersListContainer>
    </div>
  )
}
