import { useState } from "react"
import { useNavigate } from "@tanstack/react-router"
import { t } from "@lingui/macro"

import { Drawer } from "@src/components/organisms/Drawer"
import { MenuItem } from "@src/components/organisms/Menu/Common/MenuItem"
import { UserProfile } from "@src/components/molecules/UserProfile"
import { Icon } from "@src/components/atoms/Icon"
import { MobileItem } from "./MobileItem"
import { useMenus, MenuItemType } from "@src/hooks/useMenus"

type MobileProps = {
  isAdminPanel: boolean
}

export const MobileMenu = ({ isAdminPanel }: MobileProps) => {
  const { communityMenu, adminMenu } = useMenus()
  const navigate = useNavigate()
  const [adminOpen, setAdminOpen] = useState<boolean>(false)
  const [communityOpen, setCommunityOpen] = useState<boolean>(false)

  return (
    <nav className="fixed bottom-0 left-0 z-20 w-full bg-white lg:hidden">
      <ul className="flex items-center justify-between">
        <MobileItem onClick={() => navigate({ to: "/" })}>
          <Icon size="large" icon="home" />
        </MobileItem>
        <MobileItem>
          <Icon size="large" icon="lightbulb" />
        </MobileItem>
        <MobileItem onClick={() => setCommunityOpen(true)}>
          <Icon size="large" icon="grid_view" />
        </MobileItem>
        {isAdminPanel && (
          <MobileItem onClick={() => setAdminOpen(true)}>
            <Icon size="large" icon="settings" />
          </MobileItem>
        )}
        <MobileItem onClick={() => { }}>
          <UserProfile className="h-[26px] w-[26px]" />
        </MobileItem>
      </ul>

      <Drawer
        title={t({
          message: "Admin Panel",
          id: "community_menu_admin_panel",
        })}
        open={adminOpen}
        closeDrawer={() => setAdminOpen(false)}
      >
        <div className="py-4">
          {adminMenu.map(({ name, link, icon, children }: MenuItemType) => (
            <MenuItem
              key={name}
              name={name}
              link={link}
              icon={icon}
              children={children}
              className="p-4 my-1"
            />
          ))}
        </div>
      </Drawer>

      <Drawer
        title={t({
          message: "Navigation",
          id: "navigation",
        })}
        open={communityOpen}
        closeDrawer={() => setCommunityOpen(false)}
      >
        <div className="py-4">
          {communityMenu.map(({ name, link, icon }: MenuItemType) => (
            <MenuItem
              key={name}
              name={name}
              link={link}
              icon={icon}
              className="p-4 my-1"
            />
          ))}
        </div>
      </Drawer>
    </nav>
  )
}
