import { useMemo, useState } from "react"
import { t } from "@lingui/macro"
import { useQuery } from "@tanstack/react-query"

import { getCommunityCirclesQuery } from "@src/api/circle"
import { MultiSelect } from "@src/components/atoms/MultiSelect"
import { useCommunityId } from "@src/hooks/useCommunityId"
import { Privacy } from "@src/api/privacy"
import { Select } from "@src/components/atoms/Select"

export type PrivacySelectorProps = {
  privacyLevel: Privacy
  circleIds: number[]
  onChange: (values: { circleIds?: number[]; privacyLevel?: Privacy }) => void
}

export const QuestionPrivacy = ({
  privacyLevel,
  circleIds,
  onChange,
}: PrivacySelectorProps) => {
  const [selectedCircleIds, setSelectedCircleIds] =
    useState<number[]>(circleIds)
  const communityId = useCommunityId()

  const { data: communityCircles = [] } = useQuery(
    getCommunityCirclesQuery(communityId),
  )

  const circleIdsOptions = communityCircles?.map(({ id, name }) => ({
    id: id || 0,
    label: name || "",
  }))

  const circleIdsValue = useMemo(() => {
    return communityCircles
      .filter(
        ({ id }) =>
          id !== undefined &&
          selectedCircleIds.find((circleId) => circleId === id),
      )
      .map(({ id, name }) => ({
        id: id || 0,
        label:
          name ||
          t({
            message: "Unnamed Circle",
            id: "unnamed_circle",
          }),
      }))
  }, [communityCircles, selectedCircleIds])

  const privacyOptions = [
    {
      id: Privacy.Public,
      label: t({
        message: "Public",
        id: "public",
      }),
    },
    {
      id: Privacy.Custom,
      label: t({
        message: "Custom",
        id: "custom",
      }),
    },
  ]

  return (
    <div className="flex gap-2">
      <Select
        placeholder={t({
          message: "Please select privacy",
          id: "global_dropdown_select_privacy",
        })}
        options={privacyOptions}
        onSelect={({ id }) => {
          onChange({ privacyLevel: id as Privacy })
        }}
        value={
          privacyOptions.find((option) => option.id === privacyLevel) || null
        }
      />
      {privacyLevel === Privacy.Custom && (
        <MultiSelect
          value={circleIdsValue}
          placeholder={t({
            message: "Select roles",
            id: "global_dropdown_select_circles",
          })}
          options={circleIdsOptions}
          onSelect={(values) => {
            setSelectedCircleIds(values.map((value) => value.id))
          }}
        />
      )}
    </div>
  )
}
