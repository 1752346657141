import { t } from "@lingui/macro"
import { useLocation, Link } from "@tanstack/react-router"
import { useQuery } from "@tanstack/react-query"

import { getCommunityOverviewQuery } from "@src/api/community"
import { Icon } from "@src/components/atoms/Icon"
import { useCommunityId } from "@src/hooks/useCommunityId"

type CommunityBreadcrumbsProps = {
  isAdminPanel: boolean
}

export const CommunityBreadcrumbs = ({
  isAdminPanel,
}: CommunityBreadcrumbsProps) => {
  const translatedItems = {
    "circle-management": t({
      id: "circle_management",
      message: "Circle Management",
    }),
    "community-settings": t({
      message: "Community Settings",
      id: "community_settings_title",
    }),
    forms: t({
      message: "Forms",
      id: "forms",
    }),
    "requests-status": t({
      message: "Coaching",
      id: "coaching",
    }),
    forum: t({
      message: "Forum",
      id: "community_menu_forum_option",
    }),
    surveys: t({
      message: "Surveys",
      id: "surveys",
    }),
    "application-definitions": t({
      message: "Applications",
      id: "sidebar_applications",
    }),
    programs: t({
      message: "{201}",
      id: "programs",
    }),
    pages: t({
      message: "Pages",
      id: "pages",
    }),
  }

  const communityId = useCommunityId()

  const location = useLocation()
  const pathParts = location.pathname.split("/")
  const lastPart = pathParts[isAdminPanel ? 4 : 3]

  const { data: community } = useQuery(getCommunityOverviewQuery(communityId))

  const breadcrumb = [
    {
      text: community?.name,
      link: `/community/${community?.id}`,
      shouldShrink: true,
    },
    ...(isAdminPanel
      ? [
          {
            text: t({
              message: "Admin Panel",
              id: "community_menu_admin_panel",
            }),
            link: `/community/${community?.id}/admin-panel/community-settings`,
          },
        ]
      : []),
    { text: translatedItems[lastPart as keyof typeof translatedItems] },
  ]

  return (
    <div className="flex pb-4 lg:pb-6">
      <div className="flex items-center">
        {breadcrumb.map(({ text, link }, index) => (
          <span key={index} className="flex items-center">
            <Link
              to={link}
              className="text-paragraph-small font-semibold uppercase text-pearl-light sm:text-paragraph-medium sm:font-normal"
            >
              {text}
            </Link>
            {index < breadcrumb.length - 1 && (
              <Icon
                icon="keyboard_arrow_right"
                className="text-pearl-light"
                size="large"
              />
            )}
          </span>
        ))}
      </div>
    </div>
  )
}
