import { useMemo } from "react"
import { useForm } from "@tanstack/react-form"
import { zodValidator } from "@tanstack/zod-form-adapter"
import { t, Trans } from "@lingui/macro"
import { z } from "zod"
import { useNavigate } from "@tanstack/react-router"
import { useMutation } from "@tanstack/react-query"

import { TabForm } from "@src/api/tab"
import { useCommunityId } from "@src/hooks/useCommunityId"
import { EditorFormDto } from "@src/api/form"
import {
  GenericForm,
  ZodValidator,
} from "@src/components/organisms/GenericForm"
import { Button } from "@src/components/atoms/Button"
import { Input } from "@src/components/atoms/Input"
import { Skeleton } from "@src/components/atoms/Skeleton"
import { Switch } from "@src/components/atoms/Switch"
import { ImageUpload } from "@src/components/molecules/ImageUpload"
import { updateTabMutationFn } from "./usePageMutations"

export type PageDefinitionFormProps = {
  tab?: TabForm
}

export const PageDefinitionForm = ({ tab }: PageDefinitionFormProps) => {
  const navigate = useNavigate()
  const communityId = useCommunityId()

  const validators = {
    name: {
      onBlur: z
        .string()
        .min(
          1,
          t({
            message: "The form name must be at least 1 characters",
            id: "form_name_min_1",
          }),
        )
        .max(
          30,
          t({
            message: "The form name must be under 30 characters",
            id: "form_name_under_30",
          }),
        ),
    },
  }

  const questionsWithConditionalOrder: EditorFormDto[] | null | undefined =
    tab?.form?.questions?.map((question) => {
      if (!question?.formQuestionConditionalLogicList?.length) {
        return question
      }

      const { formQuestionConditionalLogicList } = question
      const conditionalLogicListWithOrder =
        formQuestionConditionalLogicList.map((conditionalLogic) => {
          return {
            ...conditionalLogic,
            temporaryQuestionId:
              tab?.form?.questions?.find(
                (question) =>
                  question.id === conditionalLogic.conditionFormQuestionId,
              )?.questionOrder || "",
          }
        })

      return {
        ...question,
        formQuestionConditionalLogicList: conditionalLogicListWithOrder,
      }
    })

  const formWithConditionalOrder: EditorFormDto & {
    canFollow?: boolean
    includeInLandingPage?: boolean
    icon?: {
      url: string | null | undefined
      blob: File | null | undefined
    }
    communityId?: number
  } = useMemo(
    () => ({
      ...tab,
      questions: questionsWithConditionalOrder,
      icon: {
        url: tab?.icon,
        blob: null,
      },
      communityId: communityId,
    }),
    [tab, communityId, questionsWithConditionalOrder],
  )

  const { mutate: saveTabInformationMutation } = useMutation({
    mutationFn: updateTabMutationFn,
    onSuccess: (response) => {
      if (!tab?.id) {
        navigate({
          to: "/community/$communityId/admin-panel/pages/$pageId",
          params: {
            communityId: String(communityId),
            pageId: String(response?.id),
          },
        })
      }
    },
  })

  const formInstance = useForm<
    EditorFormDto & {
      canFollow?: boolean
      includeInLandingPage?: boolean
      icon?: {
        url: string | null | undefined
        blob: File | null | undefined
      }
    },
    ZodValidator
  >({
    defaultValues: formWithConditionalOrder,
    validatorAdapter: zodValidator(),
    onSubmit: async ({ value }) => {
      saveTabInformationMutation({
        formValues: value,
        data: tab || {},
      })
    },
  })

  return formWithConditionalOrder ? (
    <form
      className="m-auto flex flex-col gap-6"
      onSubmit={(e) => {
        e.preventDefault()
        e.stopPropagation()

        formInstance.handleSubmit()
      }}
    >
      <header className="flex items-center justify-end gap-2">
        <Button
          variant="secondary"
          small
          onClick={() => {
            navigate({
              to: "/community/$communityId/admin-panel/pages",
              params: {
                communityId: String(communityId),
              },
            })
          }}
        >
          <Trans id="cancel">Cancel</Trans>
        </Button>
        <formInstance.Subscribe
          selector={(state) => [state.canSubmit, state.isSubmitting]}
          children={([canSubmit, isSubmitting]) => (
            <Button small type="submit" disabled={!canSubmit || isSubmitting}>
              <Trans id="save_changes">Save Changes</Trans>
            </Button>
          )}
        />
      </header>
      <section className="w-full rounded-xl border border-pearl-lighter bg-white p-6">
        <formInstance.Field
          name="name"
          validators={validators.name}
          children={(field) => (
            <Input
              name="name"
              placeholder={t({
                message: "Name",
                id: "name",
              })}
              error={field.state.meta.errors.join(", ")}
              variant="big"
              onChange={(value) => field.handleChange(value as string)}
              value={field.state.value}
              onBlur={field.handleBlur}
            />
          )}
        />
        <div className="my-4 h-[1px] w-full bg-pearl-lighter"></div>
        <formInstance.Field
          name="canFollow"
          children={(field) => (
            <Switch
              checked={field.state.value ?? false}
              label={t({
                message: "Should users be able to follow the page?",
                id: "tab_follow_toggle_label",
              })}
              onChange={(value) => {
                field.handleChange(value)
              }}
            />
          )}
        />
        <div className="my-4 h-[1px] w-full bg-pearl-lighter"></div>

        <formInstance.Field
          name="includeInLandingPage"
          children={(field) => (
            <Switch
              checked={field.state.value ?? false}
              label={t({
                message:
                  "Do you want this tab to be displayed in the landing page?",
                id: "tab_include_in_landing_page",
              })}
              onChange={(value) => {
                field.handleChange(value)
              }}
            />
          )}
        />

        <div className="my-4 h-[1px] w-full bg-pearl-lighter"></div>

        <formInstance.Field
          name="icon"
          children={(field) => (
            <ImageUpload
              label={t({
                message: "Logo image",
                id: "logo_image",
              })}
              description={t({
                message: "Use a square image of max 1 MB and at least 200px",
                id: "logo_image_description",
              })}
              name="icon"
              className="mt-6"
              imageContainerClassname="w-48 aspect-square"
              value={{
                url: field?.state?.value?.url,
              }}
              buttonText={t({
                message: "Upload",
                id: "upload",
              })}
              onChange={(value) => {
                field.handleChange({
                  blob: value?.blob,
                  url: null,
                })
              }}
            />
          )}
        />

        <div className="my-4 h-[1px] w-full bg-pearl-lighter"></div>

        <GenericForm form={formInstance} privacySelector />
      </section>
    </form>
  ) : (
    <Skeleton className="h-[100vh]" />
  )
}
