import { ReactNode, useState } from "react"
import { TabGroup, TabList, TabPanels, TabPanel, Tab } from "@headlessui/react"
import clsx from "clsx"

export type TabsProps = {
  tabs: { label: string; content: ReactNode }[]
  actions?: ReactNode
  tabWidth?: number
}

export const Tabs = ({ tabs, tabWidth, actions }: TabsProps) => {
  const [selectedIdx, setSelectedIdx] = useState(0)
  const [hoverIdx, setHoverIdx] = useState<number | null>(null)

  const calculatedWidth = tabWidth ? `${tabWidth}px` : `${100 / tabs.length}%`

  return (
    <div className="w-full px-2 py-4 sm:px-0">
      <TabGroup onChange={setSelectedIdx}>
        <TabList className="mb-7 flex gap-2 flex-col xl:flex-row">
          <div className="relative flex items-center flex-wrap">
            <div
              className="absolute bottom-0 h-1 bg-blue transition-transform duration-300"
              style={{
                transform: `translateX(${(hoverIdx ?? selectedIdx) * (tabWidth ?? 100 / tabs.length)}${tabWidth ? "px" : "%"})`,
                width: calculatedWidth,
              }}
            />

            {tabs.map((tab, idx) => (
              <Tab
                key={idx}
                onMouseEnter={() => setHoverIdx(idx)}
                onMouseLeave={() => setHoverIdx(null)}
                className={
                  clsx(
                    "relative px-4 py-2",
                    "border-b border-pearl-lighter",
                    "text-center text-paragraph lg:text-heading5 font-semibold text-pearl-lighter",
                    "outline-none transition-colors duration-300"
                  )
                }
                style={{ width: calculatedWidth }}
              >
                {({ selected }) => (
                  <span
                    className={clsx(
                      selected ? "text-blue" : "text-pearl-lighter",
                    )}
                  >
                    {tab.label}
                  </span>
                )}
              </Tab>
            ))}
          </div>

          {
            actions && (
              <div className="ml-auto w-full xl:w-auto">
                {actions}
              </div>
            )
          }
        </TabList >

        <TabPanels>
          {tabs.map((tab, idx) => (
            <TabPanel key={idx}>{tab.content}</TabPanel>
          ))}
        </TabPanels>
      </TabGroup >
    </div >
  )
}
