import { useMemo } from "react"
import { useForm } from "@tanstack/react-form"
import { zodValidator } from "@tanstack/zod-form-adapter"
import { t, Trans } from "@lingui/macro"
import { useNavigate } from "@tanstack/react-router"
import { FormDetailDto } from "@src/api/form"
import { useCommunityId } from "@src/hooks/useCommunityId"
import { EditorFormDto } from "@src/api/form"
import { useMutation } from "@tanstack/react-query"
import {
  GenericForm,
  ZodValidator,
} from "@src/components/organisms/GenericForm"
import { Button } from "@src/components/atoms/Button"
import { Skeleton } from "@src/components/atoms/Skeleton"
import { Switch } from "@src/components/atoms/Switch"
import { updateProjectFormMutationFn } from "./usePageMutations"

export type PageDefinitionFormProps = {
  projectForm?: FormDetailDto
}

export const PageProjectForm = ({ projectForm }: PageDefinitionFormProps) => {
  const navigate = useNavigate()
  const communityId = useCommunityId()

  const questionsWithConditionalOrder: EditorFormDto[] | null | undefined =
    projectForm?.questions?.map((question) => {
      if (!question?.formQuestionConditionalLogicList?.length) {
        return question
      }

      const { formQuestionConditionalLogicList } = question
      const conditionalLogicListWithOrder =
        formQuestionConditionalLogicList.map((conditionalLogic) => {
          return {
            ...conditionalLogic,
            temporaryQuestionId:
              projectForm?.questions?.find(
                (question) =>
                  question.id === conditionalLogic.conditionFormQuestionId,
              )?.questionOrder || "",
          }
        })

      return {
        ...question,
        formQuestionConditionalLogicList: conditionalLogicListWithOrder,
      }
    })

  const formWithConditionalOrder: FormDetailDto & {
    canFollow?: boolean
    includeInLandingPage?: boolean
    communityId?: number
  } = useMemo(
    () => ({
      ...projectForm,
      questions: questionsWithConditionalOrder,
      communityId: communityId,
    }),
    [projectForm, communityId, questionsWithConditionalOrder],
  )

  const { mutate: saveTabInformationMutation } = useMutation({
    mutationFn: updateProjectFormMutationFn,
  })

  const formInstance = useForm<
    EditorFormDto & {
      canFollow?: boolean
      includeInLandingPage?: boolean
    },
    ZodValidator
  >({
    defaultValues: formWithConditionalOrder,
    validatorAdapter: zodValidator(),
    onSubmit: async ({ value }) => {
      saveTabInformationMutation({
        formValues: value,
        communityId: communityId ?? 0,
      })
    },
  })

  return formWithConditionalOrder ? (
    <form
      className="m-auto flex flex-col gap-6"
      onSubmit={(e) => {
        e.preventDefault()
        e.stopPropagation()

        formInstance.handleSubmit()
      }}
    >
      <header className="flex items-center justify-end gap-2">
        <Button
          variant="secondary"
          small
          onClick={() => {
            navigate({
              to: "/community/$communityId/admin-panel/pages",
              params: {
                communityId: String(communityId),
              },
            })
          }}
        >
          <Trans id="cancel">Cancel</Trans>
        </Button>
        <formInstance.Subscribe
          selector={(state) => [state.canSubmit, state.isSubmitting]}
          children={([canSubmit, isSubmitting]) => (
            <Button small type="submit" disabled={!canSubmit || isSubmitting}>
              <Trans id="save_changes">Save Changes</Trans>
            </Button>
          )}
        />
      </header>
      <section className="w-full rounded-xl border border-pearl-lighter bg-white p-6">
        <formInstance.Field
          name="includeInLandingPage"
          children={(field) => (
            <Switch
              checked={field.state.value ?? false}
              label={t({
                message:
                  "Do you want this tab to be displayed in the landing page?",
                id: "tab_include_in_landing_page",
              })}
              onChange={(value) => {
                field.handleChange(value)
              }}
            />
          )}
        />

        <div className="my-4 h-[1px] w-full bg-pearl-lighter"></div>

        <GenericForm form={formInstance} privacySelector />
      </section>
    </form>
  ) : (
    <Skeleton className="h-[100vh]" />
  )
}
