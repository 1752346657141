import { t, Trans } from "@lingui/macro"

import { Input } from "@src/components/atoms/Input"
import { Button } from "@src/components/atoms/Button"
import { Select } from "@src/components/atoms/Select"
import { IconButton } from "@src/components/atoms/IconButton"
import { UserSelector } from "@src/components/organisms/UserSelector"

import { SectionProps } from "./types"

const booleanOptions = [
  {
    id: 0,
    label: "No",
  },
  {
    id: 1,
    label: "Yes",
  },
]

export const EvaluationSection = ({ form }: SectionProps) => {
  return (
    <section className="flex w-full flex-col gap-6 rounded-xl border border-pearl-lighter bg-white p-6">
      <h5 className="font-medium">
        <Trans id="evaluation">Evaluation</Trans>
      </h5>

      <form.Field name="evaluationRequired">
        {(field) => (
          <Select
            error={field.state.meta.errors.join(", ")}
            label={t({
              message: "Evaluate the application",
              id: "evaluate_the_application",
            })}
            placeholder={t({
              message: "Please select a value",
              id: "select_a_value",
            })}
            required
            className="lg:w-1/2"
            options={booleanOptions}
            onSelect={({ id }) => {
              field.handleChange(id === 0 ? false : true)
            }}
            value={
              booleanOptions.find(({ id }) =>
                field.state.value ? id === 1 : id === 0,
              ) || null
            }
            onBlur={field.handleBlur}
          />
        )}
      </form.Field>

      <form.Subscribe
        selector={(state) => ({
          evaluationRequired: state.values.evaluationRequired,
        })}
        children={({ evaluationRequired }) =>
          evaluationRequired && (
            <>
              <form.Field
                name="applicationDefinitionCriterias"
                mode="array"
                children={(field) => {
                  return (
                    <div className="flex flex-wrap items-center gap-3">
                      {field.state.value?.map((_, index) => (
                        <div key={index} className="relative flex gap-2">
                          <form.Field
                            name={`applicationDefinitionCriterias[${index}].evaluationCriteria`}
                            children={(field) => (
                              <Input
                                name={`applicationDefinitionCriterias[${index}]`}
                                variant="primary"
                                small
                                placeholder={t({
                                  message: "Criteria",
                                  id: "criteria",
                                })}
                                onChange={(value) => {
                                  field.handleChange(value as string)
                                }}
                                value={(field.state.value as string) || ""}
                                onBlur={field.handleBlur}
                              />
                            )}
                          />

                          <IconButton
                            icon="delete"
                            size="large"
                            variant="text"
                            className="text-pearl-dark"
                            onClick={() => {
                              form.removeFieldValue(
                                "applicationDefinitionCriterias",
                                index,
                              )
                            }}
                          />
                        </div>
                      ))}
                      <Button
                        variant="text"
                        small
                        className="self-start"
                        onClick={() => {
                          form.setFieldValue("applicationDefinitionCriterias", [
                            ...(field.state.value || []),
                            { evaluationCriteria: "" },
                          ])
                        }}
                      >
                        <Trans id="add_criteria">Add criteria</Trans>
                      </Button>
                    </div>
                  )
                }}
              />
              <form.Field
                name="applicationDefinitionEvaluators"
                children={(field) => {
                  return (
                    <UserSelector
                      userIds={
                        field.state.value?.map(({ userId }) => userId || 0) ||
                        []
                      }
                      onChange={(userIds) => {
                        form.setFieldValue(
                          "applicationDefinitionEvaluators",
                          userIds.map((userId) => ({ userId })),
                        )
                      }}
                    />
                  )
                }}
              />
            </>
          )
        }
      />
    </section>
  )
}
