import { useQuery } from "@tanstack/react-query"
import {
  getSurveyResponseQuery,
  getSurveyDefinitionQuery,
  SurveyType,
} from "@src/api/survey"
import { getSurveyFormQuery } from '@src/api/surveyForm'
import { getUserProfileQuery } from "@src/api/user"
import { getProjectOverviewQuery } from "@src/api/project"
import { Answers } from "@src/components/organisms/Answers"
import { PageContainer } from "@src/components/atoms/PageContainer"
import { Skeleton } from "@src/components/atoms/Skeleton"
import { ResponseHeaderProject } from "@src/components/molecules/ResponseHeader/ProjectHeader"

export type SurveyResponsePageProps = {
  surveyId: number
  responseId: number
}

export const SurveyResponsePage = ({
  surveyId,
  responseId,
}: SurveyResponsePageProps) => {
  const { data: surveyDefinition, isLoading: definitionLoading } = useQuery(
    getSurveyDefinitionQuery(surveyId),
  )

  const { data: form = {} } = useQuery(getSurveyFormQuery(surveyDefinition?.formId))

  const { data: surveyResponse, isLoading: responseLoading } = useQuery(
    getSurveyResponseQuery(responseId),
  )

  const { isLoading: profileLoading } = useQuery(
    getUserProfileQuery(surveyResponse?.userId),
  )

  const { data: projectOverview, isLoading: projectLoading } = useQuery(
    getProjectOverviewQuery(surveyResponse?.projectId ?? 0),
  )

  if (!form || !surveyResponse) {
    return <div>Error</div>
  }

  console.log(surveyDefinition, surveyResponse)

  if (
    definitionLoading ||
    responseLoading ||
    profileLoading ||
    projectLoading
  ) {
    return (
      <div>
        <Skeleton className="h-[200px]" />
        <Skeleton className="h-[100vh]" />
      </div>
    )
  }

  return (
    <div>
      {projectOverview &&
        surveyDefinition?.surveyType === SurveyType.Startup && (
          <ResponseHeaderProject
            city={projectOverview.city}
            countryName={projectOverview.countryName}
            name={projectOverview.name}
            sdgIds={projectOverview?.projectSustainableDevelopmentGoals
              ?.map(
                ({ sustainableDevelopmentGoalId }) =>
                  sustainableDevelopmentGoalId,
              )
              .filter((id) => id !== undefined)}
            categoryName={projectOverview.categoryName}
          />
        )}
      <PageContainer>
        <Answers
          form={form}
          answers={surveyResponse.answers || []}
        />
      </PageContainer>
    </div>
  )
}
