import { exportToCsv } from "@src/api/request"
import { OptionProps } from "@src/components/atoms/Fieldset/common"
import { RequestsItem } from "@src/components/pages/Coaching/index.tsx"
import { octetStreamToCsvDownload } from "@src/utils/file"

export enum RequestsStatusEnum {
  Open = 0,
  Assigned = 1,
  Closed = 2,
  All = 3,
}

export enum RequestsTypeEnum {
  All = 1,
  Coaching = 2,
  Request = 3,
}

export enum RequestsCsatEnum {
  All = 0,
  OneOutOf = 1,
  TwoOutOf = 2,
  ThreeOutOf = 3,
  FourOutOf = 4,
  FiveOutOf = 5,
}

export type CoachingRequestsFilters = {
  sessionTypeFilter?: RequestsTypeEnum
  statusFilter?: RequestsStatusEnum
  guestScoreFilter?: RequestsCsatEnum
  creatorScoreFilter?: RequestsCsatEnum
  sessionNameFilter?: string
  startupNameFilter?: string
  attendeesFilter?: string
  creationDateFilter?: string
}

export const getRequestsStatus = (status: number) => {
  switch (status) {
    case RequestsStatusEnum.Open:
      return "Open"
    case RequestsStatusEnum.Assigned:
      return "Assigned"
    case RequestsStatusEnum.Closed:
      return "Closed"
    default:
      return ""
  }
}

export const getSessionType = (type: number) => {
  switch (type) {
    case RequestsTypeEnum.Request:
      return "Request"
    case RequestsTypeEnum.Coaching:
      return "Coaching"
    default:
      return "All"
  }
}

export const handleDownloadCsv = async (communityId: number) => {
  try {
    // Await the CSV content returned by exportToCsv
    const csvContent = await exportToCsv(communityId)
    octetStreamToCsvDownload(csvContent, "requests-export.csv")

    // Turn the CSV content into a Blob, create a download URL and click a hidden link
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" })
    const url = window.URL.createObjectURL(blob)

    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", "requests-export.csv")
    document.body.appendChild(link)
    link.click()

    // Cleanup
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  } catch (error) {
    console.error("Error downloading CSV", error)
  }
}

export const buildFilterValues = <K extends keyof RequestsItem>(
  requests: RequestsItem[],
  key: K,
): OptionProps<string>[] => {
  const distinctValues = Array.from(
    new Set(requests.map((req) => req[key]).filter(Boolean)),
  ) as string[]

  const dynamicOptions = distinctValues.map((value) => ({
    id: value,
    label: value,
  }))

  return [{ id: "ALL", label: "All" }, ...dynamicOptions]
}
