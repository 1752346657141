import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react"
import { IconType } from "@src/config/icons"
import { Icon } from "@src/components/atoms/Icon"
import { clsx } from "clsx"
import { styles, BaseButtonProps } from "../Button/common"
import { commonStyles } from "../Fieldset/common"

export type DropdownProps = {
  dropdownClassname?: string
  items: {
    text: string
    icon?: IconType
    iconColor?: string
    onClick: () => void
    disabled?: boolean
  }[]
} & BaseButtonProps

const dropdownStyles = [
  "absolute z-20 grid flex-1 gap-0.5",
  "my-2 p-2 max-h-[100vh]",
  "border border-pearl-lighter bg-white",
  "font-semibold text-black",
  "overflow-y-auto",
]

export const Dropdown = ({
  items,
  variant = "secondary",
  small = true,
  className,
  dropdownClassname,
  icon,
  children,
}: DropdownProps) => {
  const classes = clsx(
    className,
    styles.base,
    styles[variant],
    styles.size(small),
  )

  return (
    <Menu as="div" className="relative">
      <MenuButton className={classes}>
        {icon && <Icon size={small ? "default" : "large"} icon={icon} />}
        {children}
      </MenuButton>

      <MenuItems className={clsx(dropdownStyles, dropdownClassname)}>
        {items.map((item, index) => (
          <MenuItem
            key={index}
            as="div"
            onClick={item.onClick}
            className={clsx(
              commonStyles.option,
              item.disabled && "pointer-events-none opacity-50",
            )}
          >
            {item.icon && (
              <Icon
                size="small"
                icon={item.icon}
                className={clsx(`text-${item.iconColor}` || "")}
              />
            )}
            {item.text}
          </MenuItem>
        ))}
      </MenuItems>
    </Menu>
  )
}
