import { V1_APP_URL } from "./consts"

export const getFileNameFromPath = (path: string): string => {
  const parts = path.split("/")
  return parts[parts.length - 1]
}

export const getAbsoluteFilePath = (path: string): string => {
  if (path.startsWith("http")) return path

  if (path.startsWith("/")) {
    return `${V1_APP_URL}${path}`
  }

  return `${V1_APP_URL}/${path}`
}

export const octetStreamToCsvDownload = (stream: string, fileName: string) => {
  const blob = new Blob([stream], { type: "text/csv;charset=utf-8;" })
  const url = window.URL.createObjectURL(blob)

  const link = document.createElement("a")
  link.href = url
  link.setAttribute("download", `${fileName}.csv`)
  document.body.appendChild(link)
  link.click()

  // Cleanup
  document.body.removeChild(link)
  URL.revokeObjectURL(url)
}
