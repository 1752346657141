import { V1_APP_URL } from "./consts"

export const getFullImageUrl = (url: string) => {
  if (url.startsWith("http")) return url

  if (url.startsWith("/Images")) {
    return `${V1_APP_URL}${url}`
  }

  if (url.startsWith("Images")) {
    return `${V1_APP_URL}/${url}`
  }

  if (url.startsWith("\\Images")) {
    return `${V1_APP_URL}/${url}`
  }

  if (url.startsWith("/static")) {
    return `/src/${url}`
  }

  return url
}

export const getInitials = (name: string): string => {
  if (!name) {
    return ""
  }

  const nameParts = name
    .trim()
    .split(" ")
    ?.filter((str) => str !== "")

  if (nameParts.length === 1) {
    return nameParts[0].charAt(0).toUpperCase()
  }
  return (
    nameParts[0].charAt(0).toUpperCase() + nameParts[1].charAt(0).toUpperCase()
  )
}
