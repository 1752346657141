import { t } from "@lingui/macro"
import { z } from "zod"

export const useValidators = () => ({
  name: {
    onBlur: z
      .string()
      .min(
        3,
        t({
          message: "The form name must be at least 3 characters",
          id: "form_name_min_3",
        }),
      )
      .max(
        80,
        t({
          message: "The form name must be under 80 characters",
          id: "form_name_max_80",
        }),
      ),
  },
  requiredString: {
    onBlur: z
      .string({
        required_error: t({
          message: "This field is required",
          id: "required_field",
        }),
      })
      .refine((value) => value.length >= 0),
  },
  requiredNumber: {
    onBlur: z.number({
      required_error: t({
        message: "This field is required",
        id: "required_field",
      }),
    }),
  },
  requiredDate: {
    onBlur: z.date({
      required_error: t({
        message: "This field is required",
        id: "required_field",
      }),
    }),
  },
  requiredBoolean: {
    onBlur: z.boolean({
      required_error: t({
        message: "This field is required",
        id: "required_field",
      }),
    }),
  },
})
