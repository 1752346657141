import { useMutation, useQueryClient } from "@tanstack/react-query"

import {
  MethodologySectionDto,
  MethodologyParagraphDto,
  MethodologyDto,
  renameMethodology,
  createMethodology,
  toggleMethodologyVisibility,
  saveMethodologySection,
  saveMethodologyParagraph,
  updateMethodologySectionOrder,
  updateMethodologyParagraphOrder,
  removeSection,
  removeParagraph,
  methodologiesKeys,
} from "@src/api/methodologies"
import { Privacy } from "@src/api/privacy"

export type MethodologyApiHookProps = {
  methodologySections?: MethodologySectionDto[]
  methodology?: MethodologyDto
  communityId?: number
}

type MethodologySaveRequest = {
  methodologyName?: string
  isActive?: boolean
  newMethodologySections?: MethodologySectionDto[]
}

const getChanges = ({
  original,
  updated,
}: {
  original: MethodologySectionDto[]
  updated: MethodologySectionDto[]
}): {
  sections: MethodologySectionDto[]
  paragraphs: (MethodologyParagraphDto & {
    tempSectionOrder?: number | undefined
  })[]
  sectionsOrder: { id: number; order: number }[]
  paragraphsOrder: { id: number; order: number }[]
  removedSections: MethodologySectionDto[]
  removedParagraphs: MethodologyParagraphDto[]
} => {
  const sectionChanges: MethodologySectionDto[] = []
  const paragraphChanges: (MethodologyParagraphDto & {
    tempSectionOrder?: number | undefined
  })[] = []
  const sectionsOrder: { id: number; order: number }[] = []
  const paragraphsOrder: { id: number; order: number }[] = []
  const removedSections: MethodologySectionDto[] = []
  const removedParagraphs: MethodologyParagraphDto[] = []

  updated.forEach((updatedItem) => {
    const originalItem = original.find((item) => item.id === updatedItem.id)

    if (!originalItem) {
      sectionChanges.push(updatedItem)

      updatedItem.methodologyParagraphs?.forEach((newParagraph) => {
        paragraphChanges.push({
          ...newParagraph,
          tempSectionOrder: updatedItem.order,
        })
      })
    } else {
      let hasSectionChanges = false

      if (
        originalItem.name !== updatedItem.name ||
        originalItem.logo !== updatedItem.logo
      ) {
        hasSectionChanges = true
      }

      if (hasSectionChanges) {
        sectionChanges.push(updatedItem)
      } else if (originalItem.order !== updatedItem.order) {
        sectionsOrder.push({ id: updatedItem.id!, order: updatedItem.order! })
      }

      updatedItem.methodologyParagraphs?.forEach((updatedParagraph) => {
        const originalParagraph = originalItem.methodologyParagraphs?.find(
          (p) => p.id === updatedParagraph.id,
        )

        if (!originalParagraph) {
          paragraphChanges.push({
            ...updatedParagraph,
            tempSectionOrder: updatedItem.order,
          })
        } else {
          let hasParagraphChanges = false

          if (
            originalParagraph.name !== updatedParagraph.name ||
            originalParagraph.wikiReferences !==
              updatedParagraph.wikiReferences ||
            originalParagraph.infoText !== updatedParagraph.infoText ||
            originalParagraph.defaultContent !==
              updatedParagraph.defaultContent ||
            originalParagraph.isHillary !== updatedParagraph.isHillary ||
            originalParagraph.privacyLevel !== updatedParagraph.privacyLevel ||
            JSON.stringify(originalParagraph.methodologyContentCircleList) !==
              JSON.stringify(updatedParagraph.methodologyContentCircleList)
          ) {
            hasParagraphChanges = true
          }

          if (hasParagraphChanges) {
            paragraphChanges.push(updatedParagraph)
          } else if (originalParagraph.order !== updatedParagraph.order) {
            paragraphsOrder.push({
              id: updatedParagraph.id!,
              order: updatedParagraph.order!,
            })
          }
        }
      })
    }
  })

  original.forEach((originalItem) => {
    if (!updated.find((item) => item.id === originalItem.id)) {
      removedSections.push(originalItem)
    } else {
      originalItem.methodologyParagraphs?.forEach((originalParagraph) => {
        const updatedSection = updated.find(
          (item) => item.id === originalItem.id,
        )
        if (
          updatedSection &&
          !updatedSection.methodologyParagraphs?.find(
            (p) => p.id === originalParagraph.id,
          )
        ) {
          removedParagraphs.push(originalParagraph)
        }
      })
    }
  })

  return {
    sections: sectionChanges,
    paragraphs: paragraphChanges,
    sectionsOrder,
    paragraphsOrder,
    removedSections,
    removedParagraphs,
  }
}

export const useMethodologyApi = ({
  methodologySections,
  methodology,
  communityId,
}: MethodologyApiHookProps) => {
  const queryClient = useQueryClient()

  const { mutateAsync: renameMethodologyMutation, isPending: isPendingRename } =
    useMutation({
      mutationFn: (methodologyName: string) => {
        return renameMethodology(methodology?.id || 0, methodologyName)
      },
    })

  const { mutateAsync: createMethodologyMutation, isPending: isPendingCreate } =
    useMutation({
      mutationFn: ({
        methodologyName,
        isActive,
      }: {
        methodologyName: string
        isActive: boolean
      }) => {
        return createMethodology(methodologyName, isActive, communityId ?? 0)
      },
    })

  const {
    mutate: toggleMethodologyVisibilityMutation,
    isPending: isPendingVisibility,
  } = useMutation({
    mutationFn: ({
      methodologyId,
      enable,
    }: {
      methodologyId: number
      enable: boolean
    }) => {
      return toggleMethodologyVisibility(methodologyId, enable)
    },
  })

  const {
    mutateAsync: saveMethodologySectionMutation,
    isPending: isPendingSaveSection,
  } = useMutation({
    mutationFn: (methodologySection: MethodologySectionDto) => {
      return saveMethodologySection(methodologySection)
    },
  })

  const {
    mutateAsync: saveMethodologyParagraphMutation,
    isPending: isPendingSaveParagraph,
  } = useMutation({
    mutationFn: (methodologyParagraph: MethodologyParagraphDto) => {
      return saveMethodologyParagraph(methodologyParagraph)
    },
  })

  const {
    mutateAsync: updateMethodologySectionOrderMutation,
    isPending: isPendingUpdateSectionOrder,
  } = useMutation({
    mutationFn: (sections: { id: number; order: number }[]) => {
      return updateMethodologySectionOrder(methodology?.id ?? 0, sections)
    },
  })

  const {
    mutateAsync: updateMethodologyParagraphOrderMutation,
    isPending: isPendingUpdateParagraphOrder,
  } = useMutation({
    mutationFn: (paragraphs: { id: number; order: number }[]) => {
      return updateMethodologyParagraphOrder(methodology?.id ?? 0, paragraphs)
    },
  })

  const {
    mutateAsync: removeSectionMutation,
    isPending: isPendingRemoveSection,
  } = useMutation({
    mutationFn: (sectionId: number) => {
      return removeSection(sectionId)
    },
  })

  const {
    mutateAsync: removeParagraphMutation,
    isPending: isPendingRemoveParagraph,
  } = useMutation({
    mutationFn: (paragraphId: number) => {
      return removeParagraph(paragraphId)
    },
  })

  const hasChanges = ({
    methodologyName,
    isActive,
    newMethodologySections,
  }: MethodologySaveRequest) => {
    const {
      sections,
      paragraphs,
      sectionsOrder,
      paragraphsOrder,
      removedParagraphs,
      removedSections,
    } = getChanges({
      original: methodologySections || [],
      updated: newMethodologySections || [],
    })

    if (
      methodology?.name !== methodologyName ||
      methodology?.isActive !== isActive ||
      sections.length ||
      paragraphs.length ||
      sectionsOrder.length ||
      paragraphsOrder.length ||
      removedParagraphs.length ||
      removedSections.length
    ) {
      return true
    }

    return false
  }

  const saveMethodology = async ({
    methodologyName = "",
    isActive = false,
    newMethodologySections,
  }: MethodologySaveRequest) => {
    let newMethodologyId = 0
    if (!methodology) {
      const response = await createMethodologyMutation({
        methodologyName,
        isActive,
      })
      newMethodologyId = response?.id
    } else if (methodology?.name !== methodologyName) {
      await renameMethodologyMutation(methodologyName)
    } else if (methodology?.isActive !== isActive) {
      toggleMethodologyVisibilityMutation({
        methodologyId: methodology?.id || 0,
        enable: isActive,
      })
    }

    const {
      sections,
      paragraphs,
      sectionsOrder,
      paragraphsOrder,
      removedParagraphs,
      removedSections,
    } = getChanges({
      original: methodologySections || [],
      updated: newMethodologySections || [],
    })

    const updatedSections = sections.map((section) => ({
      ...section,
      methodologyId: newMethodologyId || methodology?.id,
    }))
    const newSections = await Promise.all(
      updatedSections.map(
        async (section) => await saveMethodologySectionMutation(section),
      ),
    )

    const updatedParagraphs = paragraphs.map((paragraph) => {
      const { tempSectionOrder, privacyLevel, ...rest } = paragraph
      if (tempSectionOrder !== undefined) {
        return {
          ...rest,
          methodologyId: newMethodologyId || methodology?.id,
          methodologySectionId: newSections.find(
            (section) => section.order === tempSectionOrder,
          )?.id,
          teamCanChoosePrivacy: privacyLevel === Privacy.TeamCanChoose,
        }
      }
      return rest
    })

    await Promise.all(
      updatedParagraphs.map(
        async (updatedParagraphs) =>
          await saveMethodologyParagraphMutation(updatedParagraphs),
      ),
    )

    if (sectionsOrder.length) {
      await updateMethodologySectionOrderMutation(sectionsOrder)
    }

    if (paragraphsOrder.length) {
      await updateMethodologyParagraphOrderMutation(paragraphsOrder)
    }

    if (removedSections.length) {
      await Promise.all(
        removedSections.map(
          async (section) => await removeSectionMutation(section?.id ?? 0),
        ),
      )
    }

    if (removedParagraphs.length) {
      await Promise.all(
        removedParagraphs.map(
          async (paragraph) =>
            await removeParagraphMutation(paragraph?.id ?? 0),
        ),
      )
    }

    queryClient.invalidateQueries({
      queryKey: methodologiesKeys.data(methodology?.id || 0),
    })

    queryClient.invalidateQueries({
      queryKey: methodologiesKeys.content(methodology?.id || 0),
    })

    return newMethodologyId || methodology?.id
  }

  return {
    saveMethodology,
    hasChanges,
    isLoading:
      isPendingCreate ||
      isPendingRename ||
      isPendingVisibility ||
      isPendingSaveSection ||
      isPendingSaveParagraph ||
      isPendingUpdateSectionOrder ||
      isPendingUpdateParagraphOrder ||
      isPendingRemoveSection ||
      isPendingRemoveParagraph,
  }
}
