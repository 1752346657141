import { getRouteApi } from "@tanstack/react-router"
import { RoutesWithFilters } from "."
import { ApiFilters } from "@src/api/filters"
import { cleanPagination } from "./utils"

export type Pagination<T extends ApiFilters> = {
  defaultValues: {
    take: number
    skip: number
    orderBy?: T["orderBy"]
  }
  route: RoutesWithFilters
}

// Hook for managing API pagination by using the search params as the state and
// only source of truth
export const usePagination = <T extends ApiFilters>({
  defaultValues,
  route,
}: Pagination<T>) => {
  const routeApi = getRouteApi(route)
  const filters: T = routeApi.useSearch()
  const navigate = routeApi.useNavigate()

  const setPagination = (
    pagination: Partial<Pick<T, "take" | "skip" | "orderBy">>,
  ) => {
    navigate({
      search: () => {
        return cleanPagination(
          {
            ...filters,
            ...pagination,
          },
          defaultValues,
        )
      },
    })
  }

  return {
    take: filters.take !== undefined ? filters.take : defaultValues.take,
    skip: filters.skip !== undefined ? filters.skip : defaultValues.skip,
    orderBy:
      filters.orderBy !== undefined ? filters.orderBy : defaultValues.orderBy,
    setPagination,
  } as {
    take: number
    skip: number
    orderBy: T["orderBy"]
    setPagination: (
      pagination: Partial<Pick<T, "take" | "skip" | "orderBy">>,
    ) => void
  }
}
