import { FormQuestionAnswerType } from "@src/api/form"

import { ShortTextAnswer } from "./ShortTextAnswer"
import { NumericAnswer } from "./NumericAnswer"
import { MoneyAnswer } from "./MoneyAnswer"
import { LongTextAnswer } from "./LongTextAnswer"
import { CheckboxAnswer } from "./CheckboxAnswer"
import { DropdownAnswer } from "./DropdownAnswer"
import { RadioAnswer } from "./RadioAnswer"
import { CountryAnswer } from "./CountryAnswer"
import { FileAnswer } from "./FileAnswer"
import { TableAnswer } from "./TableAnswer"
import { CalendarAnswer } from "./CalendarAnswer"
import { ScaleAnswer } from "./ScaleAnswer"
import { GenericAnswerProps } from "./types"
import { validateConditionalRules, filterAnswersByConditional } from "./utils"

export const GenericFormViewerQuestion = ({
  form,
  question,
  questions,
}: GenericAnswerProps) => {
  return (
    <form.Subscribe
      selector={(state) => [state.values.answers]}
      children={([answers]) => {
        const conditionalAnswers = filterAnswersByConditional(
          Object.values(answers || []),
          questions,
          question.formQuestionConditionalLogicList ?? [],
        )

        const conditionsMet = validateConditionalRules(
          conditionalAnswers,
          question?.formQuestionConditionalLogicList ?? [],
          question.conditionalLogicType,
        )

        if (!conditionsMet) {
          return null
        }

        if (question.answerType === FormQuestionAnswerType.ShortTextAnswer) {
          return <ShortTextAnswer form={form} question={question} />
        }

        if (question.answerType === FormQuestionAnswerType.NumericAnswer) {
          return <NumericAnswer form={form} question={question} />
        }

        if (question.answerType === FormQuestionAnswerType.LongTextAnswer) {
          return <LongTextAnswer form={form} question={question} />
        }

        if (question.answerType === FormQuestionAnswerType.CountryAnswer) {
          return <CountryAnswer form={form} question={question} />
        }

        if (question.answerType === FormQuestionAnswerType.AccountMoneyAnswer) {
          return <MoneyAnswer form={form} question={question} />
        }

        if (question.answerType === FormQuestionAnswerType.CalendarAnswer) {
          return <CalendarAnswer form={form} question={question} />
        }

        if (question.answerType === FormQuestionAnswerType.FileAnswer) {
          return <FileAnswer form={form} question={question} />
        }

        if (question.answerType === FormQuestionAnswerType.TableAnswer) {
          return <TableAnswer form={form} question={question} />
        }

        if (question.answerType === FormQuestionAnswerType.ScaleAnswer) {
          return <ScaleAnswer form={form} question={question} />
        }

        if (
          question.answerType === FormQuestionAnswerType.MultipleChoiceAnswer ||
          question.answerType === FormQuestionAnswerType.CheckBoxChoiceAnswer
        ) {
          return <CheckboxAnswer form={form} question={question} />
        }

        if (
          question.answerType === FormQuestionAnswerType.DropDownChoiceAnswer
        ) {
          return <DropdownAnswer form={form} question={question} />
        }

        if (question.answerType === FormQuestionAnswerType.RadioChoiceAnswer) {
          return <RadioAnswer form={form} question={question} />
        }

        return null
      }}
    />
  )
}
