import { useEffect, useState } from "react"
import { t, Trans } from "@lingui/macro"
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from "date-fns"

export type CountdownProps = {
  deadline: Date
}

export const Countdown = ({ deadline }: CountdownProps) => {
  const now = new Date()

  const [timeLeft, setTimeLeft] = useState({
    days: differenceInDays(deadline, now),
    hours: differenceInHours(deadline, now) % 24,
    minutes: differenceInMinutes(deadline, now) % 60,
    seconds: differenceInMinutes(deadline, now) % 60,
  })

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date()

      const daysUntil = Math.max(differenceInDays(deadline, now), 0)
      const hoursUntil = Math.max(differenceInHours(deadline, now) % 24, 0)
      const minutesUntil = Math.max(differenceInMinutes(deadline, now) % 60, 0)
      const secondsUntil = Math.max(differenceInSeconds(deadline, now) % 60, 0)

      if (
        daysUntil <= 0 &&
        hoursUntil <= 0 &&
        minutesUntil <= 0 &&
        secondsUntil <= 0
      ) {
        clearInterval(timer)
      }

      setTimeLeft({
        days: daysUntil,
        hours: hoursUntil,
        minutes: minutesUntil,
        seconds: secondsUntil,
      })
    }, 1000)

    return () => clearInterval(timer)
  }, [deadline])

  const dates = [
    {
      id: 1,
      label: t({ message: "Days", id: "days" }),
      value: timeLeft.days,
    },
    {
      id: 2,
      label: t({ message: "Hours", id: "hours" }),
      value: timeLeft.hours,
    },
    {
      id: 3,
      label: t({ message: "Min", id: "min" }),
      value: timeLeft.minutes,
    },
    {
      id: 4,
      label: t({ message: "Sec", id: "sec" }),
      value: timeLeft.seconds,
    },
  ]

  return (
    <div className="flex flex-col items-center gap-1">
      <div className="flex gap-2">
        {dates.map(({ id, label, value }) => (
          <span
            key={id}
            className="flex size-12 flex-col gap-0.5 rounded-xl border border-pearl-lighter p-2 text-center text-blue"
          >
            <span className="text-paragraph font-bold">{value}</span>
            <span className="text-paragraph-small font-light">{label}</span>
          </span>
        ))}
      </div>
      <span className="text-center text-paragraph-medium font-normal text-blue">
        <Trans id="deadline">Deadline</Trans>:{" "}
        <span className="font-light">{deadline.toLocaleDateString()}</span>
      </span>
    </div>
  )
}
