import { queryOptions } from "@tanstack/react-query"
import { client } from "./client"
import { safeQueryOptions } from "@src/utils/query"
import {
  UnpackApiListPromise,
  UnpackPromise,
  UnpackArray,
} from "@src/utils/types"
import { components } from "./schema"

export enum ApplicationStatus {
  Apply = 0,
  Draft = 1,
  Pending = 2,
  Accepted = 3,
  Denied = 4,
}

export enum ApplicationOrderBy {
  DateNewest = 0,
  DateOldest = 1,
  HighestRating = 2,
  LowestRating = 3,
}

export const applicationKeys = {
  all: ["applications"],
  userList: () => [...applicationKeys.all, "list", "user"],
}

export const applicationDefinitionKeys = {
  all: ["applicationDefinitions"],
  communityList: (id?: number) => [
    ...applicationDefinitionKeys.all,
    "list",
    "community",
    id,
  ],
  applicationDefinition: (id?: number) => [
    ...applicationKeys.all,
    "definition",
    id,
  ],
  applicationDefinitionFilters: (id?: number) => [
    ...applicationKeys.all,
    "definition",
    id,
    "filters",
  ],
  applicationResponses: (id: number, filters: ApplicationsFilters) => [
    ...applicationKeys.all,
    "definition",
    id,
    "responses",
    filters,
  ],
}

export const applicationResponseKeys = {
  all: ["applicationResponses"],
  response: (id: number) => [...applicationResponseKeys.all, id],
}

export type CommunityApplicationDetail = UnpackApiListPromise<
  ReturnType<typeof getCommunityApplicationDefinitions>
> & { evaluationRequired?: boolean }
// @todo @Tibs lets take a look at this one

export const getCommunityApplicationDefinitions = async (id: number) => {
  const data = await client.GET("/api/Community/{id}/ApplicationDefinitions", {
    params: {
      path: { id },
    },
  })

  return data
}

export const getCommunityApplicationDefinitionsQuery = (id?: number) =>
  safeQueryOptions({
    queryFn: getCommunityApplicationDefinitions,
    queryKeys: applicationDefinitionKeys.communityList(id),
    unsafeQueryFnArgs: [id],
  })

export const getCommunityApplicationDefinition = async (id: number) => {
  const data = await client.GET("/api/ApplicationDefinition/{id}", {
    params: {
      path: { id },
    },
  })

  return data
}

export type ApplicationDefinition = UnpackPromise<
  ReturnType<typeof getCommunityApplicationDefinition>
>

export const getCommunityApplicationDefinitionQuery = (id?: number) =>
  safeQueryOptions({
    queryFn: getCommunityApplicationDefinition,
    queryKeys: applicationDefinitionKeys.applicationDefinition(id),
    unsafeQueryFnArgs: [id],
  })

export const deleteCommunityApplicationDefinition = async (id: number) => {
  const data = await client.DELETE("/api/ApplicationDefinition/{id}", {
    params: {
      path: { id },
    },
  })

  return data
}

export const saveCommunityApplicationDefinition = async (
  payload: CommunityApplicationDetail,
  id?: number,
) => {
  if (!id) {
    const data = await client.POST("/api/ApplicationDefinition", {
      body: payload,
    })

    return data
  }

  const data = await client.PUT("/api/ApplicationDefinition/{id}", {
    params: {
      path: { id },
    },
    body: payload,
  })

  return data
}

export const getCurrentUserApplications = async () => {
  const data = await client.GET("/api/Application/GetCurrentUserApplications")

  return data
}

export const getCurrentUserApplicationsQuery = () =>
  queryOptions({
    queryKey: ["currentUserApplications", "list"],
    queryFn: () => getCurrentUserApplications(),
  })

export type CurrentUserApplication = UnpackApiListPromise<
  ReturnType<typeof getCurrentUserApplications>
>

export type ApplicationsFilters = components["schemas"]["ApplicationFilter"]

export const getApplicationDefinitionResponses = async (
  id: number,
  filters: ApplicationsFilters,
) => {
  const data = await client.POST(
    "/api/ApplicationDefinition/{id}/Applications",
    {
      params: {
        path: { id },
      },
      body: filters,
    },
  )

  return data
}

export type ApplicationsResponses = UnpackPromise<
  ReturnType<typeof getApplicationDefinitionResponses>
>

export type ApplicationResponse = UnpackArray<ApplicationsResponses["list"]>

export const getApplicationDefinitionResponsesQuery = (
  applicationId: number,
  filters: ApplicationsFilters,
) =>
  queryOptions({
    queryFn: () => getApplicationDefinitionResponses(applicationId, filters),
    queryKey: applicationDefinitionKeys.applicationResponses(
      applicationId,
      filters,
    ),
  })

export const getApplicationsFilters = async (id: number) => {
  const data = await client.GET(
    "/api/ApplicationDefinition/{id}/ApplicationsFilters",
    {
      params: {
        path: { id },
      },
    },
  )

  return data
}

export type ApplicationFilterValues = UnpackPromise<
  ReturnType<typeof getApplicationsFilters>
>

export const getApplicationsFiltersQuery = (id?: number) =>
  safeQueryOptions({
    queryFn: getApplicationsFilters,
    queryKeys: applicationDefinitionKeys.applicationDefinitionFilters(id),
    unsafeQueryFnArgs: [id],
  })

export const saveApplicationTags = async (
  applicationId: number,
  tags: string[],
) => {
  await client.POST("/api/Application/{id}/SaveTags", {
    params: {
      path: { id: applicationId },
    },
    body: {
      tags,
    },
  })
}

export const acceptApplication = async (id: number, methodologyId?: number) => {
  await client.POST("/api/Application/{id}/AcceptApplication", {
    params: {
      path: { id },
      query: {
        methodologyId,
      },
    },
  })
}

export const rejectApplication = async (id: number) => {
  await client.POST("/api/Application/{id}/RejectApplication", {
    params: {
      path: { id },
    },
  })
}

export const deleteApplication = async (id: number) => {
  await client.DELETE("/api/Application/{id}/DeleteApplication", {
    params: {
      path: { id },
    },
  })
}

export const downloadApplicationDefinitionResponsesCsv = async (id: number) => {
  const data = await client.GET(
    "/api/ApplicationDefinition/{id}/Applications-csv",
    {
      params: {
        path: { id },
      },
    },
  )

  return data
}

export const downloadApplicationDefinitionEvaluationsCsv = async (
  id: number,
) => {
  const data = await client.GET(
    "/api/ApplicationDefinition/{id}/ApplicationEvaluations-csv",
    {
      params: {
        path: { id },
      },
    },
  )

  return data
}

export const getApplicationResponse = async (id: number) => {
  const data = await client.GET("/api/Application/{id}", {
    params: {
      path: { id },
    },
  })

  return data
}

export type ApplicationResponseDetails = UnpackPromise<
  ReturnType<typeof getApplicationResponse>
>

export const getApplicationResponseQuery = (id: number) => {
  return queryOptions({
    queryKey: applicationResponseKeys.response(id),
    queryFn: () => getApplicationResponse(id),
  })
}
