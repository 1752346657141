import { useSortable } from "@dnd-kit/sortable"
import { clsx } from "clsx"
import { CSS } from "@dnd-kit/utilities"
import { t, Trans } from "@lingui/macro"

import {
  MethodologySectionDto,
  MethodologyParagraphDto,
} from "@src/api/methodologies"
import { Image } from "@src/components/atoms/Image"
import { IconButton } from "@src/components/atoms/IconButton"
import { Button } from "@src/components/atoms/Button"
import { InfoContainer } from "@src/components/atoms/InfoContainer"
import { Select } from "@src/components/atoms/Select"
import { SortableList } from "@src/components/atoms/SortableList"
import { useDialogQueue } from "@src/context/DialogQueueProvider/useDialogQueue"
import { ProgramParagraphItem } from "./ProgramParagraphItem"

export type ProgramSectionItemProps = {
  methodologySection: MethodologySectionDto
  sectionIndex: number
  setParagraphPath: (params: {
    sectionIndex: number
    paragraphIndex: number
  }) => void
  updateParagraphs: (newParagraphOrder: MethodologyParagraphDto[]) => void
  removeSection: (sectionIndex: number) => void
  selectedSectionIndex: number
  selectedParagraphIndex: number
}

export const ProgramSectionItem = ({
  methodologySection,
  sectionIndex,
  setParagraphPath,
  updateParagraphs,
  removeSection,
  selectedSectionIndex,
  selectedParagraphIndex,
}: ProgramSectionItemProps) => {
  const { logo, name, methodologyParagraphs = [] } = methodologySection
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: sectionIndex,
    animateLayoutChanges: () => false,
  })
  const { enqueueDialog, closeCurrentDialog } = useDialogQueue()

  const onDragEnd = (orderedItems: { id: number }[]) => {
    const newOrder = orderedItems.map(({ id }, index) => ({
      ...methodologyParagraphs?.[id],
      order: index,
    }))
    updateParagraphs(newOrder)
  }

  const addParagraph = (isHillary = false) => {
    const newParagraph: MethodologyParagraphDto = {
      methodologySectionId: methodologySection.id,
      methodologyId: methodologySection.methodologyId,
      order: methodologyParagraphs?.length,
      name: "",
      infoText: "",
      defaultContent: "",
      wikiReferences: "",
      ...(isHillary && { isHillary }),
    }

    const updatedParagraphs = [...(methodologyParagraphs || []), newParagraph]
    updateParagraphs(updatedParagraphs)
    setParagraphPath({
      sectionIndex,
      paragraphIndex: updatedParagraphs.length - 1,
    })
  }

  const removeParagraph = (paragraphIndex: number) => {
    const updatedParagraphs =
      methodologyParagraphs?.filter((_, index) => index !== paragraphIndex) ||
      []

    if (paragraphIndex === (methodologyParagraphs?.length ?? 0) - 1) {
      setParagraphPath({
        sectionIndex,
        paragraphIndex: updatedParagraphs.length - 1,
      })
    }

    updateParagraphs(updatedParagraphs)
  }

  return (
    <div
      ref={setNodeRef}
      className={clsx(
        "flex flex-col rounded-xl border border-pearl-lighter bg-white p-2",
        isDragging && "z-20 rounded-xl bg-blue/10",
      )}
      style={{ transform: CSS.Translate.toString(transform), transition }}
    >
      <div
        className={clsx(
          "group relative flex items-center gap-2 pb-2",
          "border-b border-pearl-lighter text-paragraph",
          "cursor-pointer text-black-light hover:text-blue",
          selectedSectionIndex === sectionIndex && "text-blue",
        )}
        onClick={() => setParagraphPath({ sectionIndex, paragraphIndex: 0 })}
      >
        <IconButton
          icon="drag_indicator"
          size="largex"
          variant="text"
          className="text-pearl-dark"
          {...listeners}
          {...attributes}
        />
        <Image
          src={logo}
          className="size-8"
          alt={name || "Program section logo"}
        />
        {name}
        <IconButton
          icon="delete"
          size="largex"
          variant="text"
          className="ml-auto opacity-0 transition-opacity group-hover:opacity-100"
          onClick={() => {
            enqueueDialog({
              title: t({
                message: `Are you sure you want to delete the ${name} section?`,
                id: "delete_section_title",
              }),
              actions: (
                <div className="flex justify-end gap-2">
                  <Button
                    small
                    variant="secondary"
                    onClick={closeCurrentDialog}
                  >
                    <Trans id="cancel">Cancel</Trans>
                  </Button>

                  <Button
                    small
                    disabled={!!methodologyParagraphs?.length}
                    onClick={() => {
                      removeSection(sectionIndex)
                      closeCurrentDialog()
                    }}
                  >
                    <Trans id="delete">Delete</Trans>
                  </Button>
                </div>
              ),
              children: methodologyParagraphs?.length ? (
                <InfoContainer
                  type="warning"
                  className="w-full"
                  text={t({
                    message:
                      "You cannot delete the section with paragraphs. Please delete all paragraphs first.",
                    id: "delete_section_warning",
                  })}
                />
              ) : null,
            })
          }}
        />
      </div>

      <div className="ml-3 flex flex-col gap-2">
        <SortableList
          items={
            methodologyParagraphs?.map((paragraph, index) => ({
              id: index,
              element: (
                <ProgramParagraphItem
                  key={index}
                  onClick={() =>
                    setParagraphPath({ sectionIndex, paragraphIndex: index })
                  }
                  removeParagraph={() => removeParagraph(index)}
                  methodologyParagraph={paragraph}
                  paragraphIndex={index}
                  selectedParagraphIndex={selectedParagraphIndex}
                />
              ),
            })) ?? []
          }
          onDragEnd={onDragEnd}
          className="flex flex-col"
        />

        <Select
          placeholder={t({
            message: "Add content",
            id: "add_content",
          })}
          options={[
            {
              id: 0,
              label: t({
                message: "Activity",
                id: "activity",
              }),
            },
            {
              id: 1,
              label: t({
                message: "Learning content",
                id: "learning_content",
              }),
            },
          ]}
          onSelect={({ id }) => {
            addParagraph(id === 1 ? true : false)
          }}
        />
      </div>
    </div>
  )
}
