import { useState } from "react"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { CellContext, ColumnDef } from "@tanstack/react-table"
import { useNavigate } from "@tanstack/react-router"
import { format } from "date-fns"
import { t, Trans } from "@lingui/macro"

import {
  getSurveyDefinitionListQuery,
  deleteSurveyDefinition,
  surveyKeys,
} from "@src/api/survey"
import { Table } from "@src/components/organisms/Table"
import { useCommunityId } from "@src/hooks/useCommunityId"
import { Button } from "@src/components/atoms/Button"
// import { SearchInput } from "@src/components/atoms/SearchInput"
import { useDialogQueue } from "@src/context/DialogQueueProvider/useDialogQueue"

export interface SurveyTableItem {
  id: number | undefined
  name: string | null | undefined
  endDate: Date | undefined
}

const initialPageSize = 10
export const Surveys = () => {
  const queryClient = useQueryClient()

  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(initialPageSize)

  const communityId = useCommunityId()
  const navigate = useNavigate()

  const { enqueueDialog, closeCurrentDialog } = useDialogQueue()

  const { data: surveyDefinitions, isLoading } = useQuery(
    getSurveyDefinitionListQuery({
      communityId,
      take: pageSize,
      skip: pageIndex * pageSize,
    }),
  )

  const {
    mutate: deleteSurveyDefinitionMutation,
    isPending: isPendingDeletion,
  } = useMutation({
    mutationFn: (formId: number) => {
      return deleteSurveyDefinition(formId)
    },
    onSuccess: () => {
      closeCurrentDialog()

      queryClient.invalidateQueries({
        queryKey: surveyKeys.surveyDefinitions(
          communityId,
          pageSize,
          pageIndex,
        ),
      })
    },
  })

  const columns: ColumnDef<SurveyTableItem>[] = [
    {
      header: t({
        id: "name",
        message: "Name",
      }),
      accessorKey: "name",
      size: 500,
    },
    {
      header: t({
        id: "status",
        message: "Status",
      }),
      accessorKey: "status",
      cell: ({ row }: CellContext<SurveyTableItem, unknown>) => {
        const endDate = row.original.endDate
        if (endDate && endDate > new Date()) {
          return (
            <span className="flex items-center gap-1">
              <div className="size-2 rounded-full bg-green"></div>
              <Trans id="active_until">
                Active Until {format(endDate, "MMM yyyy")}
              </Trans>
            </span>
          )
        }

        return (
          <span className="flex items-center gap-1">
            <div className="size-2 rounded-full bg-red"></div>
            <Trans id="closed_since">
              Closed Since {format(endDate || "", "MMM yyyy")}
            </Trans>
          </span>
        )
      },
    },
    {
      header: t({
        id: "actions",
        message: "Actions",
      }),
      accessorKey: "actions",
      cell: ({ row }: CellContext<SurveyTableItem, unknown>) => {
        const id = row.original.id || 0

        return (
          <span className="flex h-4">
            <Button
              onClick={() => {
                navigator.clipboard.writeText(
                  `${window.location.origin}/community/${communityId}/surveys/${id}/complete`,
                )
              }}
              className="pl-0"
              variant="text"
              icon="content_copy"
              small
            >
              <Trans id="invite_link">Invite link</Trans>
            </Button>
            <Button
              onClick={() => {
                navigate({
                  to: "/community/$communityId/admin-panel/surveys/$surveyId/responses",
                  params: {
                    communityId: String(communityId),
                    surveyId: String(id),
                  },
                })
              }}
              className="pl-0"
              variant="text"
              icon="timeline"
              small
            >
              <Trans id="responses">Responses</Trans>
            </Button>
            <Button
              variant="text"
              icon="edit"
              className="pl-0"
              small
              onClick={() => {
                navigate({
                  to: "/community/$communityId/admin-panel/surveys/$surveyId",
                  params: {
                    communityId: String(communityId),
                    surveyId: String(id),
                  },
                  search: { duplicate: false },
                })
              }}
            >
              <Trans id="edit">Edit</Trans>
            </Button>
            <Button
              variant="text"
              icon="file_copy"
              small
              className="pl-0"
              onClick={() => {
                navigate({
                  to: "/community/$communityId/admin-panel/surveys/$surveyId",
                  params: {
                    communityId: String(communityId),
                    surveyId: String(id),
                  },
                  search: { duplicate: true },
                })
              }}
            >
              <Trans id="duplicate">Duplicate</Trans>
            </Button>
            <Button
              variant="text"
              icon="delete"
              small
              className="pl-0"
              disabled={isPendingDeletion}
              onClick={() => {
                enqueueDialog({
                  title: t({
                    id: "are_you_sure_delete_survey",
                    message: `Are you sure you want to delete the ${row.original.name} survey?`,
                  }),
                  actions: (
                    <div className="flex justify-end gap-2">
                      <Button
                        small
                        variant="secondary"
                        onClick={closeCurrentDialog}
                      >
                        <Trans id="cancel">Cancel</Trans>
                      </Button>

                      <Button
                        small
                        onClick={() => {
                          deleteSurveyDefinitionMutation(id)
                        }}
                      >
                        <Trans id="delete">Delete</Trans>
                      </Button>
                    </div>
                  ),
                })
              }}
            >
              <Trans id="delete">Delete</Trans>
            </Button>
          </span>
        )
      },
    },
  ]

  const data =
    surveyDefinitions?.list?.map(({ id, title, startDate, endDate }) => ({
      id,
      name: title,
      startDate,
      endDate: endDate ? new Date(endDate) : new Date(),
    })) || []

  return (
    <div className="flex flex-col gap-6">
      <div className="flex gap-3">
        <Button
          small
          onClick={() => {
            navigate({
              to: "/community/$communityId/admin-panel/surveys/create",
              params: {
                communityId: String(communityId),
              },
            })
          }}
        >
          <Trans id="create_new_survey">Create new survey</Trans>
        </Button>
        {/* <SearchInput
          name="search"
          value={searchValue}
          onChange={(value: string) => setSearchValue(value)}
        />*/}
      </div>
      <Table
        columns={columns}
        data={data}
        loading={isLoading}
        itemsCount={surveyDefinitions?.count}
        pageIndex={pageIndex}
        pageSize={pageSize}
        onPageChange={setPageIndex}
        onPageSizeChange={setPageSize}
      />
    </div>
  )
}
