import { t } from "@lingui/macro"

import {
  FormQuestionAnswerType,
  FormQuestionConditionLogic,
} from "@src/api/form"

export const getAnswerTypes = () => [
  {
    id: FormQuestionAnswerType.ShortTextAnswer,
    label: t({
      message: "Short text",
      id: "forms_answer_type_short_text",
    }),
    icon: "short_text",
  },
  {
    id: FormQuestionAnswerType.LongTextAnswer,
    label: t({
      message: "Long text",
      id: "forms_answer_type_long_text",
    }),
    icon: "subject",
  },
  {
    id: FormQuestionAnswerType.NumericAnswer,
    label: t({
      message: "Numeric",
      id: "forms_answer_type_numeric",
    }),
    icon: "123",
  },
  {
    id: FormQuestionAnswerType.CountryAnswer,
    label: t({
      message: "Countries",
      id: "forms_answer_type_country",
    }),
    icon: "globe",
  },
  {
    id: FormQuestionAnswerType.RadioChoiceAnswer,
    label: t({
      message: "Radio Button",
      id: "forms_answer_type_radio",
    }),
    group: "B",
    icon: "radio_button_checked",
  },
  {
    id: FormQuestionAnswerType.CheckBoxChoiceAnswer,
    label: t({
      message: "Checkbox",
      id: "forms_answer_type_checkbox",
    }),
    group: "B",
    icon: "check_box",
  },
  {
    id: FormQuestionAnswerType.MultipleChoiceAnswer,
    label: t({
      message: "Multiple choice",
      id: "forms_answer_type_multiple",
    }),
    group: "B",
    icon: "list",
  },
  {
    id: FormQuestionAnswerType.TableAnswer,
    label: t({
      message: "Table",
      id: "forms_answer_type_table",
    }),
    group: "B",
    icon: "table_rows",
  },
  {
    id: FormQuestionAnswerType.DropDownChoiceAnswer,
    label: t({
      message: "Dropdown",
      id: "forms_answer_type_dropdown",
    }),
    group: "B",
    icon: "arrow_drop_down",
  },
  {
    id: FormQuestionAnswerType.FileAnswer,
    label: t({
      message: "File upload",
      id: "forms_answer_type_file",
    }),
    group: "C",
    icon: "cloud_upload",
  },
  {
    id: FormQuestionAnswerType.ScaleAnswer,
    label: t({
      message: "Scale",
      id: "forms_answer_type_scale",
    }),
    group: "C",
    icon: "linear_scale",
  },
  {
    id: FormQuestionAnswerType.CalendarAnswer,
    label: t({
      message: "Calendar date",
      id: "forms_answer_type_calendar",
    }),
    group: "C",
    icon: "calendar_today",
  },
  {
    id: FormQuestionAnswerType.AccountMoneyAnswer,
    label: t({
      message: "Account money",
      id: "forms_answer_type_money",
    }),
    group: "C",
    icon: "euro_symbol",
  },
]

export const getConditionLogicTypes = (answerType?: FormQuestionAnswerType) => {
  const baseLogicTypes = [
    {
      id: FormQuestionConditionLogic.Equal,
      label: t({
        message: "Equals",
        id: "equals",
      }),
    },
    {
      id: FormQuestionConditionLogic.NotEqual,
      label: t({
        message: "Not equals",
        id: "not_equals",
      }),
    },
    {
      id: FormQuestionConditionLogic.IsEmpty,
      label: t({
        message: "Is empty",
        id: "is_empty",
      }),
    },
    {
      id: FormQuestionConditionLogic.NotEmpty,
      label: t({
        message: "Is not empty",
        id: "is_not_empty",
      }),
    },
  ]

  switch (answerType) {
    case FormQuestionAnswerType.AccountMoneyAnswer:
    case FormQuestionAnswerType.ScaleAnswer:
      return [
        ...baseLogicTypes,
        {
          id: FormQuestionConditionLogic.GreaterThan,
          label: t({
            message: "Greater than",
            id: "greater_than",
          }),
        },
        {
          id: FormQuestionConditionLogic.LessThan,
          label: t({
            message: "Less than",
            id: "less_than",
          }),
        },
      ]

    case FormQuestionAnswerType.LongTextAnswer:
    case FormQuestionAnswerType.ShortTextAnswer:
      return [
        ...baseLogicTypes,
        {
          id: FormQuestionConditionLogic.GreaterThan,
          label: t({
            message: "Greater than",
            id: "greater_than",
          }),
        },
        {
          id: FormQuestionConditionLogic.LessThan,
          label: t({
            message: "Less than",
            id: "less_than",
          }),
        },
        {
          id: FormQuestionConditionLogic.Contains,
          label: t({
            message: "Contains",
            id: "contains",
          }),
        },
        {
          id: FormQuestionConditionLogic.DoesNotContain,
          label: t({
            message: "Does not contain",
            id: "does_not_contain",
          }),
        },
      ]

    default:
      return baseLogicTypes
  }
}

export const hasMultipleOptions = (type?: FormQuestionAnswerType) =>
  type !== undefined
    ? [
        FormQuestionAnswerType.RadioChoiceAnswer,
        FormQuestionAnswerType.CheckBoxChoiceAnswer,
        FormQuestionAnswerType.DropDownChoiceAnswer,
        FormQuestionAnswerType.MultipleChoiceAnswer,
        FormQuestionAnswerType.TableAnswer,
        FormQuestionAnswerType.DropDownChoiceAnswer,
      ].includes(type)
    : false

export const getOptionPlaceholder = (type?: FormQuestionAnswerType) => {
  switch (type) {
    case FormQuestionAnswerType.RadioChoiceAnswer:
      return t({
        message: "Radio button option",
        id: "forms_radio_button_option",
      })
    case FormQuestionAnswerType.CheckBoxChoiceAnswer:
      return t({
        message: "Checkbox option",
        id: "forms_checkbox_option",
      })
    case FormQuestionAnswerType.MultipleChoiceAnswer:
      return t({
        message: "Multiple choice option",
        id: "forms_multiple_choice_option",
      })
    case FormQuestionAnswerType.TableAnswer:
      return t({
        message: "Table row",
        id: "forms_table_row_option",
      })
    case FormQuestionAnswerType.DropDownChoiceAnswer:
      return t({
        message: "Dropdown option",
        id: "forms_dropdown_option",
      })
    default:
      return t({
        message: "Option",
        id: "forms_default_option",
      })
  }
}
