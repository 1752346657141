import { UseMutationResult } from "@tanstack/react-query"
import { Trans } from "@lingui/macro"
import { ApplicationResponse } from "@src/api/application"
import { Button } from "@src/components/atoms/Button"

export type DeleteApplicationDialogProps = {
  selectedApplications: Array<ApplicationResponse & { id: number }>
  onClose: () => void
  mutation: UseMutationResult<void, Error, void, unknown>
}

export const DeleteApplicationsDialog = ({
  selectedApplications,
  onClose,
  mutation,
}: DeleteApplicationDialogProps) => {
  return (
    <div>
      <p className="mb-4 text-paragraph-medium text-pearl-darker">
        <Trans id="delete_application_description">
          Are you sure you want to delete the {selectedApplications.length}{" "}
          selected applications? If you confirm, these applications will be
          permanantely deleted. Beware, this action is irreversible!
        </Trans>
      </p>
      <div className="flex items-center justify-end gap-2">
        <Button variant="secondary" small onClick={() => onClose()}>
          <Trans id="cancel">Cancel</Trans>
        </Button>
        <Button
          type="submit"
          small
          onClick={() => mutation.mutate()}
          disabled={mutation.isPending || mutation.isSuccess}
        >
          <Trans id="delete_applications">Delete applications</Trans>
        </Button>
      </div>
    </div>
  )
}
