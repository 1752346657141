import { ReactNode } from "react"
import { IconType } from "@src/config/icons"

export type BaseButtonProps = {
  variant?: "primary" | "secondary" | "ternary" | "text" | "translucent" | "dotted"
  small?: boolean
  className?: string
  icon?: IconType
  children: ReactNode
}

export const styles = {
  base: [
    "inline-flex items-center justify-center gap-x-2 border text-paragraph relative cursor-pointer disabled:pointer-events-none rounded-xl", // base

    "data-[focus]:outline data-[focus]:outline-2 data-[focus]:outline-offset-2 data-[focus]:outline-blue-500", // focus

    "data-[disabled]:opacity-25", // disabled
  ],

  primary: [
    "bg-blue text-white border-blue font-semibold", // base

    "hover:bg-blue-dark hover:border-blue-dark active:border-blue-lighter active:bg-blue-lighter", // actions
  ],

  secondary: [
    "bg-transparent text-pearl-dark border-pearl-light font-semibold", // base

    "hover:bg-pearl hover:text-pearl-darker", // actions
  ],

  ternary: [
    "bg-transparent text-blue border-blue font-semibold", // base

    "hover:bg-blue hover:bg-opacity-10 active:bg-transparent active:text-blue-dark active:border-blue-dark", // actions
  ],

  translucent: [
    "bg-black/40 text-white border-transparent font-semibold", // base

    "hover:bg-black/60 active:bg-black/80", // actions
  ],

  text: [
    "bg-transparent text-blue border-transparent", // base

    "hover:text-blue-dark active:text-blue-dark", // actions
  ],

  dotted: [
    "bg-transparent text-blue border-dotted border-blue", // base

    "hover:text-blue-dark active:text-blue-dark", // actions
  ],

  size: (small: boolean) =>
    small ? ["py-2 px-4 text-paragraph-medium"] : ["py-3 px-4 text-paragraph"],
}
