import { useEffect, useState, useMemo, useReducer } from "react"
import { useQuery } from "@tanstack/react-query"
import { t, Trans } from "@lingui/macro"
import {
  ColumnDef,
  createColumnHelper,
  SortingState,
} from "@tanstack/react-table"
import { format } from "date-fns"
import { Table } from "@src/components/organisms/Table"
import { CoachingFilters } from "@src/components/pages/Coaching/CoachingFilters"

import {
  getCoachingRequestsByCommunityQuery,
  getRequestsDefaultCirclesQuery,
} from "@src/api/request"
import { getCommunityCirclesQuery } from "@src/api/circle"
import {
  getRequestsStatus,
  handleDownloadCsv,
  CoachingRequestsFilters,
  RequestsTypeEnum,
  RequestsStatusEnum,
  RequestsCsatEnum,
  getSessionType,
} from "@src/components/pages/Coaching/utils"
import { mapLanguageToDateFnsLocale } from "@src/utils/language"
import { Icon } from "@src/components/atoms/Icon"
import { useDialogQueue } from "@src/context/DialogQueueProvider/useDialogQueue"
import { Button } from "@src/components/atoms/Button"
import { SettingsDialogContent } from "@src/components/pages/Coaching/SettingsDialogContent"
import { RoutesWithClientFilters } from "@src/components/organisms/Filters/ClientFilters"

export type CoachingPageProps = {
  communityId: number
  route?: RoutesWithClientFilters
}

export type RequestsItem = {
  id?: number
  sessionType?: string | null
  name?: string | null
  projectName?: string | null
  requestStatus?: number
  advisorName?: string | null
  postDate?: string
  eventDateTimeStart?: string | null
  resolvedDate?: string | null
  advisorRating?: number | null
  entrepreneurRating?: number | null
}

export const Coaching = ({ communityId, route }: CoachingPageProps) => {
  const [pageIndex, setPageIndex] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)
  const [sorting, setSorting] = useState<SortingState>([])
  const [isDownloadingCsv, setIsDownloadingCsv] = useState<boolean>(false)
  const { enqueueDialog, closeCurrentDialog } = useDialogQueue()
  const [showFilters, setShowFilters] = useReducer(
    (previous) => !previous,
    false,
  )
  const [filterValues, setFilterValues] = useState<CoachingRequestsFilters>({})

  const { data: requests, isLoading: requestsLoading } = useQuery(
    getCoachingRequestsByCommunityQuery(communityId),
  )

  useQuery(getRequestsDefaultCirclesQuery(communityId))
  useQuery(getCommunityCirclesQuery(communityId))

  const onDownloadCsv = async () => {
    setIsDownloadingCsv(true)
    try {
      await handleDownloadCsv(communityId)
    } catch (error) {
      console.error("Download failed", error)
    } finally {
      setIsDownloadingCsv(false)
    }
  }

  useEffect(() => {
    setPageIndex(0)
  }, [filterValues])

  const filteredRequests = useMemo(() => {
    if (!requests) return []

    const filterSteps = [
      {
        condition:
          filterValues.sessionTypeFilter &&
          filterValues.sessionTypeFilter !== RequestsTypeEnum.All,
        filterFn: (req: RequestsItem) =>
          req.sessionType &&
          req.sessionType === getSessionType(filterValues.sessionTypeFilter!),
      },
      {
        condition:
          filterValues.statusFilter !== undefined &&
          filterValues.statusFilter !== RequestsStatusEnum.All,
        filterFn: (req: RequestsItem) =>
          req.requestStatus === filterValues.statusFilter,
      },
      {
        condition:
          filterValues.guestScoreFilter !== undefined &&
          filterValues.guestScoreFilter !== RequestsCsatEnum.All,
        filterFn: (req: RequestsItem) =>
          req.advisorRating == filterValues.guestScoreFilter,
      },
      {
        condition:
          filterValues.creatorScoreFilter !== undefined &&
          filterValues.creatorScoreFilter !== RequestsCsatEnum.All,
        filterFn: (req: RequestsItem) =>
          req.entrepreneurRating == filterValues.creatorScoreFilter,
      },
      {
        condition:
          filterValues.sessionNameFilter &&
          filterValues.sessionNameFilter.trim() !== "" &&
          filterValues.sessionNameFilter.trim().toLowerCase() !== "all",
        filterFn: (req: RequestsItem) => {
          const lower = filterValues.sessionNameFilter!.toLowerCase()
          return req.name?.toLowerCase().includes(lower)
        },
      },
      {
        condition:
          filterValues.startupNameFilter &&
          filterValues.startupNameFilter.trim() !== "" &&
          filterValues.startupNameFilter.trim().toLowerCase() !== "all",
        filterFn: (req: RequestsItem) => {
          const lower = filterValues.startupNameFilter!.toLowerCase()
          return req.projectName?.toLowerCase() === lower
        },
      },
      {
        condition:
          filterValues.attendeesFilter &&
          filterValues.attendeesFilter.trim() !== "" &&
          filterValues.attendeesFilter.trim().toLowerCase() !== "all",
        filterFn: (req: RequestsItem) => {
          const lower = filterValues.attendeesFilter!.toLowerCase()
          return req.advisorName?.toLowerCase() === lower
        },
      },
      {
        condition:
          filterValues.creationDateFilter &&
          filterValues.creationDateFilter.trim() !== "",
        filterFn: (req: RequestsItem) => {
          if (!req.postDate) return false

          const selectedDate = format(
            new Date(filterValues.creationDateFilter!),
            "yyyy-MM-dd",
          )
          const reqDate = format(new Date(req.postDate), "yyyy-MM-dd")
          return reqDate === selectedDate
        },
      },
    ]

    let filteredItems = [...requests]
    for (const { condition, filterFn } of filterSteps) {
      if (condition) {
        filteredItems = filteredItems.filter(filterFn)
      }
    }

    return filteredItems
  }, [requests, filterValues])

  const pagedRequests = useMemo(() => {
    if (!filteredRequests) return []
    const startIdx = pageIndex * pageSize
    const endIdx = startIdx + pageSize
    return filteredRequests.slice(startIdx, endIdx)
  }, [filteredRequests, pageIndex, pageSize])

  const columnHelper = createColumnHelper<RequestsItem>()
  const columns = [
    columnHelper.accessor("sessionType", {
      header: t({
        message: "Session type",
        id: "session_type",
      }),
      enableSorting: true,
      sortingFn: "alphanumeric",
    }),
    columnHelper.accessor("name", {
      header: t({
        message: "Session name",
        id: "session_name",
      }),
      enableSorting: true,
      sortingFn: "alphanumeric",
      cell: ({ getValue }) => {
        const sessionName = getValue()

        return (
          <div
            className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap 3xl:max-w-[250px] 4xl:max-w-none"
            title={String(sessionName)}
          >
            {sessionName}
          </div>
        )
      },
    }),
    columnHelper.accessor("projectName", {
      header: t({
        message: "{100}",
        id: "project",
      }),
      enableSorting: true,
      sortingFn: "alphanumeric",
      cell: ({ getValue }) => {
        const startupName = getValue()

        return (
          <div
            className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap 3xl:max-w-[250px] 4xl:max-w-none"
            title={String(startupName)}
          >
            {startupName}
          </div>
        )
      },
    }),
    columnHelper.accessor(`requestStatus`, {
      header: t({
        message: "Status",
        id: "status",
      }),
      enableSorting: true,
      sortingFn: "alphanumeric",
      cell: ({ getValue }) => {
        const status = getValue()

        return (
          <div>
            {status !== null && status !== undefined
              ? getRequestsStatus(status)
              : "-"}
          </div>
        )
      },
    }),
    columnHelper.accessor("advisorName", {
      header: t({
        message: "Attendees",
        id: "attendees",
      }),
      enableSorting: true,
      sortingFn: "alphanumeric",
      cell: ({ getValue }) => {
        const attendees = getValue()

        return <div>{attendees ? `${attendees}` : "-"}</div>
      },
    }),
    columnHelper.accessor(`postDate`, {
      header: t({
        message: "Creation Date",
        id: "creation_date",
      }),
      enableSorting: true,
      sortingFn: "datetime",
      cell: ({ getValue }) => {
        const date = getValue()
        const localeKey = mapLanguageToDateFnsLocale(navigator.language)
        return date ? format(new Date(date), "Pp", { locale: localeKey }) : "-"
      },
    }),
    columnHelper.accessor(`eventDateTimeStart`, {
      header: t({
        message: "Session Date",
        id: "session_date",
      }),
      enableSorting: true,
      sortingFn: "datetime",
      cell: ({ getValue }) => {
        const date = getValue()
        const localeKey = mapLanguageToDateFnsLocale(navigator.language)
        return date ? format(new Date(date), "Pp", { locale: localeKey }) : "-"
      },
    }),
    columnHelper.accessor(`resolvedDate`, {
      header: t({
        message: "Resolved Date",
        id: "resolved_date",
      }),
      enableSorting: true,
      sortingFn: "datetime",
      cell: ({ getValue }) => {
        const date = getValue()
        const localeKey = mapLanguageToDateFnsLocale(navigator.language)
        return date ? format(new Date(date), "Pp", { locale: localeKey }) : "-"
      },
    }),
    columnHelper.accessor(`advisorRating`, {
      header: t({
        message: "Guest CSAT",
        id: "guest_csat",
      }),
      enableSorting: true,
      sortingFn: "alphanumeric",
      cell: ({ getValue }) => {
        const rating = getValue()

        return (
          <div>
            {rating ? (
              <div className="flex items-center gap-1">
                <div>{rating}/5</div>
                <Icon className="text-yellow-500" size="large" icon="star" />
              </div>
            ) : (
              <div className="flex items-center gap-1">
                <div>N/A</div>
                <Icon className="text-yellow-500" size="large" icon="star" />
              </div>
            )}
          </div>
        )
      },
    }),
    columnHelper.accessor(`entrepreneurRating`, {
      header: t({
        message: "Creator CSAT",
        id: "creator_csat",
      }),
      enableSorting: true,
      sortingFn: "alphanumeric",
      cell: ({ getValue }) => {
        const rating = getValue()

        return (
          <div>
            {rating ? (
              <div className="flex items-center gap-1">
                <div>{rating}/5</div>
                <Icon className="text-yellow-500" size="large" icon="star" />
              </div>
            ) : (
              <div className="flex items-center gap-1">
                <div>N/A</div>
                <Icon className="text-yellow-500" size="large" icon="star" />
              </div>
            )}
          </div>
        )
      },
    }),
  ] as ColumnDef<RequestsItem>[]

  const handleFilterChange = (values: CoachingRequestsFilters) => {
    setFilterValues(values)
  }

  return (
    <div>
      <div className="flex flex-col gap-4 lg:gap-6">
        <div className="flex items-center gap-2">
          <Button
            variant="primary"
            small
            disabled={isDownloadingCsv}
            onClick={onDownloadCsv}
          >
            <Trans id="export_to_csv">Export to CSV</Trans>
          </Button>
          <Button
            variant="secondary"
            small
            onClick={() => {
              enqueueDialog({
                title: t({
                  message: "Request settings",
                  id: "request_settings",
                }),
                children: (
                  <SettingsDialogContent
                    communityId={communityId}
                    onClose={closeCurrentDialog}
                  />
                ),
              })
            }}
          >
            <Trans id="settings">Settings</Trans>
          </Button>
          <Button variant="secondary" small onClick={setShowFilters}>
            <Icon icon="filter_list" size="large" />
          </Button>
        </div>
        {showFilters && (
          <CoachingFilters
            values={filterValues}
            itemsList={requests || []}
            onChange={handleFilterChange}
            route={route}
          />
        )}
        <div>
          <Table
            columns={columns}
            data={pagedRequests}
            loading={requestsLoading}
            itemsCount={filteredRequests.length}
            pageIndex={pageIndex}
            pageSize={pageSize}
            onPageChange={setPageIndex}
            onPageSizeChange={setPageSize}
            sorting={sorting}
            onSortingChange={setSorting}
          />
        </div>
      </div>
    </div>
  )
}
