import { useState } from "react"
import { t } from "@lingui/macro"
import { ColumnDef, createColumnHelper } from "@tanstack/react-table"
import { format } from "date-fns"
import { Table } from "@src/components/organisms/Table"
import { Filters, ApiFiltersOptions } from "@src/components/organisms/Filters"
import { ApiDynamicFilter, ApiDynamicFilterType } from "@src/api/filters"
import { Badge, BadgeProps } from "@src/components/atoms/Badge"

export type SurveyResponsesPageProps = {
  surveyId: number
}

enum SurveyResponseStatus {
  NotStarted,
  Draft,
  Submitted,
}

enum SurveyType {
  Startup,
  Individual,
}

export type SurveyResponse = {
  Id: number
  UserName: string
  Reference: string
  Submission: string | null
  Tags: string[]
  QuestionsCount: number
  AnswersCount: number
  FillRate: number
  Evaluators: string[]
  Evaluation: number
  Status: SurveyResponseStatus
}

export type SurveyDefinition = {
  Id: number
  Title?: string
  FormId?: number
  Description?: string
  SurveyType: SurveyType
  Questions: string[]
  // Number in months
  Recurrency?: number
  KPIConvert?: boolean
  StartDate?: string
  EndDate?: string
  // The circles that will receive and can answer the survey
  CircleList?: number[]
  // The methodologies that will receive and can answer the survey
  MethodologyList?: number[]
  Deadline?: string
}

const mockResponsesData = [
  {
    Id: 1,
    UserName: "Tibi",
    Reference: "Reference 1",
    Submission: "2024-12-01T00:00:00",
    Tags: ["Tag 1", "Tag 2"],
    QuestionsCount: 8,
    AnswersCount: 8,
    FillRate: 1,
    Evaluators: ["Evaluator 1", "Evaluator 2"],
    Evaluation: 5,
    Status: SurveyResponseStatus.Submitted,
  },
  {
    Id: 2,
    UserName: "John",
    Reference: "Reference 2",
    Submission: null,
    Tags: ["Tag 1", "Tag 2"],
    QuestionsCount: 8,
    AnswersCount: 0,
    FillRate: 0,
    Evaluators: ["Evaluator 1", "Evaluator 2"],
    Evaluation: 5,
    Status: SurveyResponseStatus.Draft,
  },
  {
    Id: 3,
    UserName: "Dean",
    Reference: "Reference 3",
    Submission: null,
    Tags: ["Another tag"],
    QuestionsCount: 8,
    AnswersCount: 4,
    FillRate: 0.5,
    Evaluators: ["Evaluator 1", "Evaluator 2"],
    Evaluation: 5,
    Status: SurveyResponseStatus.NotStarted,
  },
]

enum SurveyOrderBy {
  Status,
  FillRate,
}

const orderByValues = [
  { id: SurveyOrderBy.Status, label: "Status" },
  { id: SurveyOrderBy.FillRate, label: "Fill Rate" },
]

export type SurveyResponseItem = {
  Id: number
  UserName: string
  Submission: string | null
  Tags: string[]
  FillRate: number
  Status: SurveyResponseStatus
}

const iterationFilterValues = [
  { id: 1, label: "First trimester" },
  { id: 2, label: "Second trimester" },
  { id: 3, label: "Third trimester" },
]

const statusFilterValues = [
  { id: SurveyResponseStatus.NotStarted, label: "Not Started" },
  { id: SurveyResponseStatus.Draft, label: "Draft" },
  { id: SurveyResponseStatus.Submitted, label: "Submitted" },
]

const statusToBadgeMap: Record<SurveyResponseStatus, BadgeProps["color"]> = {
  [SurveyResponseStatus.NotStarted]: "default",
  [SurveyResponseStatus.Draft]: "yellow",
  [SurveyResponseStatus.Submitted]: "green",
}

export type SurveyResponsesFilters = {
  iterationFilter?: ApiDynamicFilter<number>
  statusFilter?: SurveyResponseStatus
  orderBy?: SurveyOrderBy
  skip?: number
  take?: number
}

export type QuestionFilter = {
  question: string
  answers: string[]
  filterType: ApiDynamicFilterType
}

export const SurveysResponsesPage = ({
  surveyId,
}: SurveyResponsesPageProps) => {
  const columnHelper = createColumnHelper<SurveyResponseItem>()
  console.log(surveyId)
  const columns = [
    columnHelper.accessor("UserName", {
      header: t({
        message: "Username",
        id: "username",
      }),
    }),
    columnHelper.accessor("Submission", {
      header: t({
        message: "Submission Date",
        id: "submission_date",
      }),
      cell: ({ getValue }) => {
        const date = getValue()
        return date ? format(new Date(date), "dd-MM-yyyy HH:mm") : "-"
      },
    }),
    columnHelper.accessor("Tags", {
      header: "Tags",
      cell: ({ getValue }) => {
        return (
          <div className="flex gap-2">
            {getValue()?.map((tag) => <Badge key={tag}>{tag}</Badge>)}
          </div>
        )
      },
    }),
    columnHelper.accessor("FillRate", {
      header: t({
        message: "Fill Rate",
        id: "fill_rate",
      }),
      cell: ({ getValue }) => {
        const fillRate = getValue()
        return `${(fillRate * 100).toFixed(0)}%`
      },
    }),
    columnHelper.accessor("Status", {
      header: t({
        message: "Status",
        id: "status",
      }),
      cell: ({ getValue }) => {
        const status = getValue()

        return (
          <Badge color={statusToBadgeMap[status]}>
            {statusFilterValues.find((s) => s.id === status)?.label}
          </Badge>
        )
      },
    }),
  ] as ColumnDef<SurveyResponseItem>[]

  const [filterValues, setFilterValues] = useState<SurveyResponsesFilters>({
    iterationFilter: {
      values: [],
      filterType: ApiDynamicFilterType.IsAnyOf,
    },
  })

  const handleFilterChange = (values: SurveyResponsesFilters) => {
    setFilterValues(values)
  }

  const filterOptions: ApiFiltersOptions<SurveyResponsesFilters> = {
    statusFilter: {
      filterOptions: statusFilterValues,
      advancedFilter: false,
      label: "Status",
    },
    iterationFilter: {
      filterOptions: iterationFilterValues,
      advancedFilter: true,
      label: "Iteration",
    },
    orderBy: {
      filterOptions: orderByValues,
      advancedFilter: false,
      label: "Sort by",
    },
  }

  return (
    <div>
      <Filters
        defaultValues={{
          iterationFilter: {
            values: [],
            filterType: ApiDynamicFilterType.IsAnyOf,
          },
        }}
        values={filterValues}
        optionValues={filterOptions}
        onChange={handleFilterChange}
        route={
          "/_auth/_community/community/$communityId/_admin-panel/admin-panel/surveys/$surveyId/responses"
        }
        showResetButton={true}
        className="mb-4"
      />
      <Table columns={columns} data={mockResponsesData} loading={false} />
    </div>
  )
}
