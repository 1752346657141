import { getRouteApi } from "@tanstack/react-router"
import { ApiFilters } from "@src/api/filters"
import { ClientFilters, RoutesWithClientFilters } from "./ClientFilters"
import { RoutesWithFilters } from "."

export type SearchTerm<T extends ApiFilters | ClientFilters> = {
  key: keyof T
  route: RoutesWithFilters | RoutesWithClientFilters
}

// Hook for managing search box state by using the search params as the state and
// only source of truth
export const useSearchTerm = <T extends ApiFilters | ClientFilters>({
  key,
  route,
}: SearchTerm<T>) => {
  const routeApi = getRouteApi(route)
  const filters: T = routeApi.useSearch()
  const navigate = routeApi.useNavigate()

  const setSearchTerm = (searchTerm: string | undefined) => {
    navigate({
      search: () => {
        if (!searchTerm) {
          const newFilters = { ...filters }
          delete newFilters[key]
          return newFilters
        }

        return {
          ...filters,
          [key]: searchTerm,
        }
      },
    })
  }

  return {
    [key]: filters[key],
    setSearchTerm,
  } as unknown as T & {
    setSearchTerm: (searchTerm: string | undefined) => void
  }
}
