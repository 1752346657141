import { t } from "@lingui/macro"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import {
  saveCommunityCircle,
  CommunityCircle,
  circleKeys,
} from "@src/api/circle"
import { IconButton } from "@src/components/atoms/IconButton"
import { Permission } from "./types"

interface IUseCirclePermissionsRows {
  communityId: number | undefined
  communityCircles: CommunityCircle[] | undefined
}

type PermissionKey =
  | "permissionRequestCanViewAllAndInviteAdvisor"
  | "permissionProjectCanCreate"
  | "permissionCanAccessToOverviewProject"
  | "permissionCanInviteToProjects"
  | "permissionCanApproveParagraph"
  | "permissionCanBeInvitedAsCoach"
  | "permissionCanCoachTeamMembers"

export const useCirclePermissionsRows = ({
  communityId,
  communityCircles,
}: IUseCirclePermissionsRows) => {
  const queryClient = useQueryClient()

  const { mutate: saveCommunityCircleMutation } = useMutation({
    mutationFn: saveCommunityCircle,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: circleKeys.circleList(communityId),
      })
    },
  })

  const permissionsMap: Record<PermissionKey, string> = {
    permissionRequestCanViewAllAndInviteAdvisor: t({
      message: "Assign users to requests",
      id: "circles_roles_assign_users_to_requests",
    }),
    permissionProjectCanCreate: t({
      message: "Create {101}",
      id: "circles_roles_create_startups",
    }),
    permissionCanAccessToOverviewProject: t({
      message: "Access the {101}' dashboard",
      id: "circles_roles_access_projects_overview",
    }),
    permissionCanInviteToProjects: t({
      message: "Manage {101}' members",
      id: "circles_roles_invite_to_projects",
    }),
    permissionCanApproveParagraph: t({
      message: "Approve activities",
      id: "circles_roles_approve_paragraph",
    }),
    permissionCanBeInvitedAsCoach: t({
      message: "Can book coaching sessions",
      id: "circles_roles_searchable_as_coach",
    }),
    permissionCanCoachTeamMembers: t({
      message: "Schedule coaching sessions with {101}",
      id: "circles_roles_can_coach_projects",
    }),
  }

  return Object.entries(permissionsMap).map(([key, label]) => {
    const row: Permission = { name: label }

    communityCircles?.forEach((circle: CommunityCircle) => {
      if (circle.name) {
        const hasPermission = circle[key as PermissionKey]
        row[circle.name] = hasPermission ? (
          <IconButton
            onClick={() => {
              saveCommunityCircleMutation({
                ...circle,
                [key]: false,
              })
            }}
            variant="text"
            disabled={circle.name === "Admins"}
            icon="check_circle"
            className="!text-green"
            size="large"
          />
        ) : (
          <IconButton
            onClick={() => {
              saveCommunityCircleMutation({
                ...circle,
                [key]: true,
              })
            }}
            variant="text"
            icon="cancel"
            className="!text-pearl-light"
            size="large"
          />
        )
      }
    })

    return row
  })
}
