import { lazy } from "react"
import { t } from "@lingui/macro"

import { AnswerProps } from "./types"
import { useValidators } from "./validators"

const Editor = lazy(() =>
  import("@src/components/organisms/Editor").then((module) => ({
    default: module.Editor,
  })),
)

export const LongTextAnswer = ({ form, question }: AnswerProps) => {
  const { requiredString } = useValidators()
  const questionOrder = question?.questionOrder ?? 0

  const handleEditorChange = (value: string) => {
    const currentAnswer = form.state.values.answers?.[questionOrder] ?? {}

    form.setFieldValue("answers", {
      ...form.state.values.answers,
      [questionOrder]: {
        ...currentAnswer,
        answer: value,
        formQuestionId: question.id,
      },
    })
  }

  return (
    <form.Field
      name={`answers[${questionOrder}].answer`}
      validators={question.mandatoryAnswer ? requiredString : undefined}
    >
      {(field) => (
        <Editor
          className="rounded-xl border border-pearl-lighter"
          placeholder={t({
            message:
              "Write a compelling description to explain to users how they should fill the form",
            id: "write_compelling_description",
          })}
          error={field.state.meta.errors.join(", ")}
          value={field.state.value || ""}
          required={question.mandatoryAnswer}
          description={question.description ?? ""}
          label={question.title ?? ""}
          onChange={handleEditorChange}
          onBlur={field.handleBlur}
        />
      )}
    </form.Field>
  )
}
