import { createFileRoute } from "@tanstack/react-router"
import { EvaluateResponse } from "@src/components/pages/Applications/EvaluateResponse"

export const Route = createFileRoute(
  "/_auth/_community/community/$communityId/_admin-panel/admin-panel/application-definitions/$applicationId/evaluate/$responseId",
)({
  component: () => <EvaluateResponsePageWrapper />,
})

function EvaluateResponsePageWrapper() {
  const { applicationId } = Route.useParams()
  const { responseId } = Route.useParams()

  const parsedApplicationId = parseInt(applicationId)
  const parsedResponseId = parseInt(responseId)

  return (
    <EvaluateResponse
      applicationDefinitionId={parsedApplicationId}
      responseId={parsedResponseId}
    />
  )
}
