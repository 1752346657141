import { useQuery } from "@tanstack/react-query"

import { getSurveyFormQuery } from "@src/api/surveyForm"

import { Skeleton } from "@src/components/atoms/Skeleton"
import { SurveyForm } from "./SurveyForm"

export type SurveyFormPageProps = {
  formId?: number
  viewMode?: boolean
  duplicate?: boolean
  newForm?: boolean
}

export const SurveyFormPage = ({
  formId,
  viewMode,
  duplicate,
  newForm,
}: SurveyFormPageProps) => {
  const { data: form, isLoading } = useQuery(
    getSurveyFormQuery(newForm ? undefined : formId),
  )

  return !isLoading ? (
    <SurveyForm form={form} viewMode={viewMode} duplicate={duplicate} />
  ) : (
    <Skeleton className="h-[100vh]" />
  )
}
