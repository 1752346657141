import { useState } from "react"
import {
  Input as HeadlessInput,
  type InputProps as HeadlessInputProps,
} from "@headlessui/react"
import clsx from "clsx"

import { Icon } from "@src/components/atoms/Icon"

const styles = {
  base: [
    "relative block appearance-none rounded-xl border bg-white", // base
    "placeholder:text-pearl-dark text-paragraph-medium placeholder:text-paragraph-medium", // typography
    "focus:outline-none", // hide default focus styles
    "data-[invalid]:border-red", // states
    "border-pearl-lighter focus:border-pearl-light bg-transparent text-black",
  ],
}

export type SearchInputProps = Omit<
  HeadlessInputProps,
  "value" | "onChange"
> & {
  className?: string
  description?: string
  onChange: (value: string) => void
  value?: string | null
}

export const SearchInput = ({
  className,
  value,
  onChange,
  ...props
}: SearchInputProps) => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <div className="relative">
      <HeadlessInput
        className={clsx(styles.base, className, {
          "w-0 px-5 py-2": !isExpanded && !value,
          "transition-all duration-300 ease-in-out": true,
          "w-full py-2 pl-10 pr-5": isExpanded || value,
        })}
        name="search"
        onFocus={() => setIsExpanded(true)}
        onBlur={() => setIsExpanded(false)}
        value={value || ""}
        {...props}
        onChange={(e) => {
          const { value } = e.target
          onChange(value)
        }}
      />
      <Icon
        icon="search"
        size="largex"
        className={clsx([
          "flex items-center pl-2.5",
          "absolute bottom-1.5 left-0",
          "pointer-events-none text-pearl-lighter",
        ])}
      />
    </div>
  )
}
