import { useQuery } from "@tanstack/react-query"
import { useState } from "react"
import { t, Trans } from "@lingui/macro"
import clsx from "clsx"
import { ColumnDef, createColumnHelper } from "@tanstack/react-table"
import {
  Filters,
  ApiFiltersOptions,
  RoutesWithFilters,
} from "@src/components/organisms/Filters"
import { cleanInvalidFilters } from "@src/components/organisms/Filters/utils"
import { ApiDynamicFilterType } from "@src/api/filters"
import { useDialogQueue } from "@src/context/DialogQueueProvider/useDialogQueue"
import {
  getApplicationsFiltersQuery,
  ApplicationsFilters,
  ApplicationResponse,
  getApplicationDefinitionResponsesQuery,
  ApplicationOrderBy,
  getCommunityApplicationDefinitionQuery,
} from "@src/api/application"
import { Table } from "@src/components/organisms/Table"
import { Skeleton } from "@src/components/atoms/Skeleton"
import {
  pickFilterOptionValues,
  applicationStatusToColor,
  applicationStatusToLabel,
} from "./utils"
import { mapLanguageToDateFnsLocale } from "@src/utils/language"
import { format } from "date-fns"
import { Image } from "@src/components/atoms/Image"
import { Checkbox } from "@src/components/atoms/Checkbox"
import { Icon } from "@src/components/atoms/Icon"
import { Badge } from "@src/components/atoms/Badge"
import { IconButton } from "@src/components/atoms/IconButton"
import { Button } from "@src/components/atoms/Button"
import { TagsDialog } from "./TagsDialog"
import { hasDefinedId } from "@src/utils/types"
import { usePagination } from "@src/components/organisms/Filters/pagination"
import { Actions } from "./Actions"
import { useSearchTerm } from "@src/components/organisms/Filters/search"

export type ApplicationResponsesProps = {
  applicationId: number
  route: RoutesWithFilters
}

export const ApplicationResponses = ({
  applicationId,
  route,
}: ApplicationResponsesProps) => {
  const [filterValues, setFilterValues] = useState<ApplicationsFilters>({})
  const [showFilters, setShowFilters] = useState(false)
  const { concept, setSearchTerm } = useSearchTerm<ApplicationsFilters>({
    key: "concept",
    route: route,
  })

  const { take, skip, orderBy, setPagination } =
    usePagination<ApplicationsFilters>({
      defaultValues: { skip: 0, take: 50 },
      route: route,
    })

  const [selectedApplications, setSelectedApplications] = useState<
    Array<ApplicationResponse & { id: number }>
  >([])

  const {
    data: applicationDefinition,
    isLoading: applicationDefinitionLoading,
  } = useQuery(getCommunityApplicationDefinitionQuery(applicationId))

  const { data: applicationFilters, isLoading: applicationFiltersLoading } =
    useQuery(getApplicationsFiltersQuery(applicationId))

  const { data: applicationResponses, isLoading: applicationResponsesLoading } =
    useQuery(
      getApplicationDefinitionResponsesQuery(applicationId, {
        ...cleanInvalidFilters(filterValues),
        concept,
        take,
        skip,
        orderBy,
      }),
    )

  const { enqueueDialog, closeCurrentDialog } = useDialogQueue()

  const currentPage = Math.floor(skip / (take || 1))

  const filterOptionValues = pickFilterOptionValues(
    applicationFilters || {},
    applicationResponses || {},
    filterValues,
  )

  const filterOptions: ApiFiltersOptions<ApplicationsFilters> = {
    tagFilter: {
      advancedFilter: true,
      filterOptions: filterOptionValues.tagFilter,
      label: t({
        message: "Tags",
        id: "tags",
      }),
    },
    countryFilter: {
      advancedFilter: true,
      filterOptions: filterOptionValues.countryFilter,
      label: t({
        message: "Country",
        id: "country",
      }),
    },
    industryFilter: {
      advancedFilter: true,
      filterOptions: filterOptionValues.industryFilter,
      label: t({
        message: "Industry",
        id: "industry",
      }),
    },
    sdgFilter: {
      advancedFilter: true,
      filterOptions: filterOptionValues.sdgFilter,
      label: "SDG",
    },
  }

  const defaultValues = {
    tagFilter: {
      values: [],
      filterType: ApiDynamicFilterType.IsAnyOf,
    },
    countryFilter: {
      values: [],
      filterType: ApiDynamicFilterType.IsAnyOf,
    },
    industryFilter: {
      values: [],
      filterType: ApiDynamicFilterType.IsAnyOf,
    },
    sdgFilter: {
      values: [],
      filterType: ApiDynamicFilterType.IsAnyOf,
    },
  }

  const handleFilterChange = (values: ApplicationsFilters) => {
    setFilterValues(values)
    // Reset pagination
    setPagination({})
  }

  const columnHelper = createColumnHelper<ApplicationResponse>()

  const columns = [
    columnHelper.display({
      id: "actions",
      header: () => {
        const checked =
          applicationResponses?.list?.length === selectedApplications.length &&
          selectedApplications.length > 0

        const onChange = () => {
          if (checked) {
            setSelectedApplications([])
          } else {
            setSelectedApplications(
              applicationResponses?.list?.filter(hasDefinedId) || [],
            )
          }
        }

        return <Checkbox checked={checked} onChange={onChange} />
      },
      cell: ({ row }) => {
        const checked = selectedApplications.some(
          (app) => row.original?.id === app?.id,
        )

        const onChange = () => {
          if (checked) {
            setSelectedApplications(
              selectedApplications.filter(
                (app) => app?.id !== row.original?.id,
              ),
            )
          } else {
            setSelectedApplications(
              (
                [...selectedApplications, row.original] as Array<
                  ApplicationResponse & { id: number }
                >
              ).filter(hasDefinedId),
            )
          }
        }

        return (
          <div className="w-6">
            <Checkbox checked={checked} onChange={onChange} />
          </div>
        )
      },
    }),
    columnHelper.accessor("applicantName", {
      header: t({
        message: "Applicant name",
        id: "applicant_name",
      }),
      cell: ({ getValue, row }) => {
        const name = getValue()

        if (!name) {
          return <span>-</span>
        }

        return (
          <div className="flex cursor-pointer items-center gap-2" role="link">
            <Image
              src={row?.original?.applicantPhotourl}
              alt={name}
              lazy={true}
              className="h-6 w-6 rounded-full"
            />
            <span>{name}</span>
          </div>
        )
      },
    }),
    columnHelper.accessor("applyDate", {
      header: () => {
        const sortByDate = () => {
          if (orderBy === ApplicationOrderBy.DateNewest) {
            setPagination({ orderBy: ApplicationOrderBy.DateOldest })
          } else {
            setPagination({ orderBy: ApplicationOrderBy.DateNewest })
          }
        }

        return (
          <div
            onClick={sortByDate}
            className="flex cursor-pointer items-center gap-1"
          >
            <span>
              <Trans id="application_date">Application date</Trans>
            </span>{" "}
            {orderBy !== ApplicationOrderBy.DateNewest &&
              orderBy !== ApplicationOrderBy.DateOldest && (
                <Icon icon="swap_vert" className="text-paragraph-medium" />
              )}
            {orderBy === ApplicationOrderBy.DateNewest && (
              <Icon icon="arrow_downward_alt" />
            )}
            {orderBy === ApplicationOrderBy.DateOldest && (
              <Icon icon="arrow_upward_alt" />
            )}
          </div>
        )
      },
      cell: ({ getValue }) => {
        const date = getValue()

        if (!date) {
          return <span>-</span>
        }

        const formattedDate = format(new Date(date), "P", {
          locale: mapLanguageToDateFnsLocale(navigator.language),
        })

        return <span>{formattedDate}</span>
      },
    }),
    columnHelper.accessor("projectDescription", {
      header: t({
        message: "Organization name",
        id: "organization_name",
      }),
      cell: ({ getValue }) => {
        const description = getValue()

        if (!description) {
          return <span>-</span>
        }

        return (
          <span
            className="inline-block max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap"
            title={description}
          >
            {description}
          </span>
        )
      },
    }),
    columnHelper.accessor("averageScore", {
      header: () => {
        const sortByRating = () => {
          if (orderBy === ApplicationOrderBy.HighestRating) {
            setPagination({ orderBy: ApplicationOrderBy.LowestRating })
          } else {
            setPagination({ orderBy: ApplicationOrderBy.HighestRating })
          }
        }

        return (
          <div
            onClick={sortByRating}
            className="flex cursor-pointer items-center gap-1"
          >
            <span>
              <Trans id="rating">Rating</Trans>
            </span>{" "}
            {orderBy !== ApplicationOrderBy.HighestRating &&
              orderBy !== ApplicationOrderBy.LowestRating && (
                <Icon icon="swap_vert" className="text-paragraph-medium" />
              )}
            {orderBy === ApplicationOrderBy.HighestRating && (
              <Icon icon="arrow_downward_alt" />
            )}
            {orderBy === ApplicationOrderBy.LowestRating && (
              <Icon icon="arrow_upward_alt" />
            )}
          </div>
        )
      },
      cell: ({ getValue }) => {
        const rating = getValue()

        return (
          <div>
            {rating ? (
              <div className="flex items-center gap-1">
                <div>{rating}/5</div>
                <Icon className="text-yellow-500" size="large" icon="star" />
              </div>
            ) : (
              <div className="flex items-center gap-1">
                <div>N/A</div>
                <Icon className="text-yellow-500" size="large" icon="star" />
              </div>
            )}
          </div>
        )
      },
    }),
    columnHelper.accessor("tags", {
      header: t({
        message: "Tags",
        id: "tags",
      }),
      cell: ({ getValue, row }) => {
        const tags = getValue()
        const originalRow = row.original

        if (
          originalRow === undefined ||
          originalRow === null ||
          !hasDefinedId(originalRow)
        ) {
          return <span>N/A</span>
        }

        return (
          <div className="flex gap-1">
            {tags?.map((tag) => <Badge key={tag}>{tag}</Badge>)}
            {!!tags?.length && (
              <IconButton
                icon="edit"
                variant="text"
                className="px-1 text-blue"
                onClick={() => {
                  enqueueDialog({
                    title: t({
                      message: "Edit tags",
                      id: "edit_tags",
                    }),
                    children: (
                      <TagsDialog
                        applicationResponse={originalRow}
                        applicationTags={
                          applicationFilters?.applicationTags || []
                        }
                        applicationDefinitionId={applicationId}
                        onClose={closeCurrentDialog}
                      />
                    ),
                  })
                }}
              />
            )}
            {tags?.length === 0 && (
              <Button
                variant="text"
                small
                icon="edit"
                className="px-0 py-0"
                onClick={() => {
                  enqueueDialog({
                    title: t({
                      message: "Edit tags",
                      id: "edit_tags",
                    }),
                    children: (
                      <TagsDialog
                        applicationResponse={originalRow}
                        applicationTags={
                          applicationFilters?.applicationTags || []
                        }
                        applicationDefinitionId={applicationId}
                        onClose={closeCurrentDialog}
                      />
                    ),
                  })
                }}
              >
                <Trans id="edit_tags">Edit tags</Trans>
              </Button>
            )}
          </div>
        )
      },
    }),
    columnHelper.accessor("status", {
      header: t({
        message: "Status",
        id: "status",
      }),
      cell: ({ getValue }) => {
        const status = getValue()

        if (!status) {
          return <span>N/A</span>
        }

        return (
          <Badge color={applicationStatusToColor[status]}>
            {applicationStatusToLabel(status)}
          </Badge>
        )
      },
    }),
  ] as ColumnDef<ApplicationResponse>[]

  return (
    <div>
      <div className="mb-4">
        {" "}
        <Actions
          applicationDefinition={applicationDefinition}
          selectedApplications={selectedApplications}
          onShowFilters={() => setShowFilters(!showFilters)}
          onSearchChange={(value: string) => {
            setSearchTerm(value)
            setSelectedApplications([])
          }}
          searchValue={concept}
        />
      </div>

      <Filters
        className={clsx("mb-4 flex flex-wrap gap-2", !showFilters && "hidden")}
        values={filterValues}
        optionValues={filterOptions}
        defaultValues={defaultValues}
        onChange={(filterValues) => {
          handleFilterChange(filterValues)
        }}
        showResetButton={true}
        showLabels={false}
        route={route}
      />

      {applicationDefinitionLoading ||
      applicationFiltersLoading ||
      applicationResponsesLoading ||
      applicationFilters === undefined ||
      applicationResponses === undefined ||
      applicationDefinition === undefined ? (
        <Skeleton className="h-[100vh] rounded-lg" />
      ) : (
        <div>
          {applicationResponses?.list && (
            <Table
              columns={columns as ColumnDef<object>[]}
              data={applicationResponses.list}
              loading={applicationResponsesLoading}
              itemsCount={applicationResponses.count}
              pageIndex={currentPage}
              pageSize={take}
              onPageChange={(pageIndex: number) =>
                setPagination({ skip: pageIndex * (take || 0) })
              }
              onPageSizeChange={(pageSize: number) => {
                setPagination({ take: pageSize, skip: pageSize * currentPage })
              }}
            />
          )}
        </div>
      )}
    </div>
  )
}
