import { useMemo, useState } from "react"
import { t, Trans } from "@lingui/macro"
import { useQuery } from "@tanstack/react-query"

import { getCommunityCirclesQuery } from "@src/api/circle"
import { MultiSelect } from "@src/components/atoms/MultiSelect"
import { useCommunityId } from "@src/hooks/useCommunityId"
import { Privacy } from "@src/api/privacy"
import { MethodologyContentCircleDto } from "@src/api/methodologies"
import { Select } from "@src/components/atoms/Select"
import { Button } from "@src/components/atoms/Button"
import { IconButton } from "@src/components/atoms/IconButton"
import { Dialog } from "@src/components/atoms/Dialog"

export type PrivacySelectorProps = {
  privacyLevel: Privacy
  circleIds: MethodologyContentCircleDto[]
  onChange: (values: {
    circleIds?: MethodologyContentCircleDto[]
    privacyLevel?: Privacy
  }) => void
}

export const PrivacySelector = ({
  privacyLevel,
  circleIds,
  onChange,
}: PrivacySelectorProps) => {
  const [open, setOpen] = useState(false)
  const [selectedCircleIds, setSelectedCircleIds] =
    useState<MethodologyContentCircleDto[]>(circleIds)
  const communityId = useCommunityId()

  const { data: communityCircles = [] } = useQuery(
    getCommunityCirclesQuery(communityId),
  )

  const circleIdsOptions = communityCircles?.map(({ id, name }) => ({
    id: id || 0,
    label: name || "",
  }))

  const circleIdsValue = useMemo(() => {
    return communityCircles
      .filter(
        ({ id }) =>
          id !== undefined &&
          selectedCircleIds.find((circleId) => circleId.id === id),
      )
      .map(({ id, name }) => ({
        id: id || 0,
        label:
          name ||
          t({
            message: "Unnamed Circle",
            id: "unnamed_circle",
          }),
      }))
  }, [communityCircles, selectedCircleIds])

  const privacyOptions = [
    {
      id: Privacy.TeamCanChoose,
      label: t({
        message: "Team can choose",
        id: "team_can_choose",
      }),
    },
    {
      id: Privacy.Public,
      label: t({
        message: "Public",
        id: "public",
      }),
    },
    {
      id: Privacy.Custom,
      label: t({
        message: "Custom",
        id: "custom",
      }),
    },
    {
      id: Privacy.Team,
      label: t({
        message: "Team",
        id: "team",
      }),
    },
    {
      id: Privacy.TeamAndMentors,
      label: t({
        message: "Team and mentors",
        id: "team_and_mentors",
      }),
    },
  ]

  return (
    <>
      <div className="flex gap-2">
        <Select
          placeholder={t({
            message: "Please select privacy",
            id: "select_privacy",
          })}
          insideLabel={t({
            message: "Privacy",
            id: "privacy",
          })}
          className="w-full"
          options={privacyOptions}
          onSelect={({ id }) => {
            onChange({ privacyLevel: id as Privacy })

            if (id === Privacy.Custom) {
              setOpen(true)
            }
          }}
          value={
            privacyOptions.find((option) => option.id === privacyLevel) || null
          }
        />
        {privacyLevel === Privacy.Custom && (
          <IconButton
            icon="settings"
            variant="text"
            size="largex"
            onClick={() => setOpen(true)}
          />
        )}
      </div>

      <Dialog
        title={t({
          message: "Select privacy roles",
          id: "select_privacy_roles",
        })}
        open={open}
        onClose={() => setOpen(false)}
        actions={
          <div className="flex justify-end gap-2">
            <Button small variant="secondary" onClick={() => setOpen(false)}>
              <Trans id="cancel">Cancel</Trans>
            </Button>

            <Button
              small
              onClick={() => {
                onChange({ circleIds: selectedCircleIds })

                setOpen(false)
              }}
            >
              <Trans id="select">Select</Trans>
            </Button>
          </div>
        }
      >
        <MultiSelect
          value={circleIdsValue}
          placeholder={t({
            message: "Select roles",
            id: "select_roles",
          })}
          className="w-full"
          options={circleIdsOptions}
          onSelect={(values) => {
            setSelectedCircleIds(values)
          }}
        />
      </Dialog>
    </>
  )
}
