import { client } from "./client"
import { safeQueryOptions } from "@src/utils/query"
// import { components } from "./schema"

export const literalsKeys = {
  all: ["literals"],
}

export const getCommunityLiterals = async (communityId: number) => {
  return await client.GET("/api/CommunityLiteral/GetByCommunity", {
    params: {
      query: {
        communityId,
      },
    },
  })
}

export const getCommunityLiteralsQuery = (id?: number) =>
  safeQueryOptions({
    queryFn: getCommunityLiterals,
    queryKeys: literalsKeys.all,
    unsafeQueryFnArgs: [id],
  })
