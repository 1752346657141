import { t, Trans } from "@lingui/macro"

import { EditorLazy } from "@src/components/organisms/Editor/EditorLazy"
import { SectionProps } from "./types"

export const EmailsSection = ({ form }: SectionProps) => {
  return (
    <section className="flex w-full flex-col gap-6 rounded-xl border border-pearl-lighter bg-white p-6">
      <h5 className="font-medium">
        <Trans id="emails">Emails</Trans>
      </h5>
      <form.Field
        name="acceptedEmail"
        children={(field) => (
          <EditorLazy
            className="rounded-xl bg-ghost-white"
            label="Accepted application email"
            placeholder={t({
              message: "Write your email here",
              id: "group_message_placeholder",
            })}
            value={field.state.value}
            onChange={(value) => {
              field.handleChange(value)
            }}
          />
        )}
      />
      <form.Field
        name="rejectedEmail"
        children={(field) => (
          <EditorLazy
            className="rounded-xl bg-ghost-white"
            label="Rejected application email"
            placeholder={t({
              message: "Write your email here",
              id: "group_message_placeholder",
            })}
            value={field.state.value}
            onChange={(value) => {
              field.handleChange(value)
            }}
          />
        )}
      />
    </section>
  )
}
