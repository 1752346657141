import { client } from "./client"
import { safeQueryOptions } from "@src/utils/query"
import { UnpackPromise, UnpackApiList, UnpackArray } from "@src/utils/types"
import { components } from "./schema"
import { ApiDynamicFilter } from "./filters"
import { FormDetailDto } from "./form"

export enum UserInvitationPolicy {
  Undefined = 0,
  Anyone = 1,
  OnlyPlatformUsers = 2,
  OnlyCommunityUsers = 3,
  None = 4, // only community admins can invite
}

export enum UserJoinCommunitySetting {
  JoinWithoutApproval = 0,
  JoinWithApproval = 1,
  JoinWithInvitation = 2,
}

export enum TaskUnlock {
  Calendar = 0,
  Freestyle = 1,
  Sequential = 2,
  Stagegate = 3,
}

export enum DevelopmentStageType {
  None = 0,
  Default = 1,
  CustomType = 2,
}

export enum CommunityType {
  Public = 0,
  Private = 1,
  Hybrid = 2,
}

export enum CommunityUserSortBy {
  None,
  Name,
  Comments,
  Likes,
  Followers,
  Activity,
}

export type CommunityUserFilter = {
  skip?: number
  take?: number
  communityId: number
  communityCircleId?: number | null
  circleFilter?: ApiDynamicFilter<number>
  name?: string | null
  orderBy?: CommunityUserSortBy
  countryId?: number | null
  countryFilter?: ApiDynamicFilter<number>
  interestId?: number | null
  industryFilter?: ApiDynamicFilter<number>
  skillId?: number | null
  skillFilter?: ApiDynamicFilter<number>
  sustainableDevelopmentGoalId?: number | null
  marketExpertiseId?: number | null
  userMarketsExpertisesFilter?: ApiDynamicFilter<number>
  tags?: string[] | null
  tagFilter?: ApiDynamicFilter<string>
  companyFilter?: ApiDynamicFilter<string>
}

export const communityKeys = {
  all: ["community"],
  overview: (id?: number) => [...communityKeys.all, "overview", id, "overview"],
  users: (filters: CommunityUserFilter) => [
    ...communityKeys.all,
    "users",
    filters,
  ],
  projectForm: (id?: number) => [...communityKeys.all, "projectForm", id],
}

export type CommunityOverview = UnpackPromise<
  ReturnType<typeof getCommunityOverview>
>
export type CommunityEditInfo = components["schemas"]["CommunityEditInfoDto"]
export type SaveCommunityInfoResponse = UnpackPromise<
  ReturnType<typeof saveCommunityInfo>
>
export type CommunityUserList = UnpackPromise<
  ReturnType<typeof getCommunityUsers>
>
export type CommunityUser = UnpackArray<
  UnpackApiList<CommunityUserList["users"]>
>
export type SaveCommunityAboutResponse = UnpackPromise<
  ReturnType<typeof saveCommunityAbout>
>

export const getCommunityOverview = async (id: number) => {
  const data = await client.GET("/api/Community/GetOverview", {
    params: {
      query: { id },
    },
  })

  return data
}

export const getCommunityOverviewQuery = (id?: number) =>
  safeQueryOptions({
    queryFn: getCommunityOverview,
    queryKeys: communityKeys.overview(id),
    unsafeQueryFnArgs: [id],
  })

export const saveCommunityInfo = async (
  communityEditInfo: CommunityEditInfo,
) => {
  const data = await client.POST("/api/Community/SaveInfo", {
    body: communityEditInfo,
  })

  return data
}

export const getCommunityUsers = async (filters: CommunityUserFilter) => {
  const data = await client.POST("/api/Community/GetUserList", {
    body: filters,
  })

  return data
}

export const getCommunityUsersQuery = (filters: CommunityUserFilter) =>
  safeQueryOptions({
    queryFn: getCommunityUsers,
    queryKeys: communityKeys.users(filters),
    unsafeQueryFnArgs: [filters],
  })

export type CommunityEditAbout = components["schemas"]["CommunityEditAboutDto"]

export const saveCommunityAbout = async (aboutInfo: CommunityEditAbout) => {
  const data = await client.POST("/api/Community/SaveAbout", {
    body: aboutInfo,
  })

  return data
}

export const getCommunityProjectForm = async (id: number) => {
  const data = await client.GET("/api/Community/{id}/ProjectForm", {
    params: {
      path: { id },
    },
  })

  return data
}

export const getCommunityProjectFormQuery = (id?: number) =>
  safeQueryOptions({
    queryFn: getCommunityProjectForm,
    queryKeys: communityKeys.projectForm(id),
    unsafeQueryFnArgs: [id],
  })

export const saveCommunityProjectForm = async (
  body: FormDetailDto,
  id: number,
) => {
  const data = await client.PUT("/api/Community/{id}/ProjectForm", {
    params: {
      path: { id },
    },
    body,
  })

  return data
}
