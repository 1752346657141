import clsx from "clsx"
import { Trans } from "@lingui/macro"

import {
  MethodologySectionDto,
  MethodologyParagraphDto,
} from "@src/api/methodologies"
import { Button } from "@src/components/atoms/Button"
import { SortableList } from "@src/components/atoms/SortableList"
import { ProgramSectionItem } from "./ProgramSectionItem"

export type ProgramSectionsProps = {
  methodologyId: number
  methodologySections: MethodologySectionDto[]
  setParagraphPath: (params: {
    sectionIndex: number
    paragraphIndex: number
  }) => void
  updateSectionsOrder: (newSectionsOrder: MethodologySectionDto[]) => void
  selectedSectionIndex: number
  selectedParagraphIndex: number
}

export const ProgramSections = ({
  methodologyId,
  methodologySections,
  setParagraphPath,
  updateSectionsOrder,
  selectedSectionIndex,
  selectedParagraphIndex,
}: ProgramSectionsProps) => {
  const onDragEnd = (orderedItems: { id: number }[]) => {
    const newSectionsOrder = orderedItems.map(({ id }, index) => ({
      ...methodologySections[id],
      order: index,
    }))
    updateSectionsOrder(newSectionsOrder)
  }

  const updateParagraphOrder = (
    newOrder: MethodologyParagraphDto[],
    sectionIndex: number,
  ) => {
    const updatedSections = methodologySections.map((section, index) =>
      index === sectionIndex
        ? { ...section, methodologyParagraphs: newOrder }
        : section,
    )
    updateSectionsOrder(updatedSections)
  }

  const addSection = () => {
    const newSection: MethodologySectionDto = {
      order: methodologySections.length,
      methodologyId,
      name: "",
      methodologyParagraphs: [],
    }
    updateSectionsOrder([...methodologySections, newSection])
  }

  const removeSection = (sectionIndex: number) => {
    const updatedSections = methodologySections
      .filter((_, index) => index !== sectionIndex)
      .map((section, index) => ({ ...section, order: index }))
    updateSectionsOrder(updatedSections)
  }

  return (
    <div
      className={clsx(
        "flex flex-col gap-4",
        "w-full pb-4 lg:w-64 lg:overflow-y-auto",
      )}
    >
      <SortableList
        items={methodologySections.map((section, index) => ({
          id: index,
          element: (
            <ProgramSectionItem
              key={index}
              updateParagraphs={(newOrder) =>
                updateParagraphOrder(newOrder, index)
              }
              setParagraphPath={setParagraphPath}
              removeSection={removeSection}
              methodologySection={section}
              sectionIndex={index}
              selectedSectionIndex={selectedSectionIndex}
              selectedParagraphIndex={selectedParagraphIndex}
            />
          ),
        }))}
        onDragEnd={onDragEnd}
        className="flex flex-col gap-4"
      />

      <Button
        icon="bookmark_add"
        variant="dotted"
        small
        className="w-full"
        onClick={addSection}
      >
        {methodologySections.length === 0 ? (
          <Trans id="add_your_first_section">Add Your First Section</Trans>
        ) : (
          <Trans id="add_section">Add Section</Trans>
        )}
      </Button>
    </div>
  )
}
