import { FormAnswerDto, FormDto } from "@src/api/form"
import { Question } from "@src/components/molecules/Question"
import { FormQuestionAnswerType } from "@src/api/form"
import { TextAnswer } from "@src/components/molecules/Question/TextAnswer"
import { RichTextAnswer } from "@src/components/molecules/Question/RichTextAnswer"
import { FileAnswer } from "@src/components/molecules/Question/FileAnswer"
import { formatTableAnswer } from "./utils"
import { TableAnswer } from "@src/components/molecules/Question/TableAnswer"
import { RadioButtonAnswer } from "@src/components/molecules/Question/RadioButtonAnswer"
import { CheckboxAnswer } from "@src/components/molecules/Question/CheckboxAnswer"
import { CalendarAnswer } from "@src/components/molecules/Question/CalendarAnswer"
import { ScaleAnswer } from "@src/components/molecules/Question/ScaleAnswer"

export type AnswersProps = {
  form: FormDto
  answers: FormAnswerDto[]
}

export const Answers = ({ form, answers }: AnswersProps) => {
  const questions = form?.questions?.map((question) => {
    const answersForQuestion = answers.filter(
      (answer) => answer?.formQuestionId === question.id,
    )

    let children: React.ReactNode[] | React.ReactNode = []

    if (!answersForQuestion.length) {
      children = <TextAnswer answer={"N/A"} />
    } else {
      switch (question.answerType) {
        case FormQuestionAnswerType.ShortTextAnswer: {
          children = <TextAnswer answer={answersForQuestion[0]?.answer} />
          break
        }
        case FormQuestionAnswerType.LongTextAnswer: {
          children = <RichTextAnswer html={answersForQuestion[0]?.answer} />
          break
        }
        case FormQuestionAnswerType.FileAnswer: {
          children = <FileAnswer filepath={answersForQuestion[0]?.answer} />
          break
        }
        case FormQuestionAnswerType.TableAnswer: {
          const tableProps = formatTableAnswer(question, answersForQuestion)

          children = <TableAnswer {...tableProps} />
          break
        }
        case FormQuestionAnswerType.NumericAnswer: {
          children = <TextAnswer answer={answersForQuestion[0]?.answer} />
          break
        }
        case FormQuestionAnswerType.RadioChoiceAnswer: {
          children = (
            <RadioButtonAnswer
              answer={answersForQuestion[0]}
              question={question}
            />
          )
          break
        }
        case FormQuestionAnswerType.CheckBoxChoiceAnswer: {
          children = (
            <CheckboxAnswer question={question} answers={answersForQuestion} />
          )
          break
        }
        case FormQuestionAnswerType.MultipleChoiceAnswer: {
          children = (
            <CheckboxAnswer question={question} answers={answersForQuestion} />
          )
          break
        }
        case FormQuestionAnswerType.DropDownChoiceAnswer: {
          children = (
            <RadioButtonAnswer
              answer={answersForQuestion[0]}
              question={question}
            />
          )
          break
        }
        case FormQuestionAnswerType.ScaleAnswer: {
          children = (
            <ScaleAnswer answer={answersForQuestion[0]} question={question} />
          )
          break
        }
        case FormQuestionAnswerType.AccountMoneyAnswer: {
          const answer = answersForQuestion[0]?.answer || ""
          const answerLength = answer.length
          // Add a space between the number and the currency
          const formattedAnswer = `${answer.slice(0, answerLength - 3)} ${answer.slice(
            answerLength - 3,
          )}`
          children = <TextAnswer answer={formattedAnswer} />
          break
        }
        case FormQuestionAnswerType.CalendarAnswer: {
          children = <CalendarAnswer answer={answersForQuestion[0]?.answer} />
          break
        }
        case FormQuestionAnswerType.CountryAnswer: {
          children = <TextAnswer answer={answersForQuestion[0]?.answer} />
          break
        }
      }
    }

    return (
      <div className="mb-4" key={question.id}>
        <Question key={question.id} question={question} children={children} />
      </div>
    )
  })

  return <div>{questions}</div>
}
