import { Input as HeadlessInput } from "@headlessui/react"
import clsx from "clsx"

import {
  Label,
  Field,
  ErrorMessage,
  LeftIcon,
  RightIcon,
} from "@src/components/atoms/Fieldset"
import { IconType } from "@src/config/icons"
import { commonStyles, FieldProps } from "@src/components/atoms/Fieldset/common"

export type InputProps = FieldProps & {
  iconLeft?: IconType
  iconRight?: IconType
  name: string
  type?: 'string' | 'number'
  value?: string | number | string[] | null
  onChange: (value: string | number | string[] | null) => void
}

export const Input = ({
  variant = "primary",
  label,
  error,
  iconLeft,
  iconRight,
  className,
  description,
  required = false,
  small = false,
  name,
  value,
  onChange,
  type,
  ...props
}: InputProps) => {
  return (
    <Field className={clsx("flex-col", className)}>
      <Label htmlFor={name} required={required} description={description}>
        {label}
      </Label>

      <div className="relative">
        <HeadlessInput
          className={clsx(
            commonStyles.base,
            commonStyles[variant],
            small ? ["px-3 py-1"] : ["px-5 py-2"],
            iconLeft && "pl-10",
            iconRight && "pr-10",
          )}
          invalid={!!error}
          name={name}
          type={type}
          // This is an unfortunate workaround because the type of value in HeadlessInputProps
          // doesn't allow null and almost all our APIs can return null for empty values
          value={value as string | number | string[] | undefined}
          onChange={(e) => onChange(e.target.value)}
          {...props}
        />
        <LeftIcon icon={iconLeft} />
        <RightIcon icon={iconRight} />
      </div>
      <ErrorMessage>{error}</ErrorMessage>
    </Field>
  )
}
